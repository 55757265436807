import getSpecialTariff from 'pages/FTTB/services/getSpecialTariff';
import { getSpecialTariffFetchType } from 'pages/FTTB/selectors/specialTariff';

import {
  FETCH_FTTB_SPECIAL_TARIFF_ERROR,
  FETCH_FTTB_SPECIAL_TARIFF_START,
  FETCH_FTTB_SPECIAL_TARIFF_SUCCESS,
  SET_FTTB_SPECIAL_TARIFF_SHOW_IF_EXIST,
  SET_FTTB_SPECIAL_TARIFF_TYPE,
} from './actionTypes';

/**
 * Запрашивает цветной тарифа для указанного дома
 * @param {number} houseId Id дома для, которого проверяется наличие цвет. тарифа
 * @param {boolean} [showSpecTariffIfExist=false] Указывает отображать ли тариф,
 * сразу после загрузки (по умолчание `false`)
 */
export const fetchSpecialTariffByHouse =
  (houseId, showSpecTariffIfExist = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const fetchTariffType = getSpecialTariffFetchType(state) || 'internet';

    dispatch({ type: FETCH_FTTB_SPECIAL_TARIFF_START, payload: { showSpecTariffIfExist } });
    try {
      const data = await getSpecialTariff(houseId, fetchTariffType);
      dispatch({ type: FETCH_FTTB_SPECIAL_TARIFF_SUCCESS, payload: { tariff: data, houseId } });
    } catch (error) {
      dispatch({ type: FETCH_FTTB_SPECIAL_TARIFF_ERROR });
    }
  };

export const setSpecialTariffType = (type) => (dispatch) => {
  dispatch({ type: SET_FTTB_SPECIAL_TARIFF_TYPE, payload: { type } });
};

export const setShowSpecTariffIfExist = (show) => (dispatch) => {
  dispatch({ type: SET_FTTB_SPECIAL_TARIFF_SHOW_IF_EXIST, payload: { show } });
};
