import { path } from 'ramda';

export const UPDATE_CTN = 'update ctn';
export const INIT_SMS_SERVICE = 'init sms service';
export const UPDATE_TIMEOUT = 'update last sms time';

const dumpSmsService = (getState) => {
  const currentHistory = path(['external', 'smsHistory'], getState());
  if (typeof localStorage !== 'undefined') {
    window.localStorage.setItem('smsService', JSON.stringify(currentHistory));
  }
};

export const initSmsService = () => (dispatch) => {
  let smsServiceLocalStorage = {};

  if (typeof localStorage !== 'undefined') {
    smsServiceLocalStorage = JSON.parse(localStorage.getItem('smsService'));
  }

  dispatch({ type: INIT_SMS_SERVICE, payload: smsServiceLocalStorage || {} });
};

export const updateSmsHistory = (ctn) => (dispatch, getState) => {
  const ctns = getState().external.smsHistory.ctns || {};
  const count = ctns[ctn] || 0;

  ctns[ctn] = count + 1;

  dispatch({ type: UPDATE_CTN, payload: ctns });
  dumpSmsService(getState);
};

export const updateLastSmsTime = (time) => (dispatch, getState) => {
  dispatch({ type: UPDATE_TIMEOUT, payload: time });
  dumpSmsService(getState);
};
