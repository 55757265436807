import { INIT_SMS_SERVICE, UPDATE_CTN, UPDATE_TIMEOUT } from './actions';

export const smsService = (state = {}, action) => {
  if (action.type === INIT_SMS_SERVICE) {
    return { ...state, smsHistory: action.payload };
  }

  if (action.type === UPDATE_CTN) {
    return {
      ...state,
      smsHistory: {
        ...state.smsHistory,
        ctns: action.payload,
      },
    };
  }

  if (action.type === UPDATE_TIMEOUT) {
    return {
      ...state,
      smsHistory: {
        ...state.smsHistory,
        lastSmsTime: action.payload,
      },
    };
  }

  return state;
};
