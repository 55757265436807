import React from 'react';
import type { ICurrentRegion } from '@carnica/smart-kit';
import { BASKET_API_URL, createCartApi, fetchCartItemsStats } from '@beef/layout-kit/actions';

import store from 'store';

export const useBasket = (regionInfo: ICurrentRegion): void => {
  React.useEffect(() => {
    store.dispatch(createCartApi(BASKET_API_URL));
    store.dispatch(fetchCartItemsStats({ region: regionInfo.code }));
  }, []);
};
