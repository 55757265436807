/**
 * Принимает полную модель тарифа и мапит в promoTariff для формы заказа
 * @param {object} tariff модель тарифа
 * @return {{
 *  title: string,
 *  alias: string,
 *  fee: string,
 *  feeUnit: string,
 *  oldFee: object,
 *  discountText: string,
 *  url: string,
 *  productType: number,
 *  channelsLabel: string,
 *  channelCount: number,
 *  wifiRouterIncluded: boolean,
 *  tvDeviceIncluded: boolean,
 *  params: Array.<{
 *      sortOrder: number,
 *      paramValue: string,
 *      unit: string,
 *  }>,
 *  sortOrder: number,
 * }}
 */
export const mapToPromoTariff = (tariff) => {
  const {
    name,
    alias,
    fee,
    detailsUrl,
    productType,
    tvInfo,
    routerInfo,
    tvDeviceInfo,
    mobileTariffInfo,
    internetConnectionSpeed,
    oldFee: oldFeeOrigin,
    discountText,
  } = tariff;

  const oldFee = oldFeeOrigin &&
    !oldFeeOrigin.isEmpty && {
      value: oldFeeOrigin.value,
      unit: oldFeeOrigin.unit,
    };

  return {
    title: name,
    alias,

    fee: fee.value,
    feeUnit: fee.unit,
    oldFee,
    discountText,

    url: detailsUrl,
    productType,

    channelsLabel: tvInfo ? tvInfo.channelsLabel : '',
    channelCount: tvInfo ? tvInfo.count : 0,

    wifiRouterIncluded: routerInfo ? routerInfo.isIncluded : false,
    tvDeviceIncluded: tvDeviceInfo ? tvDeviceInfo.isIncluded : false,
    mobileTariffIncluded: !!mobileTariffInfo?.title,
    mobileTariffTitle: mobileTariffInfo?.title ?? '',

    params: [
      {
        sortOrder: -1,
        paramValue: internetConnectionSpeed?.value,
        unit: internetConnectionSpeed?.unit,
      },
    ],
    sortOrder: 0,
  };
};

export const mapSelectedProductToPromo = (selectedProduct, requestUrl) => {
  const oldFee = selectedProduct.OldFee &&
    !selectedProduct.OldFee.IsEmpty && {
      value: selectedProduct.OldFee.Value,
      unit: selectedProduct.OldFee.Unit,
    };

  return {
    title: selectedProduct.Name,
    alias: selectedProduct.Alias,

    fee: selectedProduct.ProductInitialFee.Value,
    feeUnit: selectedProduct.ProductInitialFee.Unit,
    oldFee,
    discountText: selectedProduct.DiscountText,

    productType: selectedProduct.ProductType,

    params: [],
    sortOrder: 0,

    requestUrl,
  };
};
