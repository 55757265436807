// START: connectionState
export const SET_FTTB_CONNECTION_DATA = 'SET_FTTB_CONNECTION_DATA';

export const SET_FTTB_CONNECTION_DATA_START = 'SET_FTTB_CONNECTION_DATA_START';
export const SET_FTTB_CONNECTION_DATA_SUCCESS = 'SET_FTTB_CONNECTION_DATA_SUCCESS';
export const SET_FTTB_CONNECTION_DATA_ERROR = 'SET_FTTB_CONNECTION_DATA_ERROR';

export const GET_FTTB_CONNECTION_STATE_START = 'GET_FTTB_CONNECTION_STATE_START';
export const GET_FTTB_CONNECTION_STATE_END = 'GET_FTTB_CONNECTION_STATE_END';
// END: connectionState

// START: fttbCatalog
export const SET_FTTB_CATALOG_DATA = 'SET_FTTB_CATALOG_DATA';
// END: fttbCatalog

// START: specialTariff
export const FETCH_FTTB_SPECIAL_TARIFF_START = 'FETCH_FTTB_SPECIAL_TARIFF_START';
export const FETCH_FTTB_SPECIAL_TARIFF_SUCCESS = 'FETCH_FTTB_SPECIAL_TARIFF_SUCCESS';
export const FETCH_FTTB_SPECIAL_TARIFF_ERROR = 'FETCH_FTTB_SPECIAL_TARIFF_ERROR';

export const SET_FTTB_SPECIAL_TARIFF_TYPE = 'SET_FTTB_SPECIAL_TARIFF_TYPE';
export const SET_FTTB_SPECIAL_TARIFF_SHOW_IF_EXIST = 'SET_FTTB_SPECIAL_TARIFF_SHOW_IF_EXIST';
// END: specialTariff

// START: tvChannels
export const FETCH_FTTB_TV_CHANNELS_START = 'FETCH_FTTB_TV_CHANNELS_START';
export const FETCH_FTTB_TV_CHANNELS_SUCCESS = 'FETCH_FTTB_TV_CHANNELS_SUCCESS';
export const FETCH_FTTB_TV_CHANNELS_ERROR = 'FETCH_FTTB_TV_CHANNELS_ERROR';

export const SET_SELECTED_FTTB_TV_CHANNELS_PACKAGE = 'SET_SELECTED_FTTB_TV_CHANNELS_PACKAGE';
export const REMOVE_SELECTED_FTTB_TV_CHANNELS_PACKAGE = 'REMOVE_SELECTED_FTTB_TV_CHANNELS_PACKAGE';
// END: tvChannels

// START: availableSchedoule
export const FETCH_FTTB_AVAILABLE_SCHEDULE_START = 'FETCH_FTTB_AVAILABLE_SCHEDULE_START';
export const FETCH_FTTB_AVAILABLE_SCHEDULE_SUCCESS = 'FETCH_FTTB_AVAILABLE_SCHEDULE_SUCCESS';
export const FETCH_FTTB_AVAILABLE_SCHEDULE_ERROR = 'FETCH_FTTB_AVAILABLE_SCHEDULE_ERROR';
// END: availableSchedoule

// START: fttbTariffPage
export const SET_FTTB_TARIFF_PAGE_DATA = 'SET_FTTB_TARIFF_PAGE_DATA';

export const ADD_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE = 'ADD_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE';
export const REMOVE_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE =
  'REMOVE_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE';

export const ADD_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS = 'ADD_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS';
export const REMOVE_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS =
  'REMOVE_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS';
// END: fttbTariffPage

// START: wifiRouters
export const EXCLUDE_WIFI_ROUTER = 'EXCLUDE_WIFI_ROUTER';
export const RETURN_EXCLUDED_WIFI_ROUTER = 'RETURN_EXCLUDED_WIFI_ROUTER';
export const ADD_SELECTED_WIFI_ROUTER = 'ADD_SELECTED_WIFI_ROUTER';
export const REMOVE_SELECTED_WIFI_ROUTER = 'REMOVE_SELECTED_WIFI_ROUTER';
export const REMOVE_ALL_SELECTED_WIFI_ROUTERS = 'REMOVE_ALL_SELECTED_WIFI_ROUTERS';
// END: wifiRouters

// START: tvConsoles
export const ADD_FTTB_TV_CONSOLE_FOR_CHANGE = 'ADD_FTTB_TV_CONSOLE_FOR_CHANGE';
export const REMOVE_FTTB_TV_CONSOLE_FOR_CHANGE = 'REMOVE_FTTB_TV_CONSOLE_FOR_CHANGE';
export const REMOVE_ALL_FTTB_TV_CONSOLE_FOR_CHANGE = 'REMOVE_ALL_FTTB_TV_CONSOLE_FOR_CHANGE';
export const ADD_ADDITIONAL_FTTB_TV_CONSOLE = 'ADD_ADDITIONAL_FTTB_TV_CONSOLE';
export const REMOVE_ADDITIONAL_FTTB_TV_CONSOLE = 'REMOVE_ADDITIONAL_FTTB_TV_CONSOLE';
export const EXCLUDE_PRE_ENABLED_FTTB_TV_CONSOLE = 'EXCLUDE_PRE_ENABLED_FTTB_TV_CONSOLE';
export const RETURN_ALL_PRE_ENABLED_FTTB_TV_CONSOLE = 'RETURN_ALL_PRE_ENABLED_FTTB_TV_CONSOLE';
// END: tvConsoles
