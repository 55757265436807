import {
  CHANGED_REGION,
  CHOOSE_BASKET_MODAL_COMPONENT,
  CLEAR_LOTTIE_ANIMATION,
  SET_CHOSEN_NUMBER,
  SET_CHOSEN_TARIFF,
  SET_ERROR,
  SET_EXPANDED_NUMBERS,
  SET_FIRST_LOADED_PAGE,
  SET_GLOBAL_LOADING,
  SET_HAS_CLICKED_PAYMENT_BTN,
  SET_IS_NEW_DESIGN,
  SET_LOTTIE_ANIMATION,
  SET_MODAL_BASKET_OPEN,
  SET_NOT_OPEN_PRE_MODAL,
  SET_NUMBERS,
  SET_NUMBERS_LOADING,
  SET_PAYMENT_INFO,
  SET_PORTATION_CHECK_LOADING,
  SET_PORTATION_NUMBER,
  SET_PREESIM_MODAL,
  SET_PRELOADER,
  SET_TARIFFS,
  SET_TARIFFS_LOADING,
  SET_USER_CONTACT_INFO_FROM_BASKET,
  SET_VALID_PAYMENT_DATA,
  SHOW_ERROR,
} from '../actions/actionTypes';

const getNewState = (oldState, newData) => ({
  ...oldState,
  esim: { ...oldState.esim, ...newData },
});

export default (state, action) => {
  if (action.type === SET_NUMBERS) {
    return getNewState(state, {
      numbers: {
        ...state.esim.numbers,
        [action.payload.key]: action.payload.numbers,
      },
    });
  }

  if (action.type === SET_NUMBERS_LOADING) {
    return getNewState(state, { numbersLoading: action.payload });
  }

  if (action.type === SET_GLOBAL_LOADING) {
    return getNewState(state, { isGlobalLoading: action.payload });
  }

  if (action.type === SET_PORTATION_CHECK_LOADING) {
    return getNewState(state, { portationCheckLoading: action.payload });
  }

  if (action.type === SET_PORTATION_NUMBER) {
    const { portationNumber } = state.esim;
    return getNewState(state, {
      portationNumber: {
        ...portationNumber,
        ...action.payload,
      },
    });
  }

  if (action.type === SET_CHOSEN_NUMBER) {
    return getNewState(state, { chosenNumber: action.payload });
  }

  if (action.type === SET_PREESIM_MODAL) {
    return getNewState(state, { isPreModalOpen: action.payload });
  }

  if (action.type === SET_EXPANDED_NUMBERS) {
    return getNewState(state, {
      expanded:
        action.payload ?
          {
            ...state.esim.expanded,
            [action.payload]: true,
          }
        : {},
    });
  }

  if (action.type === SET_TARIFFS) {
    return getNewState(state, { tariffs: action.payload });
  }

  if (action.type === SET_TARIFFS_LOADING) {
    return getNewState(state, { tariffsLoading: action.payload });
  }

  if (action.type === SET_CHOSEN_TARIFF) {
    return getNewState(state, { chosenTariff: action.payload });
  }

  if (action.type === SET_PAYMENT_INFO) {
    return getNewState(state, { paymentInfo: action.payload });
  }

  if (action.type === SET_ERROR) {
    return getNewState(state, { error: action.payload });
  }

  if (action.type === CHANGED_REGION) {
    return getNewState(state, { changedRegion: action.payload });
  }

  if (action.type === SET_LOTTIE_ANIMATION) {
    return getNewState(state, {
      lottieData: {
        ...(state.esim && state.esim.lottieData),
        [action.payload.name]: action.payload.data,
        lottieName: action.payload.name,
      },
    });
  }

  if (action.type === CLEAR_LOTTIE_ANIMATION) {
    return getNewState(state, {
      lottieData: {},
    });
  }

  if (action.type === CHOOSE_BASKET_MODAL_COMPONENT) {
    return getNewState(state, { basketModalComponent: action.payload });
  }

  if (action.type === SET_MODAL_BASKET_OPEN) {
    return getNewState(state, { isModalBasketOpen: action.payload });
  }

  if (action.type === SET_IS_NEW_DESIGN) {
    return getNewState(state, { newDesignToggles: action.payload });
  }

  if (action.type === SHOW_ERROR) {
    return getNewState(state, { isShowError: action.payload });
  }

  if (action.type === SET_VALID_PAYMENT_DATA) {
    return getNewState(state, { isValidPaymentData: action.payload });
  }

  if (action.type === SET_PRELOADER) {
    return getNewState(state, { isPreloader: action.payload });
  }

  if (action.type === SET_FIRST_LOADED_PAGE) {
    return getNewState(state, { firstLoadedPage: action.payload });
  }

  if (action.type === SET_NOT_OPEN_PRE_MODAL) {
    return getNewState(state, { isNotOpenPreModal: action.payload });
  }

  if (action.type === SET_USER_CONTACT_INFO_FROM_BASKET) {
    return getNewState(state, { contactUserInfo: action.payload });
  }

  if (action.type === SET_HAS_CLICKED_PAYMENT_BTN) {
    return getNewState(state, { hasClickedPaymentBtn: action.payload });
  }

  return state;
};
