import axios from 'axios';

import { updateLoadingStatus } from 'pages-components/Loader/actions';

import { TOGGLE_ERROR_POPUP } from './actionsTypes';

// ----------------------

export const SET_STATUS = 'set popup status';
export const OPEN_POPUP = 'open service status popup';
export const CLOSE_POPUP = 'close service status popup';
// ---------------------

const startSession = (url, data, params) => axios.post(url, data, params);

const pollData = (url, id, dataObj, options, responseHandler) =>
  new Promise((resolve, reject) => {
    let timeToRequest = 0;
    const pollPeriod = 1000;
    const maxWaitTime = 60000;
    let timeout = null;
    const poll = () => {
      axios
        .post(url, { requestId: id, ...dataObj }, options)
        .then((res) =>
          responseHandler(res)
            .then((data) => resolve({ data, status: 'success' }))
            .catch((err) => {
              if (err) {
                reject(err);
                clearTimeout(timeout);
              } else {
                timeToRequest += pollPeriod;
                if (timeToRequest > maxWaitTime) {
                  resolve({ status: 'sent' });
                  clearTimeout(timeout);
                } else {
                  timeout = setTimeout(poll, pollPeriod);
                }
              }
            }),
        )
        .catch(() => {
          reject('Превышено время ожидания запроса');
          clearTimeout(timeout);
        });
    };
    poll();
  });

export const startRequest =
  ({
    key,
    pollUrl,
    startUrl,
    dataForStartRequest,
    dataForPollRequest,
    optionsForStartRequest,
    responseHandler,
  }) =>
  (dispatch) => {
    // dispatch(updateLoadingStatus({ key, loading: true }));
    dispatch({ type: OPEN_POPUP, payload: { key } });
    dispatch({ type: SET_STATUS, payload: { key, status: 'loading' } });
    return startSession(
      startUrl,
      { ...dataForStartRequest, clearJson: true },
      { headers: { 'X-Requested-With': 'XMLHttpRequest' }, ...optionsForStartRequest },
    )
      .then((res) => {
        const { data } = res;
        const { requestId } = data;
        return new Promise((resolve, reject) => {
          if (requestId === 0) {
            return reject(
              'B процессе подключения произошла ошибка. Обратитесь в службу поддержки.',
            );
          }
          return resolve(
            pollData(
              pollUrl,
              requestId,
              { clearJson: true, LastTry: false, ...dataForPollRequest },
              { headers: { 'X-Requested-With': 'XMLHttpRequest' }, ...dataForPollRequest },
              responseHandler,
            ),
          );
        });
      })
      .then(({ data, status }) => {
        dispatch({ type: SET_STATUS, payload: { key, status, res: { data } } });
        dispatch({ type: OPEN_POPUP, payload: { key } });
        // dispatch(updateLoadingStatus({ key, loading: false }));
      })
      .catch((err) => {
        dispatch({
          type: SET_STATUS,
          payload: { key, status: 'error', error: { occurred: true, text: err } },
        });
        dispatch({ type: OPEN_POPUP, payload: { key } });
        // dispatch(updateLoadingStatus({ key, loading: false }));
      });
  };

export const closePopup = (key) => ({ type: CLOSE_POPUP, payload: { key } });

export const toggleErrorPopup = (isOpened, dataObj) => ({
  type: TOGGLE_ERROR_POPUP,
  payload: {
    opened: isOpened,
    data: dataObj,
    status: 'error',
  },
});

export const openConfirmation =
  ({ key }) =>
  (dispatch) => {
    dispatch({ type: OPEN_POPUP, payload: { key } });
    dispatch({ type: SET_STATUS, payload: { key, status: 'confirmation' } });
  };
