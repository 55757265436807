export const popupAndUpsaleTypes = {
  None: 'None',
  TvInfo: 'TvInfo',
  InternetParam: 'InternetParam',
  AdditionalTVPackages: 'AdditionalTVPackages',
  Antivirus: 'Antivirus',
  WifiRouter: 'WifiRouter',
  Common: 'Common',
  OrderTariffForm: 'OrderTariffForm',
  TvConsoleParameter: 'TvConsoleParameter',
  TvConsoleUpsale: 'TvConsoleUpsale',
  MobileTariff: 'MobileTariff',
};

export const enableServiceTypes = {
  tvConsoleForChange: 'tv-console-for-change',
  addService: 'add-service',
};

export const deviceType = {
  router: 'router',
  tvConsole: 'tvConsole',
};
