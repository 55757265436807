import {
  ERROR_VALIDATION,
  INIT_DATA_SPN,
  SET_DATA_FROM_MAIN_STEP,
  SET_DETAILS_OF_SPN,
  SET_ERROR_TEXT,
  SET_LOADER_CONTENT_STEP,
  SET_LOADING_MODAL,
  SET_LOTTIE_ANIMATION,
  SET_STEP_MODAL_SPN,
  SET_TEMP_EDITABLE_SPN,
  SET_TOGGLE_MODAL,
  WAITING_REQUEST,
} from '../actions';

const getNewState = (oldState, newData) => ({
  ...oldState,
  spn: { ...oldState.spn, ...newData },
});

export default (state, action) => {
  if (action.type === SET_TOGGLE_MODAL) {
    return getNewState(state, {
      isModalOpen: !state.spn.isModalOpen,
    });
  }

  if (action.type === INIT_DATA_SPN) {
    return getNewState(state, action.payload);
  }

  if (action.type === SET_STEP_MODAL_SPN) {
    return getNewState(state, {
      modalStep: action.payload,
    });
  }

  if (action.type === SET_LOADER_CONTENT_STEP) {
    return getNewState(state, {
      loaderContentStep: action.payload,
    });
  }

  if (action.type === SET_DATA_FROM_MAIN_STEP) {
    return getNewState(state, {
      mainStepData: { ...state.spn.mainStepData, ...action.payload },
    });
  }

  if (action.type === WAITING_REQUEST) {
    return getNewState(state, {
      waitingRequest: !state.spn.waitingRequest,
    });
  }

  if (action.type === SET_LOADING_MODAL) {
    return getNewState(state, {
      isLoadingModal: !state.spn.isLoadingModal,
    });
  }

  if (action.type === ERROR_VALIDATION) {
    return getNewState(state, {
      hasErrorValidation: action.payload,
    });
  }

  if (action.type === SET_ERROR_TEXT) {
    return getNewState(state, {
      errorText: action.payload,
    });
  }

  if (action.type === SET_LOTTIE_ANIMATION) {
    return getNewState(state, {
      lottieData: action.payload,
    });
  }

  if (action.type === SET_DETAILS_OF_SPN) {
    const { payload } = action;
    return getNewState(state, {
      extData: { ...state.spn.extData, ...payload },
    });
  }

  if (action.type === SET_TEMP_EDITABLE_SPN) {
    return getNewState(state, {
      tempEditableSpn: action.payload,
    });
  }

  return state;
};
