import React from 'react';
import { render } from 'react-dom';

import RestrictedPopup from './index';

export function closeExistedPopup(onPopupClosed) {
  try {
    let popupClosed = false;

    const intervalId = setInterval(() => {
      const closeButton = document.querySelector('.popupCloseButton');

      if (closeButton) {
        closeButton.click();
        clearInterval(intervalId);
        onPopupClosed();
        popupClosed = true;
      }
    }, 50);

    setTimeout(() => {
      clearInterval(intervalId);

      if (!popupClosed) {
        onPopupClosed();
      }
    }, 1000);
  } catch (err) {} // eslint-disable-line

  try {
    window.QA.Beeline.Popup.close();
  } catch (err) {} // eslint-disable-line
}

export function errorHandler(payload) {
  closeExistedPopup(() => {
    const reactContainer = document.querySelector('.react-adaptive-page');
    const popupContainer = document.createElement('div');
    reactContainer.appendChild(popupContainer);

    render(<RestrictedPopup {...payload} />, popupContainer);
  });
}
