import checkConnection from 'pages/FTTB/services/checkConnection';

/**
 * Возможные состояния кваритиры
 * @typedef FlatConnectionStates
 * @type {object}
 * @property {number} none - не проверена или не удалось проверить
 * @property {number} success - квартира доступно для подключения
 * @property {number} alreadyInUse = квартира уже подключена к билайн
 */
export const flatConnectionStates = {
  none: 0,
  success: 1,
  alreadyInUse: 2,
  partnerConnected: 3,
  error: 4,
};

/**
 * Преобразует объект `connectionData` к обекту необходимому для передачи в метод `checkConnection`
 */
export const convertConnectionDataToCheckConnectionData = (connectionData) => {
  const { city, street, house, flat } = connectionData;
  return {
    city: city.label,
    cityId: city.id,
    streetId: street.id,
    street: street.label,
    house: house.label,
    houseId: house.id,
    isConnected: house.isConnected,
    flat,
  };
};

/**
 * Проверяет доступность квартиры для подключения
 * @param {object} connectionData Объект содержащий проверяемый адрес
 * @param {{ id: number, label: string }} connectionData.city Объект города с полями `id` и `label`
 * @param {{ id: number, label: string }} connectionData.street Объект улицы с полями `id` и `label`
 * @param {{ id: number, label: string, isConnected: boolean }} connectionData.house Объект
 * дома с полями `id`, `label` и `isConnected`
 * @param {string} connectionData.flat Строка содежащая номер квартиры
 * @returns {Promise<{
 *  connectionState: FlatConnectionStates,
 *  hasRequestOnAddress: boolean,
 *  callCenterWorkStartTime: string,
 *  callCenterWorkEndTime: string,
 * }>} Возвращает промис который принимает объект с информацией о доступности квартиры
 */
export const checkFlatConnection = (connectionData) => {
  const requestData = convertConnectionDataToCheckConnectionData(connectionData);

  return checkConnection(requestData).then(({ data }) => ({
    connectionState: data.View.ConnectionState,
    hasRequestOnAddress: data.View.HasRequestOnAddress,
    callCenterWorkStartTime: data.View.CallCenterWorkStartTime,
    callCenterWorkEndTime: data.View.CallCenterWorkEndTime,
  }));
};

/**
 * Вызывает секцию смены города из шапки сайта
 */
export const changeCity = () => {
  const regionsButton = document.querySelector('#toggleButton-regions-desktop');
  if (regionsButton) regionsButton.click();
};
