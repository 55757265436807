import axios from 'axios';
import qs from 'query-string';

const fetchAvailableSchedule = (houseId, startDateOffsetInDays, endDateOffsetInDays) =>
  axios
    .get(
      `/hometariffsapi/getavailableschedule/?${qs.stringify({
        houseId,
        startDateOffsetInDays,
        endDateOffsetInDays,
      })}`,
    )
    .then(({ data }) => data.view);

export default fetchAvailableSchedule;
