import {
  INIT_SERVICE_DATA,
  RESET_SERVICE_POPUP,
  SERVICE_IS_DISCONNECT,
  SET_SERVICE_CONNECTION_POPUP,
  SET_SERVICE_DISCONNECTION_POPUP,
  SET_SERVICE_MNP_POPUP,
  SET_SERVICE_SIM_ORDER,
  SET_SERVICE_STATUS_REQUEST_COMPLATE,
  SET_SERVICE_STATUS_REQUEST_ERROR,
  SET_SERVICE_STATUS_REQUEST_IN_PROGRESS,
  SET_SERVICE_STATUS_REQUEST_START,
  SET_SERVICE_WAITING_DISCONNECT_POPUP,
  SET_SERVICE_WAITING_POPUP,
  SET_SRV_CONNECTION_RESULT_POPUP,
  SET_SRV_DISCONNECTION_RESULT_POPUP,
  TOGGLE_SERVICE_CONNECTION_POPUP,
  TOGGLE_SERVICE_DISCONNECTION_POPUP,
} from '../actions/actionTypes';

const updateConnectionPopup = (state, popupState) => ({
  ...state,
  serviceConnectionPopup: {
    ...state.serviceConnectionPopup,
    ...popupState,
  },
});

const updateDisconnectionPopup = (state, popupState) => ({
  ...state,
  serviceDisconnectionPopup: {
    ...state.serviceDisconnectionPopup,
    ...popupState,
  },
});

export function serviceConnectionReducer(state = {}, { type, payload }) {
  switch (type) {
    case INIT_SERVICE_DATA: {
      return {
        ...state,
        service: { ...payload },
      };
    }

    case RESET_SERVICE_POPUP: {
      return {
        ...state,
        serviceConnectionPopup: null,
      };
    }

    case SET_SERVICE_STATUS_REQUEST_START: {
      return updateConnectionPopup(state, payload);
    }

    case SET_SERVICE_STATUS_REQUEST_COMPLATE: {
      return updateConnectionPopup(state, payload);
    }

    case SET_SERVICE_STATUS_REQUEST_ERROR: {
      return updateConnectionPopup(state, payload);
    }

    case TOGGLE_SERVICE_CONNECTION_POPUP: {
      return updateConnectionPopup(state, { popupIsActive: !!payload.popupIsActive });
    }

    case SET_SERVICE_CONNECTION_POPUP: {
      return updateConnectionPopup(state, payload);
    }

    case SET_SRV_CONNECTION_RESULT_POPUP: {
      return updateConnectionPopup(state, {
        resultPopupData: { ...payload },
      });
    }

    case SET_SERVICE_WAITING_POPUP: {
      return updateConnectionPopup(state, {
        waitingPopup: { ...payload },
      });
    }

    case SET_SERVICE_WAITING_DISCONNECT_POPUP: {
      return updateDisconnectionPopup(state, {
        waitingPopup: { ...payload },
      });
    }

    case SET_SERVICE_STATUS_REQUEST_IN_PROGRESS: {
      return updateDisconnectionPopup(state, {
        resultPopupData: { ...payload },
      });
    }

    case TOGGLE_SERVICE_DISCONNECTION_POPUP: {
      return updateDisconnectionPopup(state, {
        popupIsActive: !!payload.popupIsActive,
        isConnectionCancel: payload.isConnectionCancel,
      });
    }

    case SET_SERVICE_DISCONNECTION_POPUP: {
      return updateDisconnectionPopup(state, payload);
    }

    case SET_SRV_DISCONNECTION_RESULT_POPUP: {
      return updateDisconnectionPopup(state, {
        resultPopupData: { ...payload },
      });
    }

    case SERVICE_IS_DISCONNECT:
      return updateDisconnectionPopup(state, payload);

    case SET_SERVICE_MNP_POPUP: {
      const { data } = state.serviceConnectionPopup;
      return updateConnectionPopup(state, {
        data: {
          ...data,
          mnpConfirmPopup: { ...data.mnpConfirmPopup, ...payload },
        },
        isPending: false,
      });
    }

    case SET_SERVICE_SIM_ORDER: {
      const { data } = state.serviceConnectionPopup;
      return updateConnectionPopup(state, {
        data: {
          ...data,
          simOrderForm: { ...data.simOrderForm, ...payload },
        },
        isPending: false,
      });
    }

    default: {
      return state;
    }
  }
}
