import { SET_CONNECTION_RESULT_POPUP } from './actionTypes';

export const recordPayload = (type, payload) => (dispatch) => {
  dispatch({ type, payload });
};

export const showError =
  (error = {}) =>
  (dispatch, getState) => {
    const { external } = getState();
    const { errorPopup } = external.tariff.data;

    dispatch(
      recordPayload(SET_CONNECTION_RESULT_POPUP, {
        ...errorPopup,
        ...error,
      }),
    );
  };
