import startOfDay from 'date-fns/start_of_day';

/**
 * @param {{
 *  startDate: string,
 *  endDate: string,
 * }[]} availableSchedule
 * @returns {Object.<number, {
 *    day: Date,
 *    timePeriods: {
 *      start: Date,
 *      end: Date,
 *    }[]
 *  }>
 * } Объект где ключи это числовое представление даты (date.getTime())
 */
export const mapAvailableSchedule = (availableSchedule) => {
  const datesObj = {};

  availableSchedule.forEach((timePeriod) => {
    const day = startOfDay(timePeriod.startDate);
    const dayTime = day.getTime();

    if (!datesObj[dayTime]) {
      datesObj[dayTime] = {
        day,
        timePeriods: [],
      };
    }

    datesObj[dayTime].timePeriods.push({
      start: new Date(timePeriod.startDate),
      end: new Date(timePeriod.endDate),
      startTimeStamp: timePeriod.startDate,
      endTimeStamp: timePeriod.endDate,
    });
  });

  return datesObj;
};
