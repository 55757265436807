import React from 'react';

import { getCookie } from 'utils/cookie';

import { IGetTokenResponse, fetchTokenInfo } from '../request';
import { AUTH_TYPES, AuthTypes } from '../types';

const MAX_ATTEMPTS = 3;

export const useUserData = () => {
  const [attempts, setAttempts] = React.useState<number>(0);
  const [pending, setPending] = React.useState<boolean>(false);
  const [isUserAuthenticated, setIsAuth] = React.useState<boolean | undefined>(undefined);
  const [userData, setUserData] = React.useState<IGetTokenResponse | undefined>(undefined);
  const [authType, setAuthType] = React.useState<AuthTypes | undefined>(undefined);

  const getUserData = () => {
    setAttempts((prevAttempts) => prevAttempts + 1);

    if (attempts < MAX_ATTEMPTS) {
      setPending(true);

      fetchTokenInfo()
        .then((data) => {
          setUserData(data);
          if (data?.View) {
            setAuthType(data.View.tokenType ?? AUTH_TYPES.Captcha);
          }

          if (!data.IsSucceeded) {
            const errorMessage = data.Error.ErrorMessage || data.Message || 'request error';
            throw new Error(errorMessage);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  React.useEffect(() => {
    const authCookie = getCookie('isAuthorized');
    setIsAuth(!!Number(authCookie));
  }, []);

  React.useEffect(() => {
    if (!pending && !userData) {
      getUserData();
    }
  }, [pending, isUserAuthenticated, userData]);

  return { isUserAuthenticated, userData, authType };
};
