import axios from 'axios';
import { path } from 'ramda';

import { formatPhone } from 'utils/format-string';

import {
  OPEN_POPUP as OPEN_CONNECTION_POPUP,
  SET_STATUS as SET_CONNECTION_STATUS,
} from '../components/ServiceStatusPopup/actions';

export const OPEN_BLACK_LIST_POPUP = 'open black list popup';
export const CLOSE_BLACK_LIST_POPUP = 'close black list popup';

export const OPEN_RECENT_CALLS_POPUP = 'open recent calls popup';
export const CLOSE_RECENT_CALLS_POPUP = 'close recent calls popup';

export const SET_BLACK_NUMBERS = 'set black numbers';
export const ADD_BLACK_NUMBER = 'add black number';
export const ADD_BLACK_NUMBERS = 'add black numbers';
export const REMOVE_BLACK_NUMBER = 'remove black number';
export const SET_BLACK_NUMBERS_FROM_SERVER = 'set black number from server';
export const SET_LAST_REMOVED_NUMBER = 'set last removed number';
export const SET_ERROR = 'set error';
export const THROW_BLACK_LIST_POPUP_ERROR = 'thromw bl error';
export const CLEAR_BLACK_LIST_POPUP_ERROR = 'clear black list error';
export const SET_BLACK_NUMBER_OPTIONS = 'set black numbers options';
export const CLEAR_BLACK_LIST = 'clear black list';
export const SET_BLACKLIST_STATUS = 'set black list status';
export const setBlackList = (list) => ({ type: SET_BLACK_NUMBERS, payload: list });
export const setBlackNumbersFromServer = (list) => ({
  type: SET_BLACK_NUMBERS_FROM_SERVER,
  payload: list,
});
export const setError = (error) => ({ type: SET_ERROR, payload: error });
export const setOptions = (options) => ({ type: SET_BLACK_NUMBER_OPTIONS, payload: options });

// special actions

export const openBlackList = () => (dispatch) => {
  dispatch({ type: SET_BLACKLIST_STATUS, payload: { touched: true } });
  dispatch({ type: OPEN_BLACK_LIST_POPUP });
};

export const closeBlackList = () => (dispatch) => {
  dispatch({ type: CLOSE_BLACK_LIST_POPUP });
  dispatch({ type: CLEAR_BLACK_LIST_POPUP_ERROR });
};

export const saveBlackList = () => (dispatch, getState) => {
  dispatch({ type: SET_LAST_REMOVED_NUMBER, payload: null });
  const error = path(['external', 'blackList', 'blackListPopup', 'error'], getState());
  if (error) {
    return;
  }
  dispatch(closeBlackList());
};

export const initBlackList = (serverListData) => (dispatch) => {
  if (serverListData && serverListData.length) {
    const serverList = serverListData.map(formatPhone);
    dispatch(setBlackNumbersFromServer(serverList));
    dispatch(setBlackList(serverList));
  }
};

export const connectBlackNumbers = () => (dispatch, getState) => {
  const key = 'blackListService';
  const numbers = path(['external', 'blackList', 'blackNumbers'], getState());
  if (!numbers) return;
  dispatch({ type: OPEN_CONNECTION_POPUP, payload: { key } });
  dispatch({ type: SET_CONNECTION_STATUS, payload: { key, status: 'loading' } });
  /* eslint-disable no-useless-escape */
  axios
    .post('/customers/products/mobile/services/blacklistsave/', {
      clearJson: 'true',
      ctn: numbers.map((value) => value.replace(/[+()\s\-]/gi, '')),
      connected: true,
    })
    .then((res) => {
      if (res.data && res.data.result) {
        dispatch(setBlackNumbersFromServer(numbers));
        dispatch({ type: SET_CONNECTION_STATUS, payload: { key, status: 'success' } });
      } else {
        dispatch({ type: SET_CONNECTION_STATUS, payload: { key, status: 'error' } });
      }
    })
    .catch(() => {
      dispatch({ type: SET_CONNECTION_STATUS, payload: { key, status: 'error' } });
    });
  /* eslint-enable no-useless-escape */
};
