import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';
import Icon from 'pages-components/Icon';

// Styles

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

/**
 * Компонент содержит иконку слева и текст во всю ширину;
 * ------------------------------------------------------
 * @param icon - Иконка слева от текста;
 * @param text  - Текст кнопки;
 * @param className - Дополнительный класс;
 * @param descriptionTooltip - Текст для tooltip;
 * @param isLayoutYoung - отображение для тарифа z;
 */
const Actions = ({ icon, text, className, descriptionTooltip, isLayoutYoung }) => {
  let paramName = 'name';

  // считаем, что это не alias и показываем как есть
  if (icon.includes('.') || icon.includes('/')) {
    paramName = 'src';
  }

  const iconParams = {
    [paramName]: icon,
  };

  return (
    <div className={cx('tiles', 'actions', className, isLayoutYoung && 'layoutYoung')}>
      <div className={cx('about', { fullWidth: !descriptionTooltip })}>
        {icon && (
          <span className={cx('icon')}>
            <Icon {...iconParams} />
          </span>
        )}
        <div className={cx('infoBox')}>
          <span className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
          {descriptionTooltip && (
            <Tooltip className={cx('textTooltip')}>
              <span dangerouslySetInnerHTML={{ __html: descriptionTooltip }} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

Actions.defaultProps = { icon: Ctx.tiles.actionsIcon };

Actions.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  descriptionTooltip: PropTypes.string,

  isLayoutYoung: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Actions;
