import {
  ADD_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE,
  ADD_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS,
  REMOVE_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE,
  REMOVE_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS,
  SET_FTTB_TARIFF_PAGE_DATA,
} from '../actions/actionTypes';
import { popupAndUpsaleTypes } from '../TariffPage/constants';
import { getPopupDataFromPageData } from '../selectors/fttbTariffPage';

const reducers = {
  [SET_FTTB_TARIFF_PAGE_DATA]: (state, payload) => ({
    ...state,
    fttbTariffPage: {
      ...state.fttbTariffPage,

      originData: payload,
    },
  }),
  [ADD_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE]: (state, { alias }) => {
    const service = state.fttbTariffPage.originData.upsales.find(
      (upsale) => upsale.serviceAlias === alias,
    );

    const selectedAdditionalServices = state.fttbTariffPage.selectedAdditionalServices || [];
    if (selectedAdditionalServices.find((s) => s.alias === alias)) return state;

    const newselectedAdditionalServices = [
      ...selectedAdditionalServices,
      {
        name: service.name,
        fee: service.initialFee.value,
        unit: service.initialFee.unit,
        alias: service.serviceAlias,
      },
    ];
    return {
      ...state,
      fttbTariffPage: {
        ...state.fttbTariffPage,
        selectedAdditionalServices: newselectedAdditionalServices,
      },
    };
  },
  [REMOVE_FTTB_TARIFF_PAGE_ADDITIONAL_SERVICE]: (state, { alias }) => {
    const filtredAdditionalServices = (
      state.fttbTariffPage.selectedAdditionalServices || []
    ).filter((service) => service.alias !== alias);

    return {
      ...state,
      fttbTariffPage: {
        ...state.fttbTariffPage,
        selectedAdditionalServices: filtredAdditionalServices,
      },
    };
  },

  [ADD_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS]: (state, { alias, groupAlias }) => {
    const selectedAntiviruses = state.fttbTariffPage.selectedAntiviruses || [];

    if (selectedAntiviruses.find((antivirus) => antivirus.alias === alias)) return state;

    const antivirusGroup = getPopupDataFromPageData(
      state.fttbTariffPage.originData,
      popupAndUpsaleTypes.Antivirus,
    ).groups.find((group) => group.alias === groupAlias);
    const currentAntivirus = antivirusGroup.antiviruses.find(
      (antivirus) => antivirus.alias === alias,
    );

    const filtredSelectedAntivivruses = selectedAntiviruses.filter(
      (antivirus) => antivirus.groupAlias !== groupAlias,
    );
    const newselectedAntiviruses = [
      ...filtredSelectedAntivivruses,
      {
        alias,
        groupAlias,
        name: currentAntivirus.name,
        fee: currentAntivirus.fee.value,
        unit: currentAntivirus.fee.unit,
      },
    ];
    return {
      ...state,
      fttbTariffPage: {
        ...state.fttbTariffPage,
        selectedAntiviruses: newselectedAntiviruses,
      },
    };
  },
  [REMOVE_FTTB_TARIFF_PAGE_SELECTED_ANTIVIRUS]: (state, { alias }) => {
    const selectedAntiviruses = state.fttbTariffPage.selectedAntiviruses || [];

    const filtredSelectedAntivivruses = selectedAntiviruses.filter(
      (antivirus) => antivirus.alias !== alias,
    );

    return {
      ...state,
      fttbTariffPage: {
        ...state.fttbTariffPage,
        selectedAntiviruses: filtredSelectedAntivivruses,
      },
    };
  },
};

export const fttbTariffPage = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
