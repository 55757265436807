import { combineReducers } from 'redux';
import { assoc, merge } from 'ramda';

import * as actionTypes from './actions';
import { TOGGLE_ERROR_POPUP } from './actionsTypes';

const popup = (state = {}, action) => {
  if (action.type === actionTypes.OPEN_POPUP) {
    return assoc(action.payload.key, { opened: true }, state);
  }

  if (action.type === actionTypes.CLOSE_POPUP) {
    return assoc(action.payload.key, { opened: false }, state);
  }

  if (action.type === TOGGLE_ERROR_POPUP) {
    const { data, opened, status } = action.payload;

    return merge(state, {
      data,
      opened,
      status,
    });
  }

  return state;
};

const data = (state = {}, action) => {
  if (action.type === actionTypes.SET_STATUS) {
    return assoc(action.payload.key, action.payload, state);
  }
  return state;
};

const root = combineReducers({
  popup,
  data,
});

export const serviceStatus = (state, action) =>
  assoc('serviceStatus', root(state.serviceStatus, action), state);
