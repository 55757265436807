import { init as initSentry } from '@sentry/react';
import { useWillMount } from '@beef/react';
import { clientOnlyHook } from '@beef/utils';

import { getSentryConfig } from './helpers';

const _useSentry = (env?: null | string): void => {
  useWillMount(() => {
    initSentry(getSentryConfig(env));
  });
};

export const useSentry = clientOnlyHook(_useSentry);
