import React, { useCallback } from 'react';
import { withHMR } from '@beef/react';
import { Footer } from '@beef/smart-kit';

const FooterV2 = ({ data }) => {
  const onMobileAppClick = useCallback((store) => {
    if (window && window?.dataLayer) {
      window.dataLayer.push({
        event: 'storeClick',
        store,
      });
    }
  }, []);

  return <Footer data={data} mobileAppClickCallback={onMobileAppClick} />;
};

export default withHMR(module)(FooterV2);
