import axios from 'axios';
import { path, pathOr } from 'ramda';
import qs from 'query-string';

import { maskPhone, unformatPhone } from 'utils/format-string';
import { getCookie, setCookie } from 'utils/cookie';
import { poll } from 'utils/timed-functions';
import { getGAClientId, pushSimOrderEvent } from 'utils/ga';

export const ORDER_PAYMENT_FORM_TOGGLE = 'ORDER_PAYMENT_FORM_TOGGLE';
export const ORDER_PAYMENT_FORM_SET_DATA = 'ORDER_PAYMENT_FORM_SET_DATA';
export const ORDER_PAYMENT_TOGGLE_RESULT_POPUP = 'ORDER_PAYMENT_TOGGLE_RESULT_POPUP';
export const ORDER_PAYMENT_SET_RESULT_POPUP_DATA = 'ORDER_PAYMENT_SET_RESULT_POPUP_DATA';
export const ORDER_PAYMENT_SET_DELIVERY_PAYMENT_DATA = 'ORDER_PAYMENT_SET_DELIVERY_PAYMENT_DATA';

const COOKIE_PAY_WAITING_NAME = '_6182_waiting';

export const setOrderPaymentFormData = (payload) => (dispatch) => {
  dispatch({
    type: ORDER_PAYMENT_FORM_SET_DATA,
    payload,
  });
};

export const toggleOrderPaymentForm = (show) => (dispatch) => {
  dispatch({
    type: ORDER_PAYMENT_FORM_TOGGLE,
    payload: { show },
  });
};

export const toggleOrderPaymentResultPopup = (popupIsActive) => (dispatch) => {
  dispatch({
    type: ORDER_PAYMENT_TOGGLE_RESULT_POPUP,
    payload: popupIsActive,
  });
};

export const setOrderPaymentResultPopupData = (data, phone) => (dispatch) => {
  dispatch({
    type: ORDER_PAYMENT_SET_RESULT_POPUP_DATA,
    payload: {
      ...data,
      description:
        phone ? data.description.replace('{{phone}}', maskPhone(phone)) : data.description,
    },
  });
};

const setDeliveryPaymentData = (payload) => (dispatch) => {
  dispatch({
    type: ORDER_PAYMENT_SET_DELIVERY_PAYMENT_DATA,
    payload,
  });
};

export const parseUrlForNeedOpenOrderPaymentResultPopup = (content) => (dispatch) => {
  if (!content.deliveryForm) return null;

  const {
    deliveryForm: { paymentSuccessPopup, paymentFailPopup },
  } = content;
  const params = qs.parse(window.location.search);
  const cookie = +getCookie(COOKIE_PAY_WAITING_NAME);

  if (cookie && params.orderId && params.phone && params.slugFrontend) {
    if (params.paymentSuccess && paymentSuccessPopup) {
      dispatch(setOrderPaymentResultPopupData(paymentSuccessPopup, params.phone));
      dispatch(toggleOrderPaymentResultPopup(true));
    }

    if (params.paymentFail && paymentFailPopup) {
      dispatch(setOrderPaymentResultPopupData(paymentFailPopup));
      dispatch(toggleOrderPaymentResultPopup(true));
    }
  }
};

export const deliveryPayment = async ({ orderNumber, shopSlug, phone }) => {
  const parameters = {};
  const urlParams = new URLSearchParams(window.location.search);
  const cookie = +getCookie(COOKIE_PAY_WAITING_NAME);

  if (
    cookie &&
    urlParams.has('orderId') &&
    urlParams.has('phone') &&
    urlParams.has('slugFrontend')
  ) {
    parameters.slugFrontend = urlParams.get('slugFrontend');
    parameters.orderId = urlParams.get('orderId');
    parameters.phone = urlParams.get('phone');
  } else {
    parameters.slugFrontend = shopSlug;
    parameters.orderId = orderNumber;
    parameters.phone = phone;
  }

  const baseUrl = `${window.location.origin}${window.location.pathname}`;
  const urlParamsForRedirect = new URLSearchParams();
  urlParamsForRedirect.append('orderId', parameters.orderId);
  urlParamsForRedirect.append('phone', unformatPhone(parameters.phone));
  urlParamsForRedirect.append('slugFrontend', parameters.slugFrontend);

  const paymentData = {
    slugFrontend: parameters.slugFrontend,
    urlSuccess: `${baseUrl}?paymentSuccess=true&${urlParamsForRedirect}`,
    urlFailed: `${baseUrl}?paymentFail=true&${urlParamsForRedirect}`,
    urlCancel: `${baseUrl}?connect=true`,
  };

  const formData = new FormData();
  formData.append('orderNumber', parameters.orderId);
  formData.append('phone', unformatPhone(parameters.phone));
  formData.append('offersAccepted', 'true');
  formData.append('paymentData', JSON.stringify(paymentData));

  try {
    const { data } = await axios.post('/shop/basket/GetOnlinePaymentData/', formData, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'multipart/form-data',
      },
    });

    if (data.IsSucceeded) {
      const response = JSON.parse(data.View);
      if (response.isSuccess) {
        setCookie({
          name: COOKIE_PAY_WAITING_NAME,
          value: 1,
          maxAge: 1200,
        });

        const form = document.createElement('form');
        document.body.appendChild(form);
        form.target = '_self';
        form.method = 'POST';
        form.action = response.data.RequestUrl;
        form.submit();
        return {};
      }
    }

    throw new Error('Payment Is Not Available');
  } catch (e) {
    console.error(e);
    return { showErrorPopup: true };
  }
};

const reserveNumber = async ({ dpcId, ...payload }, analyticsData) => {
  const { data } = await axios.post(
    '/customers/products/mobile/tariffs/reservenumberwithmobiletariff/',
    qs.stringify(payload),
  );
  if (!data.isSucceeded || !data.view) {
    throw {
      msg: data.view?.content || '',
      isCaptchaRequired: data.IsCaptchaRequired,
    };
  }

  const { orderId, shopSlug } = data.view;
  pushSimOrderEvent({ orderId, ...analyticsData });

  return {
    shopSlug,
    orderNumber: data.view.orderId,
  };
};

const makeOrder = async ({ tariffId, ...payload }, analyticsData) => {
  const { data } = await axios.post('/fancynumber/makeOrder', qs.stringify(payload));
  if (!data.IsSuccess || data.IsCaptchaRequired || data.IsCaptchaError) {
    throw {
      msg: data.Message,
      isCaptchaRequired: data.IsCaptchaRequired || data.IsCaptchaError,
    };
  }

  pushSimOrderEvent({ orderId: data.OrderId, ...analyticsData });

  return {
    orderNumber: data.OrderId,
    shopSlug: data.ShopSlug,
  };
};

const eSIMPayment = (orderNumber, payButton) => async (dispatch, getState) => {
  const orderPaymentData = getState().external.orderPaymentForm;

  const esimAvailable = await poll(
    async () => {
      try {
        const resp = await axios.post(orderPaymentData.checkAvailabilityUrl, { orderNumber });
        return resp.data.isAvailable;
      } catch (err) {
        return false;
      }
    },
    (isAvailable) => isAvailable === null,
    1000,
    orderPaymentData.pollingTime * 1000,
  );

  if (esimAvailable) {
    if (payButton) {
      return { returnUrl: `${orderPaymentData.orderPageUrl}${orderNumber}` };
    }

    window.location.href = `${orderPaymentData.cardViewUrl}${orderNumber}`;
    return;
  }

  throw new Error('server error');
};

export const submitOrderPaymentForm = (payload) => async (dispatch, getState) => {
  const scenarioType = path(['deliveryForm', 'deliveryData', 'activeTab'], getState().external);
  const isSelectedFastDelivery = pathOr(
    false,
    ['deliveryForm', 'inputValues', 'isSelectedFastDelivery'],
    getState().external,
  );

  if (scenarioType === 'delivery' && isSelectedFastDelivery && new Date().getHours() >= 18) {
    const popupContent = path(
      ['deliveryForm', 'baseDeliveryDaysData', 'expiredPeriodFastDelivery'],
      getState().external,
    );

    await dispatch(setOrderPaymentResultPopupData(popupContent));
    dispatch(toggleOrderPaymentResultPopup(true));
    dispatch(toggleOrderPaymentForm(false));
    return null;
  }

  const state = getState();
  const orderPaymentData = state.external.orderPaymentForm;
  const isForYoungDetailEnabled = state.external?.tariff?.data?.isForYoungDetailEnabled;
  const deliveryData = scenarioType === 'delivery' ? payload.getDeliveryData() : {};

  const postData = {
    number: orderPaymentData.chosenNumber || state.external?.funcyNumberOrder?.newChosenNumber,
    contactNumber: orderPaymentData.contactPhone,
    tariffId: orderPaymentData.tariffId,
    dpcId: orderPaymentData.tariffId,
    email: orderPaymentData.email,
    Captcha: payload.captcha || undefined,
    gaClientId: getGAClientId(),
    _captcha_key: payload.captchaKey,
    esim: scenarioType === 'esim',
    orderPlace: payload.orderPlace,
    optionSocs: state.external?.tariff?.data?.optionSocs,
    constructorId: state.external?.tariff?.data?.tariffConstructorId,
    ...deliveryData,
    ...orderPaymentData.FTTBData,
  };

  const isReserveCardRequest =
    payload.orderPlace === 'tariffCard' || payload.orderPlace === 'TariffZLand';
  const additionalSimCount =
    getState().external?.tariffConnectionPopup?.additionalOptions?.additionalSimCount;
  const chosenSocs = getState().external?.tariffConnectionPopup?.additionalOptions?.chosenSocs;
  const promoSoc = getState().external?.tariffConnectionPopup?.additionalOptions?.promoSoc;

  if (isForYoungDetailEnabled && chosenSocs) {
    postData.additionalSocs = chosenSocs;
    if (promoSoc) {
      postData.additionalSocs = `${postData.additionalSocs},${promoSoc}`;
    }
  }

  if (isReserveCardRequest && additionalSimCount) {
    postData.additionalSimCount = additionalSimCount;
  }

  const analyticsData = {
    tariffName: payload.tariffName,
    soc: payload.soc,
    numberSubCategory: payload.numberCategory,
    method: orderPaymentData.chosenNumber ? 'SimNumber' : 'Sim',
    shipping: deliveryData && deliveryData['delivery.type'],
  };

  try {
    const result =
      isReserveCardRequest ?
        await reserveNumber(postData, analyticsData)
      : await makeOrder(postData, analyticsData);

    if (scenarioType === 'delivery') {
      const deliveryPaymentData = {
        phone: orderPaymentData.contactPhone,
        orderNumber: result.orderNumber,
        shopSlug: result.shopSlug,
      };
      dispatch(setDeliveryPaymentData(deliveryPaymentData));
      return await deliveryPayment(deliveryPaymentData);
    }

    if (scenarioType === 'esim') {
      // Прстите меня, пожалуйст. пятница - 21:18, я очень устал((
      window.esimOrderNumber = result.orderNumber;
      return await dispatch(eSIMPayment(result.orderNumber, payload.payButton));
    }
  } catch (err) {
    const message =
      err.msg && err.msg.replace('{{number}}', maskPhone(orderPaymentData.chosenNumber));

    return {
      showError: true,
      errorMessage: message || 'Не удалось оформить заказ, попробуйте еще раз',
      captchaRequired: err.isCaptchaRequired,
    };
  }
};
