export const AUTH_STEPS = {
  initial: 'INITIAL',
  smsInitial: 'SMS_INITIAL',
  mobileIdInitial: 'MOBILE_ID_INITIAL',
  smsCode: 'SMS_CODE',
  smsCaptcha: 'SMS_CAPTCHA',
  password: 'PASSWORD',
  mobileId: 'MOBILE_ID',
  mobileIdCaptcha: 'MOBILE_ID_CAPTCHA',
};

export const ID_CONFIG = {
  mobileIdInitial: AUTH_STEPS.mobileIdInitial,
  mobileIdCaptcha: AUTH_STEPS.mobileIdCaptcha,
  smsCaptcha: AUTH_STEPS.smsCaptcha,
  password: AUTH_STEPS.password,
  smsInitial: AUTH_STEPS.smsInitial,
  smsCode: AUTH_STEPS.smsCode,
};

export const LOGIN_TYPES = {
  mobile: 'mobile',
  mobileConvergence: 'mobileConvergence',
  login: 'login',
  b2b: 'b2b',
  unknown: 'unknown',
};

export const MESSAGES = {
  AUTH_ABANDONBO_ERROR: 'Доступ в кабинет ограничен',
  AUTH_MOBILE_ID_INVALID_CTN: 'Авторизация по Mobile ID доступна только по номеру телефона',
  AUTH_MOBILE_ID_DEFAULT_ERROR: 'Что-то пошло не так, пожалуйста, повторите попытку позже',
  DEFAULT: 'Ой! Что-то пошло не так',
  MOBILE_ID_NOT_BEELINE: 'Вход по Мобильному ID доступен только абонентам билайн',
  SMS_NOT_BEELINE: 'Вход по SMS доступен только абонентам билайн',
};
