import { useWillMount } from '@beef/react';
import { EHeaderTheme } from '@carnica/smart-kit';

import { setHeaderTheme } from './utils';

export const usePrimaryHeaderTheme = () => {
  useWillMount(() => {
    setHeaderTheme(EHeaderTheme.primary);
  });
};
