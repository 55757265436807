import axios from 'axios';

import { axiosErrorHandler, jqueryErrorHandler } from 'utils/globalAjaxHandler';

export const setInterceptors = () => {
  if (typeof window !== 'undefined' && window.ROLE_MODEL_ACTIVE) {
    axios.interceptors.response.use(null, axiosErrorHandler);
    jqueryErrorHandler();
  }
};
