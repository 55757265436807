import { emptyObj } from '@beef/utils';

const getFttbConnectionState = (state) => state.external?.fttbConnectionState || emptyObj;

const getCurrentState = (state) => getFttbConnectionState(state).currentState || null;
export const getConnectionData = (state) =>
  getFttbConnectionState(state).connectionData || emptyObj;
export const getConnectionDataHouse = (state) => getConnectionData(state).house || emptyObj;

export const isConnectionDataLoading = (state) => getCurrentState(state) === 'loading';
export const isConnectionDataLoaded = (state) => getCurrentState(state) === 'loaded';
export const isInitialLoadConnectionData = (state) =>
  getFttbConnectionState(state).isInitialLoadData || false;
