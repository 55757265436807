const SET_TOGGLE_MODAL = 'SET_TOGGLE_MODAL';
const INIT_DATA_SPN = 'INIT_DATA_SPN';
const SET_STEP_MODAL_SPN = 'SET_STEP_MODAL_SPN';
const SET_DATA_FROM_MAIN_STEP = 'SET_DATA_FROM_MAIN_STEP';
const SET_LOTTIE_ANIMATION = 'SET_LOTTIE_ANIMATION';
const WAITING_REQUEST = 'WAITING_REQUEST';
const SET_DETAILS_OF_SPN = 'SET_DETAILS_OF_SPN';
const ERROR_VALIDATION = 'ERROR_VALIDATION';
const SET_LOADER_CONTENT_STEP = 'SET_LOADER_CONTENT_STEP';
const SET_ERROR_TEXT = 'SET_ERROR_TEXT';
const SET_LOADING_MODAL = 'SET_LOADING_MODAL';

const SET_TEMP_EDITABLE_SPN = 'SET_TEMP_EDITABLE_SPN';

const setInitDataSpn = (payload) => (dispatch) => {
  dispatch({
    type: INIT_DATA_SPN,
    payload,
  });
};

const setToggleModal = () => (dispatch) => {
  dispatch({
    type: SET_TOGGLE_MODAL,
  });
};

const setStepModalSpn = (payload) => (dispatch) => {
  dispatch({
    type: SET_STEP_MODAL_SPN,
    payload,
  });
};

const setDataFromMainStep = (payload) => (dispatch) => {
  dispatch({
    type: SET_DATA_FROM_MAIN_STEP,
    payload,
  });
};

const setTempEditableSpn = (payload) => (dispatch) => {
  dispatch({
    type: SET_TEMP_EDITABLE_SPN,
    payload,
  });
};

const setLoadingModal = () => (dispatch) => {
  dispatch({
    type: SET_LOADING_MODAL,
  });
};

const setWaitingRequest = () => (dispatch) => {
  dispatch({
    type: WAITING_REQUEST,
  });
};

const setDetailsForSpn = (payloadObj) => (dispatch) => {
  dispatch({
    type: SET_DETAILS_OF_SPN,
    payload: payloadObj,
  });
};

const setErrorValidation = (payload) => (dispatch) => {
  dispatch({
    type: ERROR_VALIDATION,
    payload,
  });
};

const setErrorText = (payload) => (dispatch) => {
  dispatch({
    type: SET_ERROR_TEXT,
    payload,
  });
};

const setLoaderContentStep = (payload) => (dispatch) => {
  dispatch({
    type: SET_LOADER_CONTENT_STEP,
    payload,
  });
};

export {
  ERROR_VALIDATION,
  INIT_DATA_SPN,
  SET_DATA_FROM_MAIN_STEP,
  SET_DETAILS_OF_SPN,
  SET_ERROR_TEXT,
  SET_LOADER_CONTENT_STEP,
  SET_LOADING_MODAL,
  SET_LOTTIE_ANIMATION,
  SET_STEP_MODAL_SPN,
  SET_TEMP_EDITABLE_SPN,
  SET_TOGGLE_MODAL,
  WAITING_REQUEST,
  setDataFromMainStep,
  setDetailsForSpn,
  setErrorText,
  setErrorValidation,
  setInitDataSpn,
  setLoaderContentStep,
  setLoadingModal,
  setStepModalSpn,
  setTempEditableSpn,
  setToggleModal,
  setWaitingRequest,
};
