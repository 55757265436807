import axios from 'axios';

import checkConnection from './checkConnection';
import { convertConnectionDataToCheckConnectionData } from '../utils/checkAddressHelper';
import { requestConfig } from '../constants';

const SET_STATE_URL = '/hometariffsapi/setstate/';

const setConnectionState = (connectionData) => {
  const { street, house = {}, flat } = connectionData;

  if (flat) {
    const requestData = convertConnectionDataToCheckConnectionData(connectionData);
    // для того, что бы сохранить квартиру вызываем метод `checkConnection`,
    // т.к. его побочное действие сохранение проверяемого адреса, включая квартиру
    return checkConnection(requestData);
  }

  const requestData = {
    StreetId: street.id,
    Street: street.label,
    House: house.label,
    HouseId: house.id,
    IsConnected: house.isConnected,
  };

  return axios.post(SET_STATE_URL, requestData, requestConfig);
};

export default setConnectionState;
