import { merge } from 'ramda';

import {
  CALLBACK_DOWNSELL,
  ON_DOWNSELL_OFFER_STEP,
  OPEN_TARIFF_DESCRIPTION_POPUP,
  SET_ADDITIONAL_SIM_COUNT,
  SET_ALIAS,
  SET_CHOSEN_SOCS,
  SET_CONFIRM_NUMBER_POPUP,
  SET_CONNECTION_RESULT_POPUP,
  SET_DISCONNECT_NUMBER_POPUP,
  SET_DISCONNECT_SUCCESS_POPUP,
  SET_DISCONNECT_WARNING_POPUP,
  SET_TABS_TO_DISPLAY,
  SET_TARIFF_CHANGE_RESULT_POPUP,
  SET_TARIFF_CONNECTION_POPUP,
  SET_TARIFF_IN_PROGRESS_POPUP,
  TOGGLE_CONFIRM_NUMBER_POPUP,
  TOGGLE_TARIFF_CONNECTION_POPUP,
  UPDATE_CONNECTED_NUMBERS,
} from '../actions/actionTypes';

const updateConnectionPopup = (state, popupState) => ({
  ...state,
  tariffConnectionPopup: {
    ...state.tariffConnectionPopup,
    ...popupState,
  },
  isPending: false,
});

const updateConfirmNumberPopup = (state, popupState) => ({
  ...state,
  confirmNumberPopup: {
    ...state.confirmNumberPopup,
    ...popupState,
  },
});

export function tariffConnectionReducer(state = {}, { type, payload }) {
  switch (type) {
    case TOGGLE_TARIFF_CONNECTION_POPUP: {
      return {
        ...state,
        tariffConnectionPopup: {
          ...state.tariffConnectionPopup,
          popupIsActive: !!payload.popupIsActive,
        },
      };
    }

    case SET_TARIFF_IN_PROGRESS_POPUP:
    case SET_TARIFF_CONNECTION_POPUP: {
      return updateConnectionPopup(state, payload);
    }

    case CALLBACK_DOWNSELL:
    case ON_DOWNSELL_OFFER_STEP: {
      return updateConnectionPopup(state, payload);
    }

    case SET_CONNECTION_RESULT_POPUP: {
      return updateConnectionPopup(state, {
        resultPopupData: { ...payload },
      });
    }

    case SET_TARIFF_CHANGE_RESULT_POPUP: {
      return updateConnectionPopup(state, {
        changeResultPopupData: { ...payload },
      });
    }

    case SET_DISCONNECT_NUMBER_POPUP: {
      return updateConnectionPopup(state, {
        disconnectNumberPopup: { ...payload },
      });
    }

    case SET_DISCONNECT_SUCCESS_POPUP: {
      return updateConnectionPopup(state, {
        disconnectSuccessPopup: { ...payload },
      });
    }

    case SET_DISCONNECT_WARNING_POPUP: {
      return updateConnectionPopup(state, {
        disconnectWarningPopup: { ...payload },
      });
    }

    case SET_CONFIRM_NUMBER_POPUP: {
      return updateConfirmNumberPopup(state, payload);
    }

    case TOGGLE_CONFIRM_NUMBER_POPUP: {
      return updateConfirmNumberPopup(state, payload);
    }

    case OPEN_TARIFF_DESCRIPTION_POPUP:
      return merge(state, {
        ...state,
        isTariffDescriptionPopupOpen: payload,
      });

    case SET_TABS_TO_DISPLAY:
      return updateConnectionPopup(state, {
        tabsToDisplay: payload,
      });

    case SET_ADDITIONAL_SIM_COUNT:
    case SET_CHOSEN_SOCS:
      return updateConnectionPopup(state, {
        additionalOptions: {
          ...payload,
        },
      });

    case SET_ALIAS:
      return { ...state, alias: payload };

    case UPDATE_CONNECTED_NUMBERS:
      return updateConnectionPopup(state, {
        data: {
          ...state.tariffConnectionPopup.data,
          sebConnectForm: {
            ...state.tariffConnectionPopup.data.sebConnectForm,
            numbers: payload,
          },
        },
      });
    default: {
      return state;
    }
  }
}
