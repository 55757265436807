import { SET_ALWAYS_IN_POSITIVE_VALUE } from '../actions/alwaysInPositive';

export function alwaysInPositiveReducer(state = {}, { type, payload }) {
  switch (type) {
    case SET_ALWAYS_IN_POSITIVE_VALUE: {
      return {
        ...state,
        alwaysInPositive: {
          ...state.alwaysInPositive,
          ...payload,
        },
      };
    }

    default: {
      return state;
    }
  }
}
