import { merge } from 'ramda';

const simpleMutator = (state, action) => action.payload;
const mergeMutator = (state, action) => merge(state, action.payload);

export const simpleReducer =
  (actionType, initialState = {}, mutator = simpleMutator) =>
  (state = initialState, action) => {
    if (action.type === actionType) {
      return mutator(state, action);
    }

    return state;
  };

export const mergeReducer = (actionType, initialState) =>
  simpleReducer(actionType, initialState, mergeMutator);
