import axios from 'axios';
import { path, pathOr } from 'ramda';
import qs from 'query-string';

import { pushUpsellConnectErrorEvent, pushUpsellConnectSuccessEvent } from 'utils/ga';

export const SET_ALWAYS_IN_POSITIVE_VALUE = 'SET_ALWAYS_IN_POSITIVE_VALUE';

const setPopupValue = (popupData) => (dispatch) =>
  dispatch({
    type: SET_ALWAYS_IN_POSITIVE_VALUE,
    payload: { popupData },
  });

export const resetPopupData = () => (dispatch, getState) => {
  const step = path(['external', 'alwaysInPositive', 'popupData', 'step'], getState());

  if (step === 'success' && typeof window?.location !== 'undefined') {
    const urlParams = qs.parse(window?.location.search);
    urlParams.connect = null;
    window.location.href = `${window?.location.protocol}//${window?.location.host}${
      window?.location.pathname
    }?${qs.stringify(urlParams)}`;
  } else {
    dispatch({
      type: SET_ALWAYS_IN_POSITIVE_VALUE,
      payload: {
        popupData: null,
      },
    });
  }
};

export const changeLimitConfirmation = () => (dispatch, getState) => {
  const data = pathOr({}, ['external', 'alwaysInPositive', 'confirmationPopupData'], getState());

  dispatch(
    setPopupValue({
      step: 'confirmation',
      ...data,
    }),
  );
};

let gaPayload = null;

const startPolling = (payload, interval) => (dispatch, getState) => {
  axios(payload)
    .then((response) => {
      const {
        data,
        data: { view },
      } = response;

      if (view.requestStatus === 'IN_PROGRESS') {
        setTimeout(() => {
          dispatch(startPolling(payload, interval));
        }, interval * 1000);
        return;
      }

      if (!data.isSucceeded) {
        return Promise.reject({ response });
      }

      dispatch(
        setPopupValue({
          ...view,
          step: 'success',
        }),
      );
      pushUpsellConnectSuccessEvent(gaPayload);
    })
    .catch(({ response }) => {
      dispatch(
        setPopupValue({
          ...(response.data.view ||
            pathOr({}, ['external', 'alwaysInPositive', 'errorPopupData'], getState())),
          step: 'error',
        }),
      );
      pushUpsellConnectErrorEvent(gaPayload);
    });
};

export const changeLimit = () => (dispatch, getState) => {
  const { changeLimitUrl, soc, amount, serviceName, marketCode, errorPopupData } = pathOr(
    {},
    ['external', 'alwaysInPositive'],
    getState(),
  );

  dispatch(
    setPopupValue({
      step: 'pending',
      isSuccess: true,
    }),
  );

  gaPayload = { serviceName, soc, marketCode };

  axios({ url: changeLimitUrl, method: 'post', data: { soc, amount } })
    .then((resp) => {
      const {
        data,
        data: { view },
      } = resp;

      if (view.requestId && view.checkRequestStateUrl) {
        dispatch(
          startPolling(
            {
              url: view.checkRequestStateUrl,
              method: 'post',
              data: {
                Soc: soc,
                requestId: view.requestId,
              },
            },
            view.interval,
            view.timeout,
          ),
        );
        return;
      }

      if (!data.isSucceeded) {
        return Promise.reject({ resp });
      }

      dispatch(
        setPopupValue({
          ...view,
          step: 'success',
        }),
      );

      pushUpsellConnectSuccessEvent(gaPayload);
    })
    .catch(({ response: resp }) => {
      dispatch(
        setPopupValue({
          ...errorPopupData,
          step: 'error',
        }),
      );

      gaPayload.error = resp?.view?.content || 'unresolved exception';
      pushUpsellConnectErrorEvent(gaPayload);
    });
};
