export const TOGGLE_POINTS_LIST = 'map:togglePointsList';
export const TOGGLE_MODE = 'map:toggleMode';
export const GET_POINTS = 'map:getPoints';
export const CHOOSE_POINT = 'map:choosePoint';
export const HIDE_POINT_INFO = 'map:hidePointInfo';
export const HIDE_POINT_ON_MAP = 'map:hidePointOnMap';
export const SEARCH_ON_MAP = 'map:searchOnMap';
export const SEARCH_ON_MAP_ERROR = 'map:searchOnMapError';
export const SET_CURRENT_LOCATION = 'map:setCurrentLocation';
export const UPDATE_FILTER = 'map:toggleFilter';
export const SET_IS_ANNIVERSARY = 'map:setIsAnniversary';
export const GET_REQUEST_TOGGLE = 'map:getRequestToggle';
export const SET_UPDATE_FILTERS_FLAG = 'map:setUpdateFiltersFlag';

export const SEARCH_REGION_RF = [
  [41, 19],
  [75, 179.9999],
];
