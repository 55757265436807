import {
  GET_FTTB_CONNECTION_STATE_END,
  GET_FTTB_CONNECTION_STATE_START,
  SET_FTTB_CONNECTION_DATA,
} from 'pages/FTTB/actions';

const reducers = {
  [GET_FTTB_CONNECTION_STATE_START]: (state) => ({
    ...state,
    fttbConnectionState: { currentState: 'loading' },
  }),
  [GET_FTTB_CONNECTION_STATE_END]: (state, payload) => ({
    ...state,
    fttbConnectionState: {
      currentState: 'loaded',
      isInitialLoadData: true,
      connectionData: payload.connectionData,
    },
  }),
  [SET_FTTB_CONNECTION_DATA]: (state, payload) => ({
    ...state,
    fttbConnectionState: {
      ...state.fttbConnectionState,
      isInitialLoadData: false,
      connectionData: payload.connectionData,
    },
  }),
};

export const fttbConnectionState = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
