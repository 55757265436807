export const PROMO_ORDER_LEAD = 'https://apipromo.beeline.ru/shop/orderLead';
export const FANCY_NUMBER_PATH = '/fancynumber';

export const ALLINONE_GET_REGION = '/region/getsuggestions/';
export const ALLINONE_GET_STREET = '/checkconnection/GetStreets/';
export const ALLINONE_GET_HOUSE = '/checkconnection/GetHouses/';
export const ALLINONE_REQUEST = '/customers/products/mobile/convorder/nextstep/';
export const ALLINONE_REQUEST_HOUSE = '/checkconnection/ConnectionTicket';
export const ALLINONE_GET_CONVERGENT_ORDER_DATA =
  '/customers/products/mobile/convorder/getconvergentorderreactmodel/';
export const SERVICE_CONTEXTS_ENDPOINT = '/api/products/mobile/services/info/context/';
