export const SET_NUMBERS = 'SET_NUMBERS';
export const SET_NUMBERS_LOADING = 'SET_NUMBERS_LOADING';
export const SET_CHOSEN_NUMBER = 'SET_CHOSEN_NUMBER';
export const SET_EXPANDED_NUMBERS = 'SET_EXPANDED_NUMBERS';
export const SET_TARIFFS = 'SET_TARIFFS';
export const SET_TARIFFS_LOADING = 'SET_TARIFFS_LOADING';
export const SET_CHOSEN_TARIFF = 'SET_CHOSEN_TARIFF';
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOTTIE_ANIMATION = 'SET_LOTTIE_ANIMATION';
export const SET_PORTATION_NUMBER = 'SET_PORTATION_NUMBER';
export const SET_PORTATION_CHECK_LOADING = 'SET_PORTATION_CHECK_LOADING';
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
export const SET_PREESIM_MODAL = 'SET_PREESIM_MODAL';
export const CHANGED_REGION = 'CHANGED_REGION';
export const CHOOSE_BASKET_MODAL_COMPONENT = 'CHOOSE_BASKET_MODAL_COMPONENT';
export const SET_MODAL_BASKET_OPEN = 'SET_MODAL_BASKET_OPEN';
export const SET_IS_NEW_DESIGN = 'SET_IS_NEW_DESIGN';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SET_VALID_PAYMENT_DATA = 'SET_VALID_PAYMENT_DATA';
export const SET_PRELOADER = 'SET_PRELOADER';
export const CLEAR_LOTTIE_ANIMATION = 'CLEAR_LOTTIE_ANIMATION';
export const SET_FIRST_LOADED_PAGE = 'SET_FIRST_LOADED_PAGE';
export const SET_NOT_OPEN_PRE_MODAL = 'SET_NOT_OPEN_PRE_MODAL';
export const SET_USER_CONTACT_INFO_FROM_BASKET = 'SET_USER_CONTACT_INFO_FROM_BASKET';
export const SET_HAS_CLICKED_PAYMENT_BTN = 'SET_HAS_CLICKED_PAYMENT_BTN';
