import {
  ORDER_PAYMENT_FORM_SET_DATA,
  ORDER_PAYMENT_FORM_TOGGLE,
  ORDER_PAYMENT_SET_DELIVERY_PAYMENT_DATA,
  ORDER_PAYMENT_SET_RESULT_POPUP_DATA,
  ORDER_PAYMENT_TOGGLE_RESULT_POPUP,
} from '../actions/orderPaymentForm';

const getNewState = (oldState, newData) => ({
  ...oldState,
  ...{ orderPaymentForm: { ...oldState.orderPaymentForm, ...newData } },
});

export function orderPaymentForm(state = {}, action) {
  switch (action.type) {
    case ORDER_PAYMENT_FORM_TOGGLE: {
      return getNewState(state, { show: action.payload.show });
    }
    case ORDER_PAYMENT_FORM_SET_DATA: {
      return getNewState(state, action.payload);
    }
    case ORDER_PAYMENT_TOGGLE_RESULT_POPUP: {
      return getNewState(state, { showResultPopup: action.payload });
    }
    case ORDER_PAYMENT_SET_RESULT_POPUP_DATA: {
      return getNewState(state, { resultPopupData: action.payload });
    }
    case ORDER_PAYMENT_SET_DELIVERY_PAYMENT_DATA: {
      return getNewState(state, { deliveryPaymentData: action.payload });
    }
  }

  return state;
}
