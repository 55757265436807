const defaultState = {
  isModalOpen: false,
  modalStep: 'main',
  waitingRequest: false,
  hasErrorValidation: false,
  mainStepData: {},
  lottieData: {},
};

export default defaultState;
