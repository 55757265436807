import { flattenDeep } from 'lodash';

import {
  FETCH_FTTB_TV_CHANNELS_ERROR,
  FETCH_FTTB_TV_CHANNELS_START,
  FETCH_FTTB_TV_CHANNELS_SUCCESS,
  REMOVE_SELECTED_FTTB_TV_CHANNELS_PACKAGE,
  SET_SELECTED_FTTB_TV_CHANNELS_PACKAGE,
} from 'pages/FTTB/actions/actionTypes';

const reducers = {
  [FETCH_FTTB_TV_CHANNELS_START]: (state) => ({
    ...state,

    fttbTvChannelsData: { state: 'loading' },
  }),
  [FETCH_FTTB_TV_CHANNELS_SUCCESS]: (state, payload) => ({
    ...state,

    fttbTvChannelsData: {
      ...state.fttbTvChannelsData,

      state: 'loaded',
      tvChannelsData: payload.tvChannelsData,
      tariffAlias: payload.tariffAlias,
      selectedTvPackages: [],
    },
  }),
  [FETCH_FTTB_TV_CHANNELS_ERROR]: (state) => ({
    ...state,
    fttbTvChannelsData: { state: 'error' },
  }),

  [SET_SELECTED_FTTB_TV_CHANNELS_PACKAGE]: (state, payload) => {
    const { tvPackagesGroups } = state.fttbTvChannelsData.tvChannelsData;
    const allTvPackages = flattenDeep(
      tvPackagesGroups.map((tvPackagesGroup) => tvPackagesGroup.tvPackages),
    );

    const tvPackages = payload.tvPackagesAliases.map((tvPackageAlias) => {
      const selectedTvPackage = allTvPackages.find(
        (tvPackage) => tvPackage.alias === tvPackageAlias,
      );

      return {
        fee: selectedTvPackage.fee ? Number(selectedTvPackage.fee.value) : 0,
        alias: selectedTvPackage.alias,
        name: selectedTvPackage.name,
      };
    });

    return {
      ...state,

      fttbTvChannelsData: {
        ...state.fttbTvChannelsData,

        selectedTvPackages: tvPackages,
      },
    };
  },
  [REMOVE_SELECTED_FTTB_TV_CHANNELS_PACKAGE]: (state, { alias }) => {
    const filtredTvPackages = state.fttbTvChannelsData.selectedTvPackages.filter(
      (tvPackage) => tvPackage.alias !== alias,
    );

    return {
      ...state,
      fttbTvChannelsData: {
        ...state.fttbTvChannelsData,

        selectedTvPackages: filtredTvPackages,
      },
    };
  },
};

export const fttbTvChannelsData = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
