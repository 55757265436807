import { AUTH_STEPS, ID_CONFIG } from '../Authorization2021/actions/constants';

export const INDEX_URL = '/customers/products/';
export const LOGIN_PAGE_URL = '/login/';
export const CSS_HEIGHT_VAR = '--available-height';

export const getButtonText = (step: string, text: any, stepSmsPassword: string) => {
  if (step === AUTH_STEPS.mobileIdInitial) {
    return text.initialForm.mobileIdInitial.button;
  }
  if (step === AUTH_STEPS.mobileIdCaptcha) {
    return text.smsCaptcha.button;
  }
  if (step === AUTH_STEPS.smsCaptcha) {
    return text.smsCaptcha.button;
  }
  if (step === AUTH_STEPS.password) {
    return text.initialForm.password.button;
  }
  if (step === AUTH_STEPS.smsInitial) {
    return text.initialForm.sms.initial.button;
  }
  if (step === AUTH_STEPS.smsCode && stepSmsPassword === 'loading') {
    return 'Отправить SMS';
  }
  if (step === AUTH_STEPS.smsCode && stepSmsPassword === 'success') {
    return text.initialForm.sms.code.button;
  }

  return '';
};

export const getFormId = (step: string, smsPasswordStep: string) => {
  if (step === AUTH_STEPS.mobileIdInitial) {
    return ID_CONFIG.mobileIdInitial;
  }
  if (step === AUTH_STEPS.mobileIdCaptcha) {
    return ID_CONFIG.mobileIdCaptcha;
  }
  if (step === AUTH_STEPS.smsCaptcha) {
    return ID_CONFIG.smsCaptcha;
  }
  if (step === AUTH_STEPS.password) {
    return ID_CONFIG.password;
  }
  if (step === AUTH_STEPS.smsInitial) {
    return ID_CONFIG.smsInitial;
  }
  if (step === AUTH_STEPS.smsCode && smsPasswordStep === 'success') {
    return ID_CONFIG.smsCode;
  }
  return '';
};

export const LOGIN_TEXTS = {
  FORM_HEADING: 'Вход в личный кабинет',
  BACK_LINK_HEADING: 'На сайт билайна',
  ERROR_HEADING: 'Что-то пошло не по плану',
  ERROR_DESC: 'Иногда такое случается — попробуйте авторизоваться ещё раз',
  ERROR_BUTTON: 'К авторизации',
  ERROR_IMG: 'https://static.beeline.ru/upload/images/login-fail.svg',
};
