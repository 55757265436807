import { ymPushEvent, ymPushParams } from 'utils/ym';
import { getCookie, setCookie } from 'utils/cookie';
import { LOGIN_TEXTS } from 'pages/Login/utils';

import { AUTH_STEPS } from '../actions/constants';

export const CAPTCHA_LENGTH = 6;

const LAST_STEP = {
  MOBILE_ID: 'mob_id',
  MOBILE_ID_INITIAL: 'mob_id',
  MOBILE_ID_CAPTCHA: 'mob_id',
  SMS_CODE: 'sms',
  PASSWORD: 'password',
};

const AUTH_TYPE_VISIBLE = {
  MOBILE_ID_INITIAL: 'mob_id',
  SMS_INITIAL: 'sms',
  PASSWORD: 'password',
};

const AUTH_TYPE = {
  SMS_CAPTCHA: 'sms',
  MOBILE_ID_CAPTCHA: 'mob_id',
  MOBILE_ID: 'mob_id',
};

const ACTION_TYPES = {
  MOBILE_ID_CAPTCHA: 'step_capcha',
  SMS_CAPTCHA: 'step_capcha',
  MOBILE_ID: 'step_push_to_mobile',
};

const AUTH_TYPE_BY_STEP = {
  SMS_CODE: 'sms',
  SMS_INITIAL: 'sms',
  SMS_CAPTCHA: 'sms',
  MOBILE_ID: 'mob_id',
  MOBILE_ID_CAPTCHA: 'mob_id',
  MOBILE_ID_INITIAL: 'mob_id',
  PASSWORD: 'password',
};

const mobileIdPush = (eventName) => ymPushParams({ auth_type_ev_: { mob_id: eventName } });
const smsPush = (eventName) => ymPushParams({ auth_type_ev_: { sms: eventName } });
const passwordPush = (eventName) => ymPushParams({ auth_type_ev_: { password: eventName } });
const passwordServiceConnectPush = (eventName) =>
  ymPushParams({ auth_type_ev_: { password_service_connect: eventName } });
const authSuccessPush = (type) =>
  ymPushEvent('Auth_Success', { auth_status_dm_: true, auth_type_ev_: { [type]: 'auth_success' } });

export const YMAuthEvent = {
  // common
  loginButtonClick: () => ymPushParams({ auth_type_ev_: 'enter' }),
  visible: (type) => ymPushParams({ auth_type_ev_: { [type]: 'step1_start' } }),
  clickStep: (type, action) => ymPushParams({ auth_type_ev_: { [type]: action } }),
  clickTypePassword: () => ymPushParams({ auth_type_ev_: { mob_id: 'change_auth_type_password' } }),
  authTypeSMS: () => ymPushParams({ auth_type_ev_: { mob_id: 'change_auth_type_sms' } }),
  error: (type, error) => ymPushParams({ auth_type_ev_: { [type]: { error } } }),
  backLinkClick: (type) =>
    ymPushParams({
      auth_type_ev_: {
        back_to_site: { [AUTH_TYPE_BY_STEP[type]]: LOGIN_TEXTS.FORM_HEADING },
      },
    }),
  errorShow: (type) =>
    ymPushParams({
      auth_type_ev_: {
        [AUTH_TYPE_BY_STEP[type]]: { error: LOGIN_TEXTS.ERROR_HEADING },
      },
    }),
  errorButtonClick: (type) =>
    ymPushParams({
      auth_type_ev_: {
        [AUTH_TYPE_BY_STEP[type]]: { error_click: LOGIN_TEXTS.ERROR_BUTTON },
      },
    }),
  // mobileid
  mobileIdInitialInputClick: () => mobileIdPush('input_phone_click'),
  mobileIdInitialCtnFill: () => mobileIdPush('input_phone_fill_in'),
  mobileIdNotBeeline: () => mobileIdPush('input_phone_not_beeline'),
  mobileIdConditionsClick: () => mobileIdPush('step1_conditions'),
  mobileIdAuthRequest: () => mobileIdPush('step1_next'),
  mobileIdCaptchaShow: () => mobileIdPush('step_capcha'),
  mobileIdCaptchaInputClick: () => mobileIdPush('input_capcha_click'),
  mobileIdCaptchaCancel: () => mobileIdPush('capcha_cancel'),
  mobileIdCaptchaReload: () => mobileIdPush('capcha_reload'),
  mobileIdCaptchaNext: () => mobileIdPush('capcha_next'),
  mobileIdCaptchaError: () => mobileIdPush('capcha_error'),
  mobileIdSendPush: () => mobileIdPush('step_push_to_mobile'),
  mobileIdPushTimeout: () => mobileIdPush('push_time_over'),
  mobileIdPushRepeat: () => mobileIdPush('push_repeat'),
  mobileIdChangeNumber: () => mobileIdPush('change_number'),
  mobileIdChangeAuthTypeClick: () => mobileIdPush('change_auth_type'),
  mobileIdPasswordAuthClick: () => mobileIdPush('change_auth_type_password'),
  mobileIdSmsAuthClick: () => mobileIdPush('change_auth_type_sms'),
  mobileIdFillCaptchaInput: () => mobileIdPush('input_capcha_fill_in'),
  mobileIdPushError: (message) => ymPushParams({ auth_type_ev_: { mob_id: { error: message } } }),
  mobileIdSuccess: () => authSuccessPush('mob_id'),
  // sms
  smsInitialInputClick: () => smsPush('input_phone_click'),
  smsInitialCtnFill: () => smsPush('input_phone_fill_in'),
  smsNotBeeline: () => smsPush('input_phone_not_beeline'),
  smsConditionsClick: () => smsPush('step1_conditions'),
  smsInitialCtnSubmit: () => smsPush('step2_input_phone'),
  smsCaptchaShow: () => smsPush('step_capcha'),
  smsCaptchaInputClick: () => smsPush('input_capcha_click'),
  smsCaptchaCancel: () => smsPush('capcha_cancel'),
  smsCaptchaReload: () => smsPush('capcha_reload'),
  smsCaptchaNext: () => smsPush('capcha_next'),
  smsCaptchaError: () => smsPush('capcha_error'),
  smsFillCaptchaInput: () => smsPush('input_capcha_fill_in'),
  smsSend: () => smsPush('send_sms'),
  smsCodeShow: () => smsPush('step_input_code'),
  smsRepeat: () => smsPush('repeat_sms'),
  smsChangeNumber: () => smsPush('change_number'),
  smsPasswordClick: () => smsPush('input_password_click'),
  smsPasswordFill: () => smsPush('input_password_fill_in'),
  smsAuthEnter: () => smsPush('auth_enter'),
  smsPasswordShow: () => smsPush('input_password_show'),
  smsError: (message) => ymPushParams({ auth_type_ev_: { sms: { error: message } } }),
  // password
  passwordLoginClick: () => passwordPush('input_login_click'),
  passwordPasswordClick: () => passwordPush('input_password_click'),
  passwordRestoreClick: () => passwordPush('restore_password'),
  passwordConditionsClick: () => passwordPush('step1_conditions'),
  passwordPasswordShow: () => passwordPush('input_password_show'),
  passwordPasswordHide: () => passwordPush('input_password_hide'),
  passwordLoginFill: () => passwordPush('input_login_fill_in'),
  passwordPasswordFill: () => passwordPush('input_password_fill_in'),
  passwordAuthEnter: () => passwordPush('auth_enter'),
  // password service connect
  passwordServiceConnectClose: () => passwordServiceConnectPush('step1_close'),
};

export const sentErrorYMAuth = (error) => ymPushParams({ auth_type_ev_: { mob_id: { error } } });

export const sentYMUserAuth = (user) => {
  const authStep = getCookie('auth_step');
  if (authStep) {
    ymPushEvent('userParams', {
      CTN: user.hashCtn,
      region_id: user.regionId,
      region_name: user.regionName,
      tariff_name: user.tariffName,
      clientType: user.clientType,
    });
  }
};

export const pushYMEvent = (step) => {
  if (AUTH_TYPE_VISIBLE[step]) {
    YMAuthEvent.visible(AUTH_TYPE_VISIBLE[step]);
  }
  if (AUTH_TYPE[step]) {
    YMAuthEvent.clickStep(AUTH_TYPE[step], ACTION_TYPES[step]);
  }
};

export const saveLastStep = (step) => {
  if (Object.keys(LAST_STEP).includes(step)) {
    setCookie({ name: 'auth_step', path: true, value: LAST_STEP[step] });
  }
};

export const sendSubmitAnalytics = (step) => {
  if (step === AUTH_STEPS.mobileIdInitial) {
    YMAuthEvent.mobileIdAuthRequest();
  }
  if (step === AUTH_STEPS.mobileIdCaptcha) {
    YMAuthEvent.mobileIdCaptchaNext();
  }
  if (step === AUTH_STEPS.smsInitial) {
    YMAuthEvent.smsInitialCtnSubmit();
  }
  if (step === AUTH_STEPS.smsCode) {
    YMAuthEvent.smsAuthEnter();
  }
  if (step === AUTH_STEPS.password) {
    YMAuthEvent.passwordAuthEnter();
  }
};
