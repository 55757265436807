export const SET_AUTH_STEP = 'set auth step';
export const SET_AUTH_CTN = 'set auth ctn';
export const SET_AUTH_LOGIN = 'set auth login';
export const SET_AUTH_PASSWORD = 'set auth password';
export const SET_CTN_TYPE = 'set ctn type';
export const SET_AUTH_STATE = 'set auth state';
export const SET_CTN_READ_FROM_URL = 'set ctn read from url';

export const SET_MOBILE_ID_CAPTCHA = 'set mobile id captcha';
export const TOGGLE_PENDING_CAPTCHA = 'toggle pending captcha';
