import {
  FETCH_FTTB_TV_CHANNELS_ERROR,
  FETCH_FTTB_TV_CHANNELS_START,
  FETCH_FTTB_TV_CHANNELS_SUCCESS,
  REMOVE_SELECTED_FTTB_TV_CHANNELS_PACKAGE,
  SET_SELECTED_FTTB_TV_CHANNELS_PACKAGE,
} from './actionTypes';
import getTvChannels from '../services/getTvChannels';

export const fetchTvChannelsData =
  (fetchTvChannelsUrl, tariffAlias) => async (dispatch, getState) => {
    const { tariffAlias: fetchedDataTariffAlias } = getState().external.fttbTvChannelsData || {};

    // Если данные для этого тарифа уже были загруженны, то не выполняем повторный запрос
    if (tariffAlias === fetchedDataTariffAlias) return;
    dispatch({ type: FETCH_FTTB_TV_CHANNELS_START });

    try {
      const data = await getTvChannels(fetchTvChannelsUrl);
      dispatch({
        type: FETCH_FTTB_TV_CHANNELS_SUCCESS,
        payload: { tvChannelsData: data, tariffAlias },
      });
    } catch (error) {
      dispatch({ type: FETCH_FTTB_TV_CHANNELS_ERROR });
    }
  };

export const setSelectedTvPackages = (selectedTvPackagesAliases) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FTTB_TV_CHANNELS_PACKAGE,
    payload: { tvPackagesAliases: selectedTvPackagesAliases },
  });
};

export const removeSelectedTvPackage = (alias) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_FTTB_TV_CHANNELS_PACKAGE,
    payload: { alias },
  });
};
