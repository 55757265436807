/* eslint-disable max-len */

export default {
  tiles: {
    actionsIcon: 'present',
    constructorIcon: '//static.beeline.ru/upload/images/emoji/constructor-icon.svg',
  },
  loginForm: {
    title: 'Личный кабинет «билайн»',
    description:
      'Проверить баланс, остаток минут и интернета, посмотреть детализацию, подключить или отключить услуги – быстро и самостоятельно, без звонков и ожиданий.',
    titleLink: '/customers/products/mobile/profile/',
    buttonText: 'Далее',
    infoLinkText: 'Как получить логин/пароль',
  },
  maps: {
    checkboxDisabledText: 'Для маломобильных людей',
    checkboxAlfaBankCardText: 'Выдача кредитной карты "100 дней"',
    checkboxAlfaBankCard1525Text: 'Выдача дебетовой карты "1-5-25"',
    checkboxOfficesText: 'Для корпоративных клиентов',
    checkboxOwnerOfficesText: '*Офисы обслуживания билайна',
    postfix: 'на карте',
    showMore: 'Показать ещё',
    movingMapText: 'В данной области офисов билайн нет. Попробуйте передвинуть карту',
    zoomingInMapText: 'Для отображение остальных точек попробуйте приблизить карту',
  },
  staticEmojiUrl: '//static.beeline.ru/upload/images/emoji/',
  tariff: {
    connectTariffPreloaderText: 'Тариф подключается...',
    disconnectTariffPreloaderText: 'Тариф отключается...',
    descriptionsAnchor: 'Дополнительная информация о тарифе',
    configureText: 'Настроить тариф',
    divorcingModal: {
      buttons: {
        withInternet: 'С домашним интернетом',
        withoutInternet: 'Без домашнего интернета',
        moreInfo: 'Подробней об интернете',
      },
    },
    buttonText: {
      proceed: 'Продолжить',
      connect: 'Подключить тариф',
      mnp: 'Перенести номер в билайн',
      simOrder: 'Купить SIM-карту',
      simOrderMore: 'Купить еще одну SIM',
      simOrderDiscount: 'Заказать SIM-карту',
      connectInlineButton: 'Перейти на этот тариф',
      smsLiginForm: 'Проверить тариф',
      connectedTariffCard: 'Мой тариф',
      learnMoreTariffCard: 'Узнать больше',
    },
    statusText: {
      available: 'Тариф доступен для подключения в офисе',
      currentTariff: 'Мой тариф',
      myTariff: 'Моя связь',
      archived: 'Тариф в архиве',
    },
    connectBenefit: {
      benefit: 'delivery',
      text: 'Бесплатно доставим домой, на&nbsp;работу или&nbsp;в&nbsp;офис билайн',
    },
    subTitle: {
      discount: 'Тариф со скидкой',
      regular: 'Тариф',
    },
    title: {
      tariffConnect: 'Подключение тарифа <br />«{{PAGE_TITLE}}»',
      discountedSim: 'Закажите SIM со скидкой',
      mnp: 'Быстрый заказ тарифа «{{PAGE_TITLE}}» с&nbsp;сохранением вашего номера',
      simOrder: 'Быстрый заказ тарифа «{{PAGE_TITLE}}»',
      simOrderDiscount:
        'Заказать SIM со&nbsp;скидкой <img src="//static.beeline.ru/upload/images/emoji/50-off.svg" />',
    },
    configureBtnText: 'Настроить тариф',
    smsLoginFormText:
      'Введите номер, мы проверим возможность подключения и отправим вам SMS с кодом',
  },
  simOrderBenefits: {
    help: 'Позвоним для&nbsp;оформления <br />заказа и&nbsp;ответим на&nbsp;вопросы',
    mnp: 'Переведем в «билайн» <br />и&nbsp;сохраним ваш&nbsp;номер',
    delivery: 'Бесплатная доставка или самовывоз из офиса',
    deliveryWhite: 'Бесплатно доставим домой,<br /> на работу или в офис билайн',
  },
  accumulators: {
    kbyte: {
      icon: 'internet',
      label: 'Интернет',
    },
    seconds: {
      icon: 'phone',
      label: 'Минуты',
    },
    sms: {
      icon: 'chat',
      label: 'SMS',
    },
    unlimText: 'Безлимитно',
  },
  deviceLanding: {
    deviceButton: 'Купить',
    shopLinkText: 'Перейти в магазин',
  },
  fancyNumber: {
    pageUrl: '/customers/products/mobile/services/details/nomer-na-vybor/krasivie-nomera/',
    cardDiscountText:
      'Заказ тарифа «{{TARIFF_NAME}}» со&nbsp;скидкой <img src="//static.beeline.ru/upload/images/emoji/50-off.svg" />',
    cardRegularText: 'Быстрый заказ тарифа «{{TARIFF_NAME}}» с&nbsp;сохранением вашего номера',
    stopGapMessage: 'Красивые номера закончились',
    errorText: 'Упс, что-то пошло не так',
    emptyText: 'Таких номеров нет',
    failText:
      'При отправке запроса на сервер произошла ошибка, наши специалисты уже работают над этим!',
  },
  defaultError: {
    icon: '//static.beeline.ru/upload/images/emoji/smile-nervous.svg',
    title: 'Упс, что-то пошло не так',
    content:
      'При отправке запроса на сервер произошла ошибка, наши специалисты уже работают над этим!',
    buttonText: 'Продолжить',
  },
  paymentForm: {
    RU: {
      ctnLabelMobile: 'Номер получателя',
      ctnLabelHome: 'Номер лицевого счёта',
      amount: 'Сумма',
      paymentMethod: 'Способ оплаты',
      commission: '100–15 000 ₽ без комиссии',
      cantCheck: 'Для абонентов билайн',
      notBeeline: 'Абонент не принадлежит сети билайн.',
      buttonText: 'К пополнению',
      alterButton: 'Оплатить картой',
      success: 'Счёт пополнен!',
      error: 'Не получилось оплатить',
      offeroApple:
        'Нажимая Pay, я принимаю <a class="offer-link" href="https://moskva.beeline.ru/customers/pomosh/question/voprosy-i-dokumenty/mobile/drugie-dokumenty/pravila-ispolzovania-servisa-mobilnyy-platezh/" target="_blank" rel="noopener">условия</a> использования услуги',
      offeroGoogle:
        'Нажимая GPay, я принимаю <a class="offer-link" href="https://moskva.beeline.ru/customers/pomosh/question/voprosy-i-dokumenty/mobile/drugie-dokumenty/pravila-ispolzovania-servisa-mobilnyy-platezh/" target="_blank" rel="noopener">условия</a> использования услуги',
      offeroSfp:
        'Нажимая «Оплатить через СБП», я принимаю <a class="offer-link" href="https://moskva.beeline.ru/customers/pomosh/question/voprosy-i-dokumenty/mobile/drugie-dokumenty/pravila-ispolzovania-servisa-mobilnyy-platezh/" target="_blank" rel="noopener">условия</a> использования услуги',
    },
    EN: {
      ctnLabelMobile: 'Payment recipient',
      ctnLabelHome: 'Account number',
      amount: 'Amount',
      commission: 'From 100 to 15000 RUB',
      cantCheck: 'Unable to verify number. Try again later',
      notBeeline: 'Not Beeline number',
      buttonText: 'Submit',
      alterButton: 'Pay with card',
      success: 'Success!',
      error: 'Failed to pay',
      offeroApple:
        'Clicking Pay, I accept the service <a href="https://static.beeline.ru/upload/images/promoforge/docs/oferta_Apple_Pay_eng.pdf" target="_blank" rel="noopener">terms</a> and conditions',
      offeroGoogle:
        'Clicking GPay, I accept the service <a href="https://static.beeline.ru/upload/images/promoforge/docs/oferta_Google_Pay_eng.pdf" target="_blank" rel="noopener">terms</a> and conditions',
    },
  },
  roaming: { simRegion: 'Город, где куплена SIM-карта' },
  tariffsCatalog: {
    heading: 'Тарифы',
    headingMore: 'Ещё тарифы',
    archiveLink: 'Архивные тарифы',
    allTariffs: 'Показать все тарифы',
    digitalTariffTitle: 'Кино, музыка и связь',
    simpleTariffTitle: 'Для общения с близкими',
    startTariffTitle: 'Для старта',
    freeMonth: 'месяц бесплатно',
    pathIcon: '//static.beeline.ru/upload/images/icons/alive-ruler-icons/{{name}}.svg',
    familyWidget: {
      mobile: {
        icon: {
          desktop: 'https://static.beeline.ru/upload/images/icons/catalog-forMobile.svg',
          mobile: 'https://static.beeline.ru/upload/images/icons/catalog-forMobile.svg',
        },
        text: '<strong style="color: #282828">Безлимитный</strong> мобильный \n интернет только для тебя',
      },
      convergent: {
        icon: {
          desktop: 'https://static.beeline.ru/upload/images/icons/catalog-forConvergent-web.svg',
          mobile: 'https://static.beeline.ru/upload/images/icons/catalog-forConvergent-mobile.svg',
        },
        text: '<strong style="color: #282828">Сэкономь в 2 раза</strong> &#8210; подключи всю семью и&nbsp;пользуйтесь связью, домашним интернетом и&nbsp;ТВ все вместе',
      },
    },
  },
  service: {
    defaultSubTitle: 'Услуга',
    serviceNameConst: 'sharedEverythingBundle',
    connectTariffPreloaderText: 'Услуга подключается...',
    disconnectTariffPreloaderText: 'Услуга отключается...',
    secretaryService: {
      priceHeading: 'Стоимость',
      compositionHeading: 'Состав услуги',
    },
  },
  socialPackage: {
    legal:
      'Для тех, кому нужна социальная поддержка. Подключим в офисе при предъявлении документа, подтверждающего право на льготу',
    status: 'Для подключения необходим документ, подтверждающий право на льготу',
    tariff: 'Тариф ',
    fancyNumber: 'Красивый номер',
  },
  upsalePopup: {
    buttonText: 'Узнать больше',
    resetButtonText: 'Неинтересно',
  },
  allInOne: {
    requestForm: {
      buttonText: 'Отправить заявку',
      btnWithoutHome: 'Подключить',
      connectHouseLink: 'Сообщить когда подключат мой дом',
      tariffDiscountLabel: 'Тариф со скидкой',
      tariffUnit: '₽/мес',
      regionLabel: 'Город',
      streetLabel: 'Улица',
      homeLabel: 'Дом',
      flatLabel: 'Квартира',
      phoneLabel: 'Номер телефона',
      nameLabel: 'Как к вам обращаться',
      regionIsNotFound: 'К сожалению введенный Вами регион не найден',
      streetIsNotFound: 'К сожалению, введенная Вами улица не найдена',
      houseIsNotFound: 'К сожалению, введенный Вами дом не найден',
      notAvailableHousePrefix: 'По адресу ',
      notAvailableHousePostfix:
        ' доступно подключение мобильной связи + билайн ТВ без домашнего интернета',
      userAgreementText:
        'Нажимая на кнопку «Отправить заявку» или «Подключить», вы предоставляете ПАО «ВымпелКом» согласие на обработку персональных данных',
      termsOfUse:
        'ПАО «ВымпелКом» (127083, г. Москва, ул. Восьмого марта, дом 10, строение 14) обрабатывает указанные в форме персональные данные в течение 1 месяца с целью получения консультации путем сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передачи (предоставления, доступа), обезличивания, блокирования, удаления, уничтожения, как с использованием, так и без использования средств автоматизации в соответствии с Политикой «Обработка персональных данных». Отзыв согласия на обработку персональных данных направляется по форме, размещенной на сайте в сети интернет (www.beeline.ru).',
    },
  },
  deliveryForm: {
    addressLabel: 'Адрес доставки в городе',
    dateLabel: 'Дата доставки',
    intervalLabel: 'Интервал доставки',
    deliveryDiscountLabel: 'на абон. плату при заказе доставки',
  },
  downloadBySms: 'Скачать по SMS',
  tariffCard: {
    upperBar: {
      eSim: {
        type: 'eSim',
        text: 'Поддерживает eSIM',
        iconUrl: '//static.beeline.ru/upload/images/icons/eSimIcon.svg',
      },
      connected: {
        type: 'connected',
        text: 'Мой тариф',
      },
      connectedYoung: {
        type: 'connected',
        text: 'Моя связь',
      },
      onlyInOffice: {
        type: 'notAvailable',
        text: 'Для подключения в офисе',
      },
    },
  },
  catalogSwitchNames: { fttb: 'Тарифы с домашним ТВ' },
};
