import setConnectionStateService from 'pages/FTTB/services/setConnectionState';
import getConnectionStateService from 'pages/FTTB/services/getConnectionState';
import {
  isConnectionDataLoaded,
  isConnectionDataLoading,
} from 'pages/FTTB/selectors/connectionState';

import {
  GET_FTTB_CONNECTION_STATE_END,
  GET_FTTB_CONNECTION_STATE_START,
  SET_FTTB_CONNECTION_DATA,
  SET_FTTB_CONNECTION_DATA_ERROR,
  SET_FTTB_CONNECTION_DATA_START,
  SET_FTTB_CONNECTION_DATA_SUCCESS,
} from './actionTypes';

/**
 * Получает данные о адресе(если он был ранее введён) из сессии пользователя,
 * если запрос уже был выполнен или в процессе выполнения, то ничего не делает
 * @param {func} dispatch
 * @param {func} getState
 */
export const getConnectionStateInitial = async (dispatch, getState) => {
  const state = getState();

  if (isConnectionDataLoading(state) || isConnectionDataLoaded(state)) return;

  dispatch({ type: GET_FTTB_CONNECTION_STATE_START });
  try {
    const connectionData = await getConnectionStateService();
    dispatch({ type: GET_FTTB_CONNECTION_STATE_END, payload: { connectionData } });
  } catch (error) {
    dispatch({ type: GET_FTTB_CONNECTION_STATE_END, payload: {} });
  }
};

/**
 * Принимает объект с адресом записывает его в `store` и сохраняет в сессию
 * @param {object} - connectionData
 */
export const setAndSaveConnectionState = (connectionData) => async (dispatch) => {
  dispatch({ type: SET_FTTB_CONNECTION_DATA, payload: { connectionData } });

  dispatch({ type: SET_FTTB_CONNECTION_DATA_START });
  try {
    await setConnectionStateService(connectionData);
    dispatch({ type: SET_FTTB_CONNECTION_DATA_SUCCESS });
  } catch (error) {
    dispatch({ type: SET_FTTB_CONNECTION_DATA_ERROR });
  }
};
