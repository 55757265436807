import React from 'react';
import Chat from '@beef/widget-chat';

import { ChatWidgetOld } from 'widgets/Chat/ChatWidgetOld';

import type { IChatWidgetProps } from './types';
import { useUserData } from './hooks';

const defaultProps: IChatWidgetProps['config'] = {
  url: 'ocp2.beeline.ru',
  messengers: ['IS_CHAT_CHAT_ENABLED'],
  welcomeMessage:
    'Здравствуйте. Я — ваш электронный друг и помощник в билайне. Помогу, чем смогу — а где не смогу, там позову человека',
  isWelcomeMessageOnStartEnabled: false,
  isOldDesignEnabled: false,
};

export const ChatWidget: React.FC<IChatWidgetProps> = ({
  config,
  domNodeRef,
  className,
  onChatWidgetClick,
}) => {
  const {
    isOldDesignEnabled,
    url,
    apiVersion,
    messengers,
    welcomeMessage,
    isWelcomeMessageOnStartEnabled,
  } = {
    ...defaultProps,
    ...config,
  };

  const { isUserAuthenticated, userData, authType } = useUserData();

  const Component = isOldDesignEnabled ? ChatWidgetOld : Chat;

  const authZoneConfig = React.useMemo(
    () => ({
      zoneType: isUserAuthenticated ? 'authorized' : 'unauthorized',
      startMessage: welcomeMessage,
      showMessageBeforeInput: isWelcomeMessageOnStartEnabled,
    }),
    [isUserAuthenticated, welcomeMessage, isWelcomeMessageOnStartEnabled],
  );

  if (typeof isUserAuthenticated === 'undefined') {
    return null;
  }

  return (
    <Component
      apiVersion={apiVersion}
      authType={authType}
      authZoneConfig={authZoneConfig}
      baseUrl={url}
      className={className}
      domNodeRef={domNodeRef}
      isOldDesignEnabled={isOldDesignEnabled}
      messengers={messengers}
      onChatWidgetClick={onChatWidgetClick}
      userData={userData?.View}
    />
  );
};
