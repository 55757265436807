import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Emoji, Link, Tooltip } from '@beef/legacy-ui-kit';
import QRCode from 'qrcode';
import classNames from 'classnames/bind';

import Actions from 'pages-components/Tiles/Actions';
import Picture from 'pages-components/Picture';

// Utils / Hooks
import { useIsMobile } from 'utils/hooks/useIsMobile';

// Styles

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * @param image
 * @param emoji
 * @param heading
 * @param description
 * @param descriptionList
 * @param buttonText
 * @param onButtonClick
 * @param buttonUrl
 * @param qrCodeUrl - Ссылка для QR Code
 * @param isShowQrCode - Поле необходимо для того, чтобы скрывать qrCode.
 * Просто добавив null в qp не получится, так как ссылку подмешивает бэк.
 * @param subButtonText
 * @param onSubButtonClick
 * @param subButtonUrl
 * @param children
 * @param className
 * @param tooltipText
 * @param descriptionIcon
 */
const PlainContainer = ({
  image,
  emoji,
  heading,
  description,
  descriptionList,
  noteText,
  buttonText,
  onButtonClick,
  buttonUrl,
  qrCodeUrl,
  isShowQrCode,
  subButtonText,
  onSubButtonClick,
  subButtonUrl,
  children,
  className,
  tooltipText,
  descriptionIcon,
  present,
}) => {
  const isMobile = useIsMobile();
  const qrImageEl = useRef(null);

  useEffect(() => {
    if (!isMobile && qrCodeUrl) {
      QRCode.toDataURL(qrCodeUrl).then((url) => {
        qrImageEl.current.src = url;
      });
    }
  });

  return (
    <div className={cx('component', className)}>
      {image && <Picture className={cx('image')} src={image} />}
      {emoji && <Emoji className={cx('emoji')} name={emoji} />}
      {heading && <div className={cx('heading')} dangerouslySetInnerHTML={{ __html: heading }} />}
      {noteText && (
        <div className={cx('noteText')} dangerouslySetInnerHTML={{ __html: noteText }} />
      )}

      {/* Description в моделе лежит в двух видах: строка и массив объектов, в данном случае выводим массив */}
      {descriptionList && (
        <div className={cx('descriptionWrap', { descriptionIcon })}>
          {descriptionList.map((item, index) => (
            <p className={cx('paragraph')} key={index}>
              <span dangerouslySetInnerHTML={{ __html: item.text }} />
              {item.tooltip && (
                <Tooltip className={cx('tooltip')} contentPosition="top">
                  <div dangerouslySetInnerHTML={{ __html: item.tooltip }} />
                </Tooltip>
              )}
            </p>
          ))}
        </div>
      )}

      {/* Description в моделе лежит в двух видах: строка и массив объектов, в данном случае выводим строку */}
      {description && (
        <div className={cx('descriptionWrap', { descriptionIcon })}>
          {descriptionIcon && <Picture src={descriptionIcon} />}
          <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />

          {tooltipText && (
            <Tooltip>
              <div dangerouslySetInnerHTML={{ __html: tooltipText }} />
            </Tooltip>
          )}
        </div>
      )}

      {present?.text && (
        <div className={cx('gift')}>
          <Actions icon={present.icon} text={present.text} />
        </div>
      )}

      {isShowQrCode && qrCodeUrl && !isMobile && (
        <div className={cx('qrCode')}>
          <img alt="" ref={qrImageEl} src="" />
        </div>
      )}

      {children}
      {buttonText && (onButtonClick || buttonUrl) && (
        <Button className="big" href={buttonUrl} onClick={onButtonClick} transformer>
          {buttonText}
        </Button>
      )}
      {subButtonText && (onSubButtonClick || subButtonUrl) && (
        <Link className={cx('subButton')} href={subButtonUrl} onClick={onSubButtonClick}>
          {subButtonText}
        </Link>
      )}
    </div>
  );
};

PlainContainer.propTypes = {
  image: PropTypes.string,
  emoji: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  noteText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  subButtonText: PropTypes.string,
  subButtonUrl: PropTypes.string,
  tooltipText: PropTypes.string,
  qrCodeUrl: PropTypes.string,
  descriptionIcon: PropTypes.string,

  isShowQrCode: PropTypes.bool,

  descriptionList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),

  present: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
  }),

  onButtonClick: PropTypes.func,
  onSubButtonClick: PropTypes.func,
};

export default PlainContainer;
