import { pathOr } from 'ramda';

import {
  CLEAR_USED_FANCY_NUMBER,
  SET_CHOSEN_NUMBER,
  SET_DEFAULT_STATE_OF_POPUP,
  SET_FANCY_NUMBERS,
  SET_FANCY_NUMBERS_LOADING,
  SET_FANCY_NUMBERS_LOADING_ERROR,
  SET_NEW_PHONE_INDEX,
  SET_TARIFFS_INFO,
  TOGGLE_FANCY_NUMBER_ORDER_POPUP,
} from '../actions/funcyNumberOrder';

const getNewState = (oldState, newData) => ({
  ...oldState,
  ...{
    funcyNumberOrder: {
      ...oldState.funcyNumberOrder,
      ...newData,
    },
  },
});

export function funcyNumberOrderReducer(state = {}, action) {
  switch (action.type) {
    case TOGGLE_FANCY_NUMBER_ORDER_POPUP: {
      return getNewState(state, { popupIsActive: action.payload.popupIsActive });
    }

    case SET_FANCY_NUMBERS: {
      return getNewState(state, {
        currentIndex: 0,
        numbers: action.numbers,
      });
    }

    case SET_NEW_PHONE_INDEX: {
      const index = pathOr(0, ['funcyNumberOrder', 'currentIndex'], state);
      const category = pathOr(null, ['funcyNumberOrder', 'currentCategory'], state);
      const numbers = pathOr([], ['funcyNumberOrder', 'numbers'], state);
      const { categoryType, diffIndex } = action.payload;
      let currentIndex = 0;

      if (!categoryType) {
        numbers.forEach((item) => {
          if (item.alias === category) {
            currentIndex = item.numbers[index + diffIndex] ? index + diffIndex : 0;
          }
        });
      }

      return getNewState(state, {
        currentIndex,
        currentCategory: categoryType || category,
      });
    }

    case SET_TARIFFS_INFO:
      const { tariffs, defaultTariffId } = action.payload;
      return getNewState(state, {
        tariffsInfo: {
          tariffs: tariffs && tariffs.length > 0 ? tariffs : null,
          deliveryTariffId: pathOr(defaultTariffId, [0, 'id'], tariffs),
        },
      });

    case SET_CHOSEN_NUMBER:
      return {
        ...state,
        ...{
          funcyNumberOrder: {
            ...state.funcyNumberOrder,
            newChosenNumber: action.payload,
          },
        },
      };

    case SET_DEFAULT_STATE_OF_POPUP:
      return getNewState(state, {
        showOrderForm: false,
        showError: false,
        tariffsInfo: null,
      });

    case CLEAR_USED_FANCY_NUMBER: {
      const fancyNumberOrder = state.funcyNumberOrder;
      const category = fancyNumberOrder.currentCategory;
      const oldNumbers = fancyNumberOrder.numbers;

      const numbers = oldNumbers.map((item) => {
        let obj = item;

        if (item.alias === category) {
          const newNumbersVal = item.numbers.filter(
            (num) => num.value !== item.numbers[fancyNumberOrder.currentIndex].value,
          );
          obj = {
            ...item,
            numbers: newNumbersVal,
          };
        }

        return obj;
      });

      try {
        localStorage.setItem('fancyNumbersData', JSON.stringify({ date: +new Date(), numbers }));
      } catch (ex) {} // eslint-disable-line

      return getNewState(state, { numbers });
    }

    case SET_FANCY_NUMBERS_LOADING: {
      return getNewState(state, { isLoading: action.payload });
    }

    case SET_FANCY_NUMBERS_LOADING_ERROR: {
      return getNewState(state, { isError: action.payload });
    }
  }

  return state;
}
