export const SENTRY_DSN = 'https://97facc1b871146e9ba88967b2c006545@sentry-relay.beeline.ru/10';

export const ERRORS_REGEXP = [
  /QaCommon/gi,
  /EXCLUDED_TITLES/gi,
  /jQuery/gi,
  /flocktory/gi,
  /XPath expression/gi,
  /\$ is not defined/gi,
  /Can't find variable: \$/gi,
];

export const FTTB_PAGES = [
  'products/home/commercial',
  'products/home/order',
  'products/home/request',
  'products/home/home-tariffs/tariffs',
  'products/home/presets',
  'products/home/home-tariffs',
  'products/home/kit',
  'products/home/internet',
  'products/mobile/convergent-archive-page',
  'products/beeline-consenta',
  'domashnij-internet-i-tv-besplatno/promo',
  'domashnij-internet-tv-mobilnaya-svyaz',
];

export const PROFILE_PAGES = ['customers/products/elk', 'customers/products/mobile/profile'];

export const OTHER_B2C_DOMAINS = ['vimpelcom.ru', 'widgetpay1.beeline.ru'];

export const CROSS_DOMAIN_RESOURCES = [
  'flocktory.com',
  'mc.yandex.ru',
  'diginetica.net',
  'bee-cake.ru',
  'gdeslon.ru',
  'vk.com',
  'chrome://',
  'mail.ru',
];
