import React from 'react';
import { Personal2021 } from '@beef/layout-kit';

import { YMAuthEvent } from 'pages/Login/Authorization2021/utils/ym';

export const ExtendedPersonalComponent = (props) => (
  <Personal2021
    {...props}
    onLoginClick={(e) => {
      e.preventDefault();
      YMAuthEvent.loginButtonClick();
      document.location.href = e.target.href || '/login/';
    }}
  />
);
