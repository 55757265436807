import React, { useCallback, useState } from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { withDataConverter } from '@beef/react';
import { HeaderModule, HeaderRC, headerDataConverter } from '@carnica/smart-kit';
import type { IHeaderCommon } from '@carnica/smart-kit';
// @ts-expect-error FIXME need @beef/elk-shared .d.ts
import { SideMenu } from '@beef/elk-shared';

import { headerStore2024 } from 'store';
import { ChatWidget } from 'widgets/Chat';

import { useBasket } from './hooks/useBasket';
import { useSentry } from './hooks/useSentry';
import { useLegacyHeaderActions } from './hooks/useLegacyHeaderActions';

const selectHeaderData = (state: any) => state.header;

const HeaderV2 = ({ data }: { data: IHeaderCommon }) => {
  useLegacyHeaderActions(data);
  useSentry(data.env);
  useBasket(data.currentRegion);

  const [isOpenSideMenu, setIsOpenSideMenu] = useState(false);

  const handleLoginClick = useCallback(
    () => setIsOpenSideMenu((prev: boolean) => !prev),
    [setIsOpenSideMenu],
  );

  return (
    <>
      <HeaderModule onLoginClick={handleLoginClick} selectData={selectHeaderData}>
        <HeaderRC />
        <SideMenu isOpen={isOpenSideMenu} onClose={handleLoginClick} user={data?.auth?.user} />
      </HeaderModule>
      {/* @ts-expect-error FIXME Header React Model types */}
      {!!data?.chatWidget && <ChatWidget config={data.chatWidget} />}
    </>
  );
};

export default compose(
  withDataConverter(headerDataConverter),
  withReduxStore(headerStore2024, {
    passThroughProps: ({ header: { common: data } }: any) => ({ data }),
  }),
)(HeaderV2);
