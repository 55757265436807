export const deviceTypes = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

export const mobileMedia = '(max-width: 767px)';
export const tabletMedia = '(min-width: 768px) and (max-width: 1023px)';
export const desktopMedia = '(min-width: 1024px)';

export const actionTypes = { SWITCH_DEVICE_TYPE: 'switch device type' };

export function deviceTypeReducer(state, { type, deviceType }) {
  const { SWITCH_DEVICE_TYPE } = actionTypes;
  switch (type) {
    case SWITCH_DEVICE_TYPE:
      return { ...state, deviceType };
    default:
      return state;
  }
}

export function setMobileType() {
  return {
    type: actionTypes.SWITCH_DEVICE_TYPE,
    deviceType: deviceTypes.MOBILE,
  };
}

export function setTabletType() {
  return {
    type: actionTypes.SWITCH_DEVICE_TYPE,
    deviceType: deviceTypes.TABLET,
  };
}

export function setDesktopType() {
  return {
    type: actionTypes.SWITCH_DEVICE_TYPE,
    deviceType: deviceTypes.DESKTOP,
  };
}

export function addResizeEventListener(store) {
  store.addReducer(deviceTypeReducer);
  if (typeof window !== 'undefined') {
    const switcher = (mq, action) => {
      if (mq.matches) {
        store.dispatch(action());
      }
    };
    if (typeof window.matchMedia === 'function') {
      const mobileMediaQuery = window.matchMedia(mobileMedia);
      const desktopMediaQuery = window.matchMedia(desktopMedia);
      const tabletMediaQuery = window.matchMedia(tabletMedia);
      // Checking type on client.
      switcher(mobileMediaQuery, setMobileType);
      switcher(desktopMediaQuery, setDesktopType);
      switcher(tabletMediaQuery, setTabletType);
      mobileMediaQuery.addListener((mq) => switcher(mq, setMobileType));
      desktopMediaQuery.addListener((mq) => switcher(mq, setDesktopType));
      tabletMediaQuery.addListener((mq) => switcher(mq, setTabletType));
    } else {
      store.dispatch(setDesktopType());
    }
  } else {
    // Set default mobile type for server rendering.
    store.dispatch(setMobileType());
  }
}
