import {
  SET_AUTH_CTN,
  SET_AUTH_LOGIN,
  SET_AUTH_PASSWORD,
  SET_AUTH_STATE,
  SET_AUTH_STEP,
  SET_CTN_READ_FROM_URL,
  SET_CTN_TYPE,
  SET_MOBILE_ID_CAPTCHA,
  TOGGLE_PENDING_CAPTCHA,
} from '../actions/actionTypes';

const getNewState = (oldState, newData) => ({
  ...oldState,
  ...{
    authorization: {
      ...oldState.authorization,
      ...newData,
    },
  },
});

export const authorization2021 = (state, action) => {
  switch (action.type) {
    case SET_AUTH_CTN:
      return getNewState(state, { authCtn: action.payload });
    case SET_AUTH_LOGIN:
      return getNewState(state, { authLogin: action.payload });
    case SET_AUTH_PASSWORD:
      return getNewState(state, { authPassword: action.payload });
    case SET_AUTH_STEP:
      return getNewState(state, { authStep: action.payload });
    case SET_CTN_TYPE:
      return getNewState(state, { loginType: action.payload });
    case SET_AUTH_STATE:
      return getNewState(state, { ...action.payload });
    case SET_CTN_READ_FROM_URL:
      return getNewState(state, { ctnReadFromUrl: true });
    case SET_MOBILE_ID_CAPTCHA:
      return getNewState(state, { ...action.payload });
    case TOGGLE_PENDING_CAPTCHA:
      return getNewState(state, { pendingCaptcha: !state.authorization.pendingCaptcha });
    default:
      return state;
  }
};
