import { EHeaderTheme } from '@carnica/smart-kit';
import { useEffect } from 'react';

import { setHeaderTheme } from './utils';

export const useHeaderTheme = (theme: EHeaderTheme) => {
  useEffect(() => {
    setHeaderTheme(theme);
  }, [theme]);
};
