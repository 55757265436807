import axios from 'axios';

import { FANCY_NUMBER_PATH } from '../../../constants/Endpoints';

export const FETCH_NUMBERS = 'fetch numbers';
export const FETCHING_ERROR = 'fetching error';
export const INCREMENT_LIMIT = 'increment limit';
export const CHOSE_NUMBER = 'chose number';
export const SHUFFLE_NUMBERS = 'shuffle numbers';
export const UPDATE_HOT_NUMBERS = 'update hot numbers';
export const REMOVE_CHOSEN_NUMBER = 'remove chosen number';
export const SET_CATEGORY = 'set category';
export const SET_FILTER = 'set filter';
export const SET_FETCHED_TARIFFS = 'SET_FETCHED_TARIFFS';
export const TOGGLE_POPUP_STATUS = 'TOGGLE_POPUP_STATUS';

export const fetchNumbers = (pathname, query, initial) => (dispatch, getState) => {
  const params = query ? `${query}&category=` : '?category=';
  const { category } = getState().external.fancyLanding;
  dispatch({ type: FETCHING_ERROR, payload: false });
  axios(`${FANCY_NUMBER_PATH}/${pathname}/${params}${category}`, { credentials: 'include' })
    .then(({ data }) => {
      if (!data || !data.numbers) {
        throw 'error';
      }
      dispatch({ type: FETCH_NUMBERS, payload: data });
      if (initial) {
        dispatch({ type: UPDATE_HOT_NUMBERS, payload: data });
      }
    })
    .catch(() => {
      dispatch({ type: FETCHING_ERROR, payload: true });
    });
};

export const fetchTariffs = (url, popupTariffCategory) => async (dispatch) => {
  try {
    const { data } = await axios.get(url, { params: { popupTariffCategory } });

    dispatch({
      type: SET_FETCHED_TARIFFS,
      payload: data || {},
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchLuck = () => (dispatch, getState) => {
  const { luckCounter, numbers } = getState().external.fancyLanding;

  if (luckCounter > 4 || !numbers || numbers.length < 9) {
    return dispatch(fetchNumbers('all', ''));
  }
  return dispatch({ type: SHUFFLE_NUMBERS });
};
export const togglePopupStatus = (popupIsActive = false) => ({
  type: TOGGLE_POPUP_STATUS,
  payload: popupIsActive,
});

export const incrementLimit = () => ({ type: INCREMENT_LIMIT });

export const removeChosenNumber = () => ({ type: REMOVE_CHOSEN_NUMBER });

export const setCategory = (payload) => ({ type: SET_CATEGORY, payload });

export const setFilter = (payload) => ({ type: SET_FILTER, payload });
