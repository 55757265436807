import {
  FETCH_FTTB_AVAILABLE_SCHEDULE_ERROR,
  FETCH_FTTB_AVAILABLE_SCHEDULE_START,
  FETCH_FTTB_AVAILABLE_SCHEDULE_SUCCESS,
} from 'pages/FTTB/actions/actionTypes';

import { mapAvailableSchedule } from '../utils/mapAvailableSchedule';

const reducers = {
  [FETCH_FTTB_AVAILABLE_SCHEDULE_START]: (state) => ({
    ...state,

    fttbAvailableSchedule: { state: 'loading' },
  }),
  [FETCH_FTTB_AVAILABLE_SCHEDULE_SUCCESS]: (state, payload) => ({
    ...state,

    fttbAvailableSchedule: {
      ...state.fttbAvailableSchedule,

      state: 'loaded',
      availableSchedule: mapAvailableSchedule(payload.availableSchedule),
    },
  }),
  [FETCH_FTTB_AVAILABLE_SCHEDULE_ERROR]: (state) => ({
    ...state,
    fttbAvailableSchedule: { state: 'error' },
  }),
};

export const fttbAvailableSchedule = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
