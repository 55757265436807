import {
  FETCH_FTTB_AVAILABLE_SCHEDULE_ERROR,
  FETCH_FTTB_AVAILABLE_SCHEDULE_START,
  FETCH_FTTB_AVAILABLE_SCHEDULE_SUCCESS,
} from './actionTypes';
import fetchAvailableSchedule from '../services/fetchAvailableSchedule';

export const getAvailableSchedule = (houseId) => async (dispatch) => {
  dispatch({ type: FETCH_FTTB_AVAILABLE_SCHEDULE_START });

  try {
    const availableSchedule = await fetchAvailableSchedule(houseId);
    dispatch({
      type: FETCH_FTTB_AVAILABLE_SCHEDULE_SUCCESS,
      payload: { availableSchedule: availableSchedule.periods },
    });
  } catch (error) {
    dispatch({ type: FETCH_FTTB_AVAILABLE_SCHEDULE_ERROR });
  }
};
