import axios from 'axios';

const requestConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const getConnectionState = () =>
  axios.post('/hometariffsapi/getstate/', null, requestConfig).then(({ data }) => {
    if (!data.View) return null;

    const { Address, Region, Flat } = data.View;

    return {
      street: {
        id: Address.StreetId,
        label: Address.Street,
      },
      house: {
        id: Address.HouseId,
        label: Address.House,
        isConnected: Address.IsConnected,
      },
      city: {
        id: Region.Id,
        label: Region.Title,
      },
      flat: Flat,
    };
  });

export default getConnectionState;
