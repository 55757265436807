import {
  DELIVERY_ACTIVE_TAB,
  DELIVERY_INFO_LOADING,
  DELIVERY_INFO_UPDATE,
  DELIVERY_SET_BASE_DELIVERY_DAYS_DATA,
  DELIVERY_SET_INPUT_VALUE,
  DELIVERY_SET_PAYMENT_LOADING,
  DELIVERY_UPDATE_DELIVERY_DAYS,
} from '../actions/deliveryForm';

const getNewState = (oldState, newData) => ({
  ...oldState,
  ...{
    deliveryForm: {
      ...oldState.deliveryForm,
      ...newData,
    },
  },
});

export function deliveryFormReducer(state = {}, action) {
  switch (action.type) {
    case DELIVERY_INFO_LOADING: {
      return getNewState(state, {
        loading: action.payload.loading,
        currentTariffId: action.payload.currentTariffId || state.deliveryForm?.currentTariffId,
      });
    }
    case DELIVERY_SET_BASE_DELIVERY_DAYS_DATA: {
      return getNewState(state, { baseDeliveryDaysData: action.payload });
    }
    case DELIVERY_INFO_UPDATE: {
      return getNewState(state, { deliveryData: action.payload });
    }
    case DELIVERY_ACTIVE_TAB: {
      return getNewState(state, {
        deliveryData: {
          ...state.deliveryForm.deliveryData,
          activeTab: action.payload,
        },
      });
    }
    case DELIVERY_SET_INPUT_VALUE: {
      return getNewState(state, {
        inputValues: {
          ...state.deliveryForm.inputValues,
          ...action.payload,
        },
      });
    }
    case DELIVERY_UPDATE_DELIVERY_DAYS: {
      return getNewState(state, {
        deliveryData: {
          ...state.deliveryForm.deliveryData,
          ...action.payload,
        },
      });
    }
    case DELIVERY_SET_PAYMENT_LOADING: {
      return getNewState(state, {
        paymentLoading: action.payload.loading,
        paymentStatus: action.payload.status,
      });
    }
  }

  return state;
}
