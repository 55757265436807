import { combineReducers } from 'redux';
import {
  __,
  T,
  always,
  append,
  assoc,
  compose,
  concat,
  cond,
  dropRepeats,
  prop,
  propEq,
  without,
} from 'ramda';

import { simpleReducer } from 'utils/reduxHelpers';

import * as actionTypes from '../actions/blackList';

const blackListPopup = (state = { opened: false }, action) => {
  if (action.type === actionTypes.OPEN_BLACK_LIST_POPUP) {
    return { ...state, opened: true };
  }

  if (action.type === actionTypes.CLOSE_BLACK_LIST_POPUP) {
    return { ...state, opened: false };
  }

  if (action.type === actionTypes.THROW_BLACK_LIST_POPUP_ERROR) {
    return { ...state, error: action.payload };
  }

  if (action.type === actionTypes.CLEAR_BLACK_LIST_POPUP_ERROR) {
    return { ...state, error: null };
  }

  return state;
};

const recentCallsPopup = (state = { opened: false }, action) =>
  cond([
    [propEq('type', actionTypes.OPEN_RECENT_CALLS_POPUP), always({ opened: true })],
    [propEq('type', actionTypes.CLOSE_RECENT_CALLS_POPUP), always({ opened: false })],
    [T, always(state)],
  ])(action);

const blackNumbers = (state = [], action) =>
  cond([
    [
      propEq('type', actionTypes.ADD_BLACK_NUMBER),
      compose(dropRepeats, append(__, state), prop('payload')),
    ],
    [
      propEq('type', actionTypes.ADD_BLACK_NUMBERS),
      compose(dropRepeats, concat(state), prop('payload')),
    ],
    [
      propEq('type', actionTypes.REMOVE_BLACK_NUMBER),
      compose(without(__, state), append(__, []), prop('payload')),
    ],
    [propEq('type', actionTypes.CLEAR_BLACK_LIST), always([])],
    [propEq('type', actionTypes.SET_BLACK_NUMBERS), prop('payload')],
    [T, always(state)],
  ])(action);

const serviceStatus = simpleReducer(actionTypes.SET_BLACKLIST_STATUS, { touched: false });
const blackNumbersFromServer = simpleReducer(actionTypes.SET_BLACK_NUMBERS_FROM_SERVER, []);
const lastRemovedNumber = simpleReducer(actionTypes.SET_LAST_REMOVED_NUMBER, null);

const root = combineReducers({
  blackListPopup,
  blackNumbers,
  blackNumbersFromServer,
  lastRemovedNumber,
  serviceStatus,
  recentCallsPopup,
  options: simpleReducer(actionTypes.SET_BLACK_NUMBER_OPTIONS),
});

export const blackList = (state, action) =>
  assoc('blackList', root(state.blackList, action), state);
