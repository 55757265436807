import { search } from '@beef/layout-kit/store';
import { getLegacyStore } from '@beef/legacy-beeline-store';
import { composeStoreInitializer } from '@beef/redux';
import { combineReducers } from '@reduxjs/toolkit';
import { headerReducer as header } from '@carnica/smart-kit';

import { blackList } from 'pages/ProductCard/reducers/blackList';
import { redirect } from 'pages/ProductCard/reducers/redirect';
import { serviceStatus } from 'pages/ProductCard/components/ServiceStatusPopup/reducers';
import { allInOneReducer } from 'pages/AllInOneForm/reducers/AllInOneForm';
import { funcyNumberOrderReducer } from 'widgets/reducers/funcyNumberOrder';
import { deliveryFormReducer } from 'widgets/reducers/deliveryForm';
import { orderPaymentForm } from 'widgets/reducers/orderPaymentForm';
import { authorizationWidgetsReducer } from 'widgets/reducers/authorization';
import { alwaysInPositiveReducer } from 'pages/ProductCard/reducers/alwaysInPositive';
import { tariffConnectionReducer } from 'pages/ProductCard/reducers/tariffConnect';
import { serviceConnectionReducer } from 'pages/ProductCard/reducers/serviceConnect';
import { tariffConstructorReducer } from 'pages/ProductCard/reducers/tariffConstructor';
import fancyLanding from 'pages/FancyLanding/reducers';
import esimPurchase from 'pages/ESIMPurchase/reducers';
import {
  fttbAvailableSchedule,
  fttbCatalogData,
  fttbConnectionState,
  fttbSpecialTariffData,
  fttbTariffPage,
  fttbTvChannelsData,
  fttbTvConsoles,
  fttbWifiRouters,
} from 'pages/FTTB/reducers';
import { mapsReducer } from 'pages/Maps/store/reducer';
import { familyEconomyLandingReducer } from 'pages/FamilyEconomyLanding/store/reducer';
import spnReducer from 'pages/SPN/store/reducers';
import { authorization2021 } from 'pages/Login/Authorization2021/reducers/authorization';

import { addResizeEventListener } from './resizeListener';
import { setUser } from './reducers/setUser';
import { setAuthData } from './reducers/setAuthData';
import { smsService } from '../components/SmsServiceExtension/reducers';
import { loading } from '../components/Loader/reducers';
import defaultState from './defaultState';

const store = getLegacyStore();

const SET_DEFAULT_STATE = 'set default state';

store.addReducer((state, action) =>
  action.type === SET_DEFAULT_STATE ? { ...state, ...defaultState } : state,
);
store.dispatch({ type: SET_DEFAULT_STATE });

store.addReducer(authorization2021);
store.addReducer(search);
store.addReducer(blackList);
store.addReducer(serviceStatus);

// ------------------------
store.addReducer(loading);
// ------------------------

store.addReducer(redirect);
store.addReducer(smsService);
store.addReducer(allInOneReducer);
store.addReducer(funcyNumberOrderReducer);
store.addReducer(deliveryFormReducer);
store.addReducer(orderPaymentForm);
store.addReducer(authorizationWidgetsReducer);
store.addReducer(alwaysInPositiveReducer);
store.addReducer(tariffConnectionReducer);
store.addReducer(serviceConnectionReducer);
store.addReducer(tariffConstructorReducer);
store.addReducer(fancyLanding);
store.addReducer(fttbConnectionState);
store.addReducer(mapsReducer);
store.addReducer(fttbCatalogData);
store.addReducer(fttbSpecialTariffData);
store.addReducer(fttbTvChannelsData);
store.addReducer(fttbAvailableSchedule);
store.addReducer(fttbTariffPage);
store.addReducer(fttbWifiRouters);
store.addReducer(fttbTvConsoles);
store.addReducer(esimPurchase);
store.addReducer(familyEconomyLandingReducer);
store.addReducer(setUser);
store.addReducer(setAuthData);
store.addReducer(spnReducer);
addResizeEventListener(store);

export const headerStore2024 = composeStoreInitializer(
  combineReducers({
    header,
  }),
  [],
  'headerStore',
);

export default store;
