import { emptyObj } from '@beef/utils';

const getFttbSpecialTariffData = (state) => state.external?.fttbSpecialTariffData || emptyObj;
const getSpecTariffFetchingState = (state) => getFttbSpecialTariffData(state).state;
const getSpecTariffAvailability = (state) =>
  getFttbSpecialTariffData(state).availabilitySpecialTariff;
export const getSpecialTariff = (state) => getFttbSpecialTariffData(state).tariff;
export const getSpecialTariffFullModel = (state) => getFttbSpecialTariffData(state).tariffFullModel;
export const getSpecialTariffHouseId = (state) => getFttbSpecialTariffData(state).houseId;
export const getSpecialTariffFetchType = (state) => getFttbSpecialTariffData(state).fetchTariffType;

export const isSpecialTariffFetched = (state) => getSpecTariffFetchingState(state) === 'loaded';
export const isSpecialTariffAvailable = (state) => getSpecTariffAvailability(state) === 'available';
export const isShowSpecTariffIfExist = (state) =>
  getFttbSpecialTariffData(state).showSpecTariffIfExist || false;
