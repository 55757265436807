import { debounce } from './debounce';

export { debounce } from './debounce';

const FUNC_ERROR_TEXT = 'Expected a function';

export const isObject = (value: any): value is Record<string, unknown> => {
  const type = typeof value;
  return value !== null && (type === 'object' || type === 'function');
};

// FIXME: This is incorrect implementation of throttle
export function throttle<PInput extends any[], POutput>(
  func: (...args: PInput) => POutput,
  wait: number,
  options: {
    leading?: boolean;
    trailing?: boolean;
  },
) {
  let leading = true;
  let trailing = true;

  if (typeof func !== 'function') {
    throw new TypeError(FUNC_ERROR_TEXT);
  }
  if (isObject(options)) {
    leading = 'leading' in options ? !!options.leading : leading;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }
  return debounce(func, wait, {
    leading,
    maxWait: wait,
    trailing,
  });
}

export const wait = (interval = 1000, getTimeoutId?: (id: number) => void) =>
  new Promise<void>((resolve) => {
    const timeout = setTimeout(() => resolve(), interval) as unknown as number;
    getTimeoutId?.(timeout);
  });

export async function poll<POutput>(
  fn: () => Promise<POutput>,
  needContinue: (arg: POutput) => boolean,
  interval: number,
  timeout = 60000,
  /** При необходимости отменить таймер, можно использовать этот колбэк, чтобы полчуить timeoutId
   * но лучше использовать логику в needContinue
   */
  getTimeoutId?: (timeoutId: number) => void,
): Promise<POutput> {
  const result = await fn();

  const currentTimeout = timeout - interval;

  if (!needContinue(result) || currentTimeout <= 0) return result;

  await wait(interval, getTimeoutId);

  return poll(fn, needContinue, interval, currentTimeout);
}
