import { PORTATION_NUMBER_DEFAULT_VALUE } from 'pages/ESIMPurchase/constants';

const defaultState = {
  paymentInfo: {},
  portationNumber: PORTATION_NUMBER_DEFAULT_VALUE,
  isGlobalLoading: true,
  numbersLoading: false,
  tariffsLoading: false,
  isPreModalOpen: true,
  changedRegion: false,
  isModalBasketOpen: false,
  isShowError: true,
  isPreloader: false,
  firstLoadedPage: false,
  isNotOpenPreModal: false,
};

export default defaultState;
