import {
  ADD_ADDITIONAL_FTTB_TV_CONSOLE,
  ADD_FTTB_TV_CONSOLE_FOR_CHANGE,
  EXCLUDE_PRE_ENABLED_FTTB_TV_CONSOLE,
  REMOVE_ADDITIONAL_FTTB_TV_CONSOLE,
  REMOVE_ALL_FTTB_TV_CONSOLE_FOR_CHANGE,
  REMOVE_FTTB_TV_CONSOLE_FOR_CHANGE,
  RETURN_ALL_PRE_ENABLED_FTTB_TV_CONSOLE,
} from 'pages/FTTB/actions/actionTypes';

const reducers = {
  [ADD_FTTB_TV_CONSOLE_FOR_CHANGE]: (state, { tvConsole }) => {
    const tvConsolesForChange = state.fttbSelectedTvConsoles.forChange || [];

    if (tvConsolesForChange.find((tv) => tv.alias === tvConsole.alias)) return state;

    const newTvConsole = {
      name: tvConsole.name,
      alias: tvConsole.alias,
      fee: Number(tvConsole.price.value),
      unit: tvConsole.price.unit,
      isAndroidTv: tvConsole.isAndroidTv,
    };

    return {
      ...state,
      fttbSelectedTvConsoles: {
        ...state.fttbSelectedTvConsoles,
        forChange: [...tvConsolesForChange, newTvConsole],
      },
    };
  },
  [REMOVE_FTTB_TV_CONSOLE_FOR_CHANGE]: (state, { alias }) => {
    const tvConsolesForChange = state.fttbSelectedTvConsoles.forChange || [];

    const filtredTvConsolesForChange = tvConsolesForChange.filter((tv) => tv.alias !== alias);
    return {
      ...state,
      fttbSelectedTvConsoles: {
        ...state.fttbSelectedTvConsoles,
        forChange: filtredTvConsolesForChange,
      },
    };
  },
  [REMOVE_ALL_FTTB_TV_CONSOLE_FOR_CHANGE]: (state) => ({
    ...state,
    fttbSelectedTvConsoles: {
      ...state.fttbSelectedTvConsoles,
      forChange: [],
    },
  }),

  [ADD_ADDITIONAL_FTTB_TV_CONSOLE]: (state, { tvConsole }) => {
    const additionalTvConsoles = state.fttbSelectedTvConsoles.additional || [];

    if (additionalTvConsoles.find((tv) => tv.alias === tvConsole.alias)) return state;

    const newTvConsole = {
      name: tvConsole.name,
      alias: tvConsole.alias,
      fee: Number(tvConsole.price.value),
      unit: tvConsole.price.unit,
      isAndroidTv: tvConsole.isAndroidTv,
    };

    return {
      ...state,
      fttbSelectedTvConsoles: {
        ...state.fttbSelectedTvConsoles,
        additional: [...additionalTvConsoles, newTvConsole],
      },
    };
  },
  [REMOVE_ADDITIONAL_FTTB_TV_CONSOLE]: (state, { alias }) => {
    const additionalTvConsoles = state.fttbSelectedTvConsoles.additional || [];

    const filtredAdditionalTvConsoles = additionalTvConsoles.filter((tv) => tv.alias !== alias);
    return {
      ...state,
      fttbSelectedTvConsoles: {
        ...state.fttbSelectedTvConsoles,
        additional: filtredAdditionalTvConsoles,
      },
    };
  },

  [EXCLUDE_PRE_ENABLED_FTTB_TV_CONSOLE]: (state, { tvConsole }) => {
    const excluded = state.fttbSelectedTvConsoles.excluded || [];

    if (excluded.find((tv) => tv.alias === tvConsole.alias)) return state;

    const newTvConsole = {
      name: tvConsole.name,
      alias: tvConsole.alias,
      fee: Number(tvConsole.price.value),
      unit: tvConsole.price.unit,
    };

    return {
      ...state,
      fttbSelectedTvConsoles: {
        ...state.fttbSelectedTvConsoles,
        excluded: [...excluded, newTvConsole],
      },
    };
  },
  [RETURN_ALL_PRE_ENABLED_FTTB_TV_CONSOLE]: (state) => ({
    ...state,
    fttbSelectedTvConsoles: {
      ...state.fttbSelectedTvConsoles,
      excluded: [],
    },
  }),
};

export const fttbTvConsoles = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
