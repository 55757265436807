import {
  CHOOSE_POINT,
  GET_POINTS,
  GET_REQUEST_TOGGLE,
  HIDE_POINT_INFO,
  HIDE_POINT_ON_MAP,
  SEARCH_ON_MAP,
  SEARCH_ON_MAP_ERROR,
  SET_CURRENT_LOCATION,
  SET_IS_ANNIVERSARY,
  SET_UPDATE_FILTERS_FLAG,
  TOGGLE_MODE,
  TOGGLE_POINTS_LIST,
  UPDATE_FILTER,
} from './constants';

const getNewState = (oldState, payload) => ({
  ...oldState,
  ...{ maps: { ...oldState.maps, ...payload } },
});

const getNewStateForFilters = (oldState, { appliedFilters, isUpdateFilters }) => ({
  ...oldState,
  ...{
    maps: {
      ...oldState.maps,
      appliedFilters: {
        ...oldState.maps?.appliedFilters,
        ...appliedFilters,
      },
      isUpdateFilters,
    },
  },
});

export function mapsReducer(state = {}, { type, payload }) {
  if (type === TOGGLE_POINTS_LIST) {
    return getNewState(state, {
      listOpened: payload,
    });
  }

  if (type === TOGGLE_MODE) {
    return getNewState(state, {
      mapMode: payload,
    });
  }

  if (type === SET_UPDATE_FILTERS_FLAG) {
    return getNewStateForFilters(state, {
      isUpdateFilters: payload,
    });
  }

  if (type === UPDATE_FILTER) {
    return getNewStateForFilters(state, {
      appliedFilters: payload,
      isUpdateFilters: false,
    });
  }

  if (type === GET_REQUEST_TOGGLE) {
    return getNewState(state, {
      isUpdatedMapFilterLinkEnabled: payload,
    });
  }

  if (type === GET_POINTS) {
    return getNewState(state, {
      points: payload.points.map((point) => ({
        ...point,
        place: {
          ...point.place,
          lowerCaseName: point.place?.name?.toLowerCase(),
        },
      })),
      clusters: payload.clusters,
      total: payload.total,
      isUpdatedMapFilterLinkEnabled: payload.isUpdatedMapFilterLinkEnabled,
    });
  }

  if (type === CHOOSE_POINT) {
    return getNewState(state, {
      listOpened: true,
      chosenPoint: {
        ...payload,
        showInfo: true,
        displayPoint: true,
      },
    });
  }

  if (type === HIDE_POINT_INFO) {
    return getNewState(state, {
      chosenPoint: {
        showInfo: false,
      },
    });
  }

  if (type === HIDE_POINT_ON_MAP) {
    return getNewState(state, {
      chosenPoint: {
        displayPoint: false,
      },
    });
  }

  if (type === SEARCH_ON_MAP) {
    return getNewState(state, {
      foundPoints: payload,
      searchOnMapError: false,
    });
  }

  if (type === SEARCH_ON_MAP_ERROR) {
    return getNewState(state, {
      searchOnMapError: true,
    });
  }

  if (type === SET_CURRENT_LOCATION) {
    return getNewState(state, {
      currentLocation: payload,
    });
  }

  if (type === SET_IS_ANNIVERSARY) {
    return getNewState(state, {
      isAnniversary: true,
    });
  }

  return state;
}
