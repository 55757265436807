import { SET_ALLINONE_VALUE } from '../actions/actionTypes';

export const allInOneReducer = (state, action) => {
  if (action.type === SET_ALLINONE_VALUE) {
    return {
      ...state,
      allInOne: {
        ...state.allInOne,
        ...{
          ...action.payload,
        },
        requestData:
          action.resetError ?
            {}
          : action.payload.requestData || (state.allInOne && state.allInOne.requestData),
      },
    };
  }

  return state;
};
