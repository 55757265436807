import axios from 'axios';

import { beautifyNumbers } from 'utils/fancyNumberBeautifier';

export const SET_FANCY_NUMBERS = 'SET_FANCY_NUMBERS';
export const SET_NEW_PHONE_INDEX = 'SET_NEW_PHONE_INDEX';
export const TOGGLE_FANCY_NUMBER_ORDER_POPUP = 'TOGGLE_FANCY_NUMBER_ORDER_POPUP';
export const CLEAR_USED_FANCY_NUMBER = 'CLEAR_USED_FANCY_NUMBER';
export const SET_TARIFFS_INFO = 'SET_TARIFFS_INFO';
export const SET_DEFAULT_STATE_OF_POPUP = 'SET_DEFAULT_STATE_OF_POPUP';
export const SET_FANCY_NUMBERS_LOADING_ERROR = 'SET_FANCY_NUMBERS_LOADING_ERROR';
export const SET_FANCY_NUMBERS_LOADING = 'SET_FANCY_NUMBERS_LOADING';

export const SET_CHOSEN_NUMBER = 'SET_CHOSEN_NUMBER';

export const setFuncyNumbers = (numbers) => ({
  type: SET_FANCY_NUMBERS,
  numbers,
});

export const setNewPhoneIndex = (categoryType, diffIndex = 1) => ({
  type: SET_NEW_PHONE_INDEX,
  payload: {
    categoryType,
    diffIndex,
  },
});

export const removeUsedNumber = (number) => ({
  type: CLEAR_USED_FANCY_NUMBER,
  payload: number,
});

export const setChosenNumber = (number) => ({
  type: SET_CHOSEN_NUMBER,
  payload: number,
});

const setFancyNumbersLoading = (isLoading) => ({
  type: SET_FANCY_NUMBERS_LOADING,
  payload: isLoading,
});

const setFancyNumbersLoadingError = (isError) => ({
  type: SET_FANCY_NUMBERS_LOADING_ERROR,
  payload: isError,
});

export const fetchNumbers =
  (refresh = 300000, highlight = false, isMain = true, numberMasks) =>
  (dispatch) => {
    const date = +new Date();
    const savedData = JSON.parse(localStorage.getItem('fancyNumbersData'));

    if (
      savedData &&
      savedData.numbers &&
      typeof savedData.numbers[0] !== 'string' &&
      refresh &&
      date - savedData.date < refresh
    ) {
      return dispatch(setFuncyNumbers(savedData.numbers));
    }

    dispatch(setFancyNumbersLoading(true));
    dispatch(setFancyNumbersLoadingError(false));

    return axios
      .get(`/fancynumber/all/${isMain ? '?ismain=true' : ''}`)
      .then((resp) => {
        if (!resp.data.numbers) {
          throw new Error('Server error');
        }
        const numbers =
          highlight ? beautifyNumbers(resp.data.numbers, numberMasks) : resp.data.numbers;
        dispatch(setFuncyNumbers(numbers));

        try {
          localStorage.setItem('fancyNumbersData', JSON.stringify({ date, numbers }));
      } catch (ex) {} // eslint-disable-line
      })
      .catch((err) => {
        dispatch(setFancyNumbersLoadingError(true));
        throw err;
      })
      .finally(() => {
        dispatch(setFancyNumbersLoading(false));
      });
  };
