import React from 'react';
import { useChangeZIndex } from '@beef/widget-chat';
import OldChat from '@beef/widget-chat-old';
import { cnTheme } from '@carnica/themes';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { TChatOld } from './types';

const cx = classNames.bind(styles);

// TODO: Удалить после полного перехода на новый чат.
export const ChatWidgetOld: React.FC<TChatOld> = ({
  className,
  onChatWidgetClick,
  domNodeRef,
  ...otherProps
}) => {
  const zIndexChat = useChangeZIndex();

  return (
    <div
      className={cx('wrapper', cnTheme('light'), className)}
      id="chat-widget"
      onClick={onChatWidgetClick}
      ref={domNodeRef}
      style={{ zIndex: zIndexChat }}
    >
      <OldChat {...otherProps} />
    </div>
  );
};
