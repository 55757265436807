import { browserTracingIntegration } from '@sentry/react';
import type { BrowserOptions } from '@sentry/react';
import type { ErrorEvent, Event } from '@sentry/types';

import {
  CROSS_DOMAIN_RESOURCES,
  ERRORS_REGEXP,
  FTTB_PAGES,
  OTHER_B2C_DOMAINS,
  PROFILE_PAGES,
  SENTRY_DSN,
} from './constants';

const filterErrorEvents = (event: ErrorEvent): Event | null => {
  const errorText = event.exception?.values?.[0]?.value || '';

  const matches = ERRORS_REGEXP.reduce<Array<string>>(
    (acc, regexp) => acc.concat(errorText.match(regexp) || []),
    [],
  );

  return matches.length ? null : event;
};

export const getSentryConfig = (env?: null | string): BrowserOptions => {
  if (process.env.NODE_ENV === 'development' || (env && !window.sentryEnabled)) {
    return {};
  }

  return {
    environment: 'production',
    dsn: SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    beforeSend: filterErrorEvents,
    tracesSampleRate: 0.15,
    sampleRate: 0.6,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
    denyUrls: [...FTTB_PAGES, ...PROFILE_PAGES, ...CROSS_DOMAIN_RESOURCES, ...OTHER_B2C_DOMAINS],
  };
};
