import { combineReducers } from 'redux';
import { assoc } from 'ramda';

import { mergeReducer, simpleReducer } from 'utils/reduxHelpers';

import * as actionTypes from '../actions/redirect';

const root = combineReducers({
  optionsPopup: simpleReducer(actionTypes.OPTIONS_POPUP, { opened: false }),
  serviceStatus: mergeReducer(actionTypes.SERVICE_STATUS, {
    touched: false,
    saved: true,
    loading: false,
    error: { active: false, text: 'Произошла ошибка на сервере' },
    loaded: false,
    options: actionTypes.defaultOptions,
    serverOptions: actionTypes.defaultOptions,
  }),
});

export const redirect = (state, action) => assoc('redirect', root(state.redirect, action), state);
