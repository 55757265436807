import axios from 'axios';
import { clone, isEmpty, isNil, path, pathOr } from 'ramda';
import { format } from 'date-fns';

import { formatDateWithDot, maskPhone, templateParser, unformatPhone } from 'utils/format-string';
import { SET_CONNECTION_RESULT_POPUP } from 'pages/ProductCard/actions/actionTypes';
import { setTariffConnectionPopup } from 'pages/ProductCard/actions/tariffConnect/tariffConnectExtension';

import { setOrderPaymentFormData, setOrderPaymentResultPopupData } from './orderPaymentForm';

export const DELIVERY_INFO_LOADING = 'DELIVERY_INFO_LOADING';
export const DELIVERY_INFO_UPDATE = 'DELIVERY_INFO_UPDATE';
export const DELIVERY_ACTIVE_TAB = 'DELIVERY_ACTIVE_TAB';
export const DELIVERY_SET_INPUT_VALUE = 'DELIVERY_SET_INPUT_VALUE';
export const DELIVERY_SET_BASE_DELIVERY_DAYS_DATA = 'DELIVERY_SET_BASE_DELIVERY_DAYS_DATA';
export const DELIVERY_UPDATE_DELIVERY_DAYS = 'DELIVERY_UPDATE_DELIVERY_DAYS';
export const DELIVERY_SET_PAYMENT_LOADING = 'DELIVERY_SET_PAYMENT_LOADING';

/**
 * Функция получает строковое значение даты и возвращает объект даты с учетом часового пояса
 * @param {string} strDate - Дата в формате ГГГГ-ММ-ДД
 * @return {Date} - дата в формате объекта Date
 */
export const getFineDate = (strDate) => {
  if (strDate) {
    const strArray = strDate.split('-');
    const numberArray = strArray.map((el, id) => (id === 1 ? +el - 1 : +el));

    return new Date(numberArray[0], numberArray[1], numberArray[2]);
  }

  return new Date(strDate);
};
const getTodayValue = () => new Date().getDate();
const checkToday = (date) => getFineDate(date).getDate() === getTodayValue();
const checkTomorrowDay = (date) => getFineDate(date).getDate() - getTodayValue() === 1;
const getTomorrowDate = () => new Date().setDate(getTodayValue() + 1);
const addPeriodToBeginning = (array, interval, date = new Date()) => {
  array.unshift({
    date: format(date, 'YYYY-MM-DD'),
    intervals: interval,
  });
};

const setLoadingState = (loading, currentTariffId) => ({
  type: DELIVERY_INFO_LOADING,
  payload: { loading, currentTariffId },
});

const setPaymentLoadingState = (loading, status = 'success') => ({
  type: DELIVERY_SET_PAYMENT_LOADING,
  payload: { loading, status },
});

const setDeliveryInfo = (payload) => ({
  type: DELIVERY_INFO_UPDATE,
  payload,
});

export const setInputValue = (payload) => (dispatch) => {
  dispatch({
    type: DELIVERY_SET_INPUT_VALUE,
    payload,
  });
};

const setBaseDeliveryDaysData = (payload) => ({
  type: DELIVERY_SET_BASE_DELIVERY_DAYS_DATA,
  payload,
});

const getPoints = (data) => ({
  points:
    data.points &&
    data.points.map((point) => ({
      id: point.id,
      name: point.name || '',
      address: point.address || '',
      opening: point.opening || '',
      availableDate: 'Сегодня и позднее', // Нужно как-то вычислять, но на первом этапе оставляем хардкодное значение
      subwayName: point.nearest_subway?.name || '',
      subwayColor: point.nearest_subway?.line_color || '',
    })),
});

const getBaseDeliveryDaysData = (data, excludeFastDelivery) => ({
  isFastDeliveryAvailable:
    !excludeFastDelivery && !isNil(data.fast_delivery) && !isEmpty(data.fast_delivery),
  isEmployeeDeliveryAvailable: !isNil(data.employee_delivery) && !isEmpty(data.employee_delivery),
  deliveryDays:
    data.intervals_per_date ?
      data.intervals_per_date.map((day) => ({
        date: day.date,
        type: 'COURIER',
        intervals: day.intervals.map(({ start, end }) => ({
          label: `с ${start} до ${end}`,
          start,
          end,
        })),
      }))
    : [],
});

const mapDeliveryDays = ({
  deliveryDays,
  isFastDeliveryAvailable,
  isEmployeeDeliveryAvailable,
  employeeDeliveryPeriods,
  fastDeliveryPeriod,
}) => {
  const onlyCourierDelivery = new URLSearchParams(window.location.search).has('onlycourier');

  // Проверка доступности сервиса быстрой доставки
  const reallyIsFastDeliveryAvailable =
    isFastDeliveryAvailable &&
    !onlyCourierDelivery &&
    new Date().getHours() < 18 &&
    new Date().getHours() >= 9;

  const firstEmployeeDeliveryPeriod = new Date().getHours() >= 12 && new Date().getHours() < 15;
  const secondEmployeeDeliveryPeriod = new Date().getHours() < 12;
  const thirdEmployeeDeliveryPeriod = new Date().getHours() >= 19;

  // Проверка доступности сервиса доставки силами сотрудников
  const reallyIsEmployeeDeliveryAvailable =
    isEmployeeDeliveryAvailable &&
    !onlyCourierDelivery &&
    !reallyIsFastDeliveryAvailable &&
    (firstEmployeeDeliveryPeriod || secondEmployeeDeliveryPeriod || thirdEmployeeDeliveryPeriod);

  const resultData = {
    isFastDeliveryAvailable: reallyIsFastDeliveryAvailable,
    isEmployeeDeliveryAvailable: reallyIsEmployeeDeliveryAvailable,
    deliveryDays: clone(deliveryDays),
  };

  // Если доступна быстрая доставка, то проверяем наличие сегодняшней даты в списке дат доставок
  // Если такая дата есть, то добавляем фиксированный интервал для быстрой доставки
  // Если такой даты нет, то добавляем ее вместе с фиксированным интервалом
  if (reallyIsFastDeliveryAvailable) {
    if (resultData.deliveryDays[0] && checkToday(resultData.deliveryDays[0].date)) {
      resultData.deliveryDays[0].intervals = fastDeliveryPeriod;
    } else {
      addPeriodToBeginning(resultData.deliveryDays, fastDeliveryPeriod);
    }

    resultData.deliveryDays[0].type = 'FAST';
  }

  // Если доступна доставка сотрудником, то ищем подходящий период на текущий момент времени
  if (reallyIsEmployeeDeliveryAvailable) {
    // Если удовлетворяет первый период, то проверяем наличие сегодняшней даты в списке дат доставок
    // Если такая дата есть, то добавляем фиксированный интервал для первого периода
    // Если такой даты нет, то добавляем ее вместе с фиксированным интервалом
    if (firstEmployeeDeliveryPeriod) {
      if (resultData.deliveryDays[0] && checkToday(resultData.deliveryDays[0].date)) {
        resultData.deliveryDays[0].intervals = employeeDeliveryPeriods.firstPeriod;
      } else {
        addPeriodToBeginning(resultData.deliveryDays, employeeDeliveryPeriods.firstPeriod);
      }
    }

    // Если удовлетворяет второй период, то проверяем наличие сегодняшней даты в списке дат доставок
    // Если такая дата есть, то добавляем фиксированный интервал для второго периода
    // Если такой даты нет, то добавляем ее вместе с фиксированным интервалом
    if (secondEmployeeDeliveryPeriod) {
      if (resultData.deliveryDays[0] && checkToday(resultData.deliveryDays[0].date)) {
        resultData.deliveryDays[0].intervals = employeeDeliveryPeriods.secondPeriod;
      } else {
        addPeriodToBeginning(resultData.deliveryDays, employeeDeliveryPeriods.secondPeriod);
      }
    }

    // Если удовлетворяет третий период, то проверяем наличие сегодняшней даты в списке дат доставок
    // Если такая дата есть, то удаляем ее и проверяем/добавляем завтрашнюю дату
    // с фиксированным интервалом для данного периода
    // Если такой даты нет, но есть завтрашняя дата в списке дат,
    // то добавляем фиксированный интервал для данного периода
    // Если в списке дат нету ни "сегодня", ни "завтра", то добавляем соответствующую дату с интервалом
    if (thirdEmployeeDeliveryPeriod) {
      const dateIsToday = resultData.deliveryDays[0] && checkToday(resultData.deliveryDays[0].date);
      const dateIsTomorrow =
        resultData.deliveryDays[0] && checkTomorrowDay(resultData.deliveryDays[0].date);

      if (dateIsToday) {
        resultData.deliveryDays.shift();
        if (checkTomorrowDay(resultData.deliveryDays[0].date)) {
          resultData.deliveryDays[0].intervals = employeeDeliveryPeriods.secondPeriod;
        } else {
          addPeriodToBeginning(
            resultData.deliveryDays,
            employeeDeliveryPeriods.secondPeriod,
            getTomorrowDate(),
          );
        }
      } else if (dateIsTomorrow) {
        resultData.deliveryDays[0].intervals = employeeDeliveryPeriods.secondPeriod;
      } else {
        addPeriodToBeginning(
          resultData.deliveryDays,
          employeeDeliveryPeriods.secondPeriod,
          getTomorrowDate(),
        );
      }
    }

    resultData.deliveryDays[0].type = 'EMPLOYEE';
  }

  return resultData;
};

export const updatingCurrentDeliveryDaysData = (timeout) => (dispatch, getState) => {
  const baseDeliveryDaysData = pathOr(
    null,
    ['external', 'deliveryForm', 'baseDeliveryDaysData'],
    getState(),
  );
  const { isEmployeeDeliveryAvailable, isFastDeliveryAvailable } = baseDeliveryDaysData;
  let timerId;

  if (isFastDeliveryAvailable || isEmployeeDeliveryAvailable) {
    timerId = setInterval(() => {
      const currentValues = path(['external', 'deliveryForm', 'inputValues'], getState());
      const deliveryData = path(['external', 'deliveryForm', 'deliveryData'], getState());
      const result = mapDeliveryDays(baseDeliveryDaysData);
      dispatch({
        type: DELIVERY_UPDATE_DELIVERY_DAYS,
        payload: result,
      });
      if (currentValues.isSelectedFastDelivery || currentValues.isSelectedEmployeeDelivery) {
        dispatch(
          setInputValue({
            ...currentValues,
            isSelectedFastDelivery:
              deliveryData.isFastDeliveryAvailable &&
              result.deliveryDays[currentValues.currentDeliveryDateIndex].type === 'FAST',
            isSelectedEmployeeDelivery:
              deliveryData.isEmployeeDeliveryAvailable &&
              result.deliveryDays[currentValues.currentDeliveryDateIndex].type === 'EMPLOYEE',
          }),
        );
      }
    }, timeout);
  }

  return timerId;
};

export const fetchDeliveryInfo =
  ({
    constructorId,
    optionSocs,
    tariffId,
    employeeDeliveryPeriods,
    fastDeliveryPeriod,
    expiredPeriodFastDelivery,
    paymentFailPopupContent,
    esim,
    excludeFastDelivery,
  }) =>
  (dispatch, getState) => {
    if (
      !constructorId &&
      path(['deliveryForm', 'currentTariffId'], getState().external) === tariffId
    ) {
      return Promise.resolve('alreadyLoading');
    }

    dispatch(setLoadingState(true, tariffId));
    dispatch(setOrderPaymentResultPopupData(paymentFailPopupContent));

    return axios
      .post('/order/DeliveryTypes/', { tariffId, constructorId, optionSocs })
      .then(({ data }) => {
        if (!data.data) throw new Error('notAvailable');
        const fastDeliveryWithType = fastDeliveryPeriod.map((period) => ({
          ...period,
          type: 'fastDelivery',
        }));

        const baseDeliveryDaysData = {
          employeeDeliveryPeriods,
          fastDeliveryPeriod: fastDeliveryWithType,
          expiredPeriodFastDelivery,
          paymentAvailable: !data.fastDeliveryCashPayment,
          ...getBaseDeliveryDaysData(data.data, excludeFastDelivery),
        };

        dispatch(setBaseDeliveryDaysData(baseDeliveryDaysData));
        const result = {
          ...getPoints(data.data),
          ...mapDeliveryDays(baseDeliveryDaysData),
        };

        const availableTypes = [];

        const deliveryAvailable = result.deliveryDays?.length > 0;
        const pointsAvailable = result.points;

        if (!deliveryAvailable && !pointsAvailable) {
          availableTypes.push('makeRequest');
        }

        if (deliveryAvailable) {
          availableTypes.push('delivery');
          const defaultDeliveryDate = result.deliveryDays[0].date;

          /* eslint-disable max-len */
          dispatch(
            setInputValue({
              currentDeliveryDateIndex: 0,
              currentDeliveryDate: defaultDeliveryDate,
              firstDeliveryDate: defaultDeliveryDate,
              lastDeliveryDate: result.deliveryDays[result.deliveryDays.length - 1].date,
              currentInterval: 1,
              isSelectedFastDelivery: result.isFastDeliveryAvailable, // при доступной быстрой доставке - она предвыбрана, тк в приоритете
              isSelectedEmployeeDelivery: result.isEmployeeDeliveryAvailable, // при доступной доставке сотрудником - она предвыбрана, тк в приоритете (после быстрой)
              contactType: 'email',
            }),
          );
          /* eslint-enable max-len */
        }
        if (pointsAvailable) {
          availableTypes.push('pickup');
        }
        if (esim) {
          availableTypes.push('esim');
        }

        if (availableTypes.length === 0) {
          throw new Error('notAvailable');
        }

        dispatch(
          setDeliveryInfo({
            availableTypes,
            activeTab: availableTypes[0],
            ...result,
          }),
        );
        dispatch(setLoadingState(false));
      })
      .catch(() => {
        dispatch(setLoadingState(false));
        throw new Error('error');
      });
  };

export const setActiveTab = (payload) => (dispatch) => {
  dispatch({
    type: DELIVERY_ACTIVE_TAB,
    payload,
  });
};

export const getDeliveryFormData = () => (dispatch, getState) => {
  const inputValues = pathOr({}, ['deliveryForm', 'inputValues'], getState().external);
  const deliveryData = pathOr({}, ['deliveryForm', 'deliveryData'], getState().external);

  const { activeTab, isFastDeliveryAvailable, isEmployeeDeliveryAvailable, deliveryDays } =
    deliveryData;
  const {
    isSelectedFastDelivery,
    isSelectedEmployeeDelivery,
    currentDeliveryDate,
    currentDeliveryDateIndex,
    currentInterval,
    address,
  } = inputValues;

  let type;
  if (activeTab === 'pickup') {
    type = 'pickup';
  } else if (isFastDeliveryAvailable && isSelectedFastDelivery) {
    type = 'fastDelivery';
  } else if (isEmployeeDeliveryAvailable && isSelectedEmployeeDelivery) {
    type = 'employee';
  } else {
    type = 'courier';
  }

  const data = { 'delivery.type': type };

  if (type === 'pickup') {
    return data;
  }

  if (!address || !currentDeliveryDate) {
    return null;
  }

  data['delivery.date'] = currentDeliveryDate;
  data['delivery.address'] = address;

  const interval =
    !isSelectedFastDelivery &&
    deliveryDays[currentDeliveryDateIndex].intervals[currentInterval - 1];

  data['delivery.time.start'] = interval.start;
  data['delivery.time.end'] = interval.end;

  return data;
};

export const openOrderPaymentForm =
  (texts, chosenNumber, tariffId, contactPhone = '', FTTBData = {}) =>
  async (dispatch, getState) => {
    dispatch(setPaymentLoadingState(true));
    dispatch(setTariffConnectionPopup({ contactPhone: maskPhone(contactPhone) }));
    const scenarioType = path(['deliveryForm', 'deliveryData', 'activeTab'], getState().external);
    const additionalOptions = pathOr(
      {},
      ['external', 'tariffConnectionPopup', 'additionalOptions'],
      getState(),
    );
    const promoPacks = pathOr({}, ['external'], getState());

    let paymentInfo;
    let additionalParams = {};
    if (additionalOptions?.chosenSocs) {
      let newChosenSocs = '';
      if (promoPacks.promocode) {
        const newPacks = Object.values(promoPacks.totalPacks);
        const internetPromoSoc = additionalOptions.chosenSocs
          .split(',')
          .find((elem) => newPacks.includes(elem));
        if (internetPromoSoc) {
          const OldPackSocs = Object.keys(promoPacks.totalPacks);
          const isHasPromoSoc = OldPackSocs.find((elem) => {
            if (promoPacks.totalPacks[elem] === internetPromoSoc) {
              return elem;
            }
          });
          newChosenSocs = additionalOptions.chosenSocs.replace(internetPromoSoc, isHasPromoSoc);
        }
      }
      if (newChosenSocs) {
        additionalParams = {
          ...additionalParams,
          additionalSocs: newChosenSocs.split(','),
        };
      } else {
        additionalParams = {
          ...additionalParams,
          additionalSocs: additionalOptions?.chosenSocs.split(','),
        };
      }
    }

    try {
      const resp = await axios.post('/order/prepareinfo/', { tariffId, ...additionalParams });

      if (!resp.data) throw new Error('err');

      paymentInfo = resp.data.priceInfo;
    } catch (err) {
      dispatch(setPaymentLoadingState(false, 'error'));

      if (scenarioType !== 'esim') {
        const errorPopupContent = path(['tariff', 'data', 'errorPopup'], getState().external);
        if (errorPopupContent) {
          // Показ попапа ошибки на карточке тарифа
          dispatch({
            type: SET_CONNECTION_RESULT_POPUP,
            payload: errorPopupContent,
          });
        } else {
          // Показ попапа ошибки на красивых номерах
          return true;
        }
      }
      return;
    }

    const params = [
      {
        title: `Ваш номер<br>${maskPhone(chosenNumber.number)}<br>${texts.duration || ''}`,
        value: chosenNumber.price ? `${chosenNumber.price} ₽` : 'Бесплатно',
        tooltip: texts.durationTooltip,
      },
      {
        title:
          paymentInfo.description || 'Стартовое пополнение баланса при покупке SIM. Вернём на счёт',
        value: `${paymentInfo.value} ₽`,
      },
    ];

    const totalPriceValue = {
      totalPrice: paymentInfo.value + (+chosenNumber.price || 0),
    };

    if (scenarioType === 'esim') {
      const { contactEmail } = getState().external.deliveryForm.inputValues;

      dispatch(
        setOrderPaymentFormData({
          tariffId,
          show: true,
          email: contactEmail,
          chosenNumber: unformatPhone(chosenNumber.number),
          FTTBData,
          pollingTime: paymentInfo.pollingTime,
          cardViewUrl: paymentInfo.cardViewUrl,
          orderPageUrl: paymentInfo.orderPageUrl,
          applePayEnabled: paymentInfo.isApplePayEnabled,
          googlePayEnabled: paymentInfo.isGooglePayEnabled,
          checkAvailabilityUrl: paymentInfo.checkAvailabilityUrl,
          applePayProcessPaymentUrl: paymentInfo.applePayProcessPaymentUrl,
          googlePayProcessPaymentUrl: paymentInfo.googlePayProcessPaymentUrl,
          ...totalPriceValue,
          texts: {
            ...texts,
            heading: templateParser(texts.heading, totalPriceValue),
            warning: templateParser(texts.warning, totalPriceValue),
            params,
          },
        }),
      );
    } else {
      const {
        inputValues: { currentDeliveryDateIndex, currentInterval },
        deliveryData: { deliveryDays },
      } = getState().external.deliveryForm;
      const delivery = dispatch(getDeliveryFormData());
      const propsForParser = {
        date: formatDateWithDot(delivery['delivery.date']),
        interval: deliveryDays[currentDeliveryDateIndex].intervals[currentInterval - 1].label
          .toLowerCase()
          .replace(' по ', ' до '),
        address: delivery['delivery.address'],
        ...totalPriceValue,
      };

      dispatch(
        setOrderPaymentFormData({
          tariffId,
          show: true,
          sum: paymentInfo.value,
          chosenNumber: unformatPhone(chosenNumber.number),
          FTTBData,
          contactPhone,
          texts: {
            ...texts,
            params,
            warning: templateParser(texts.warning, propsForParser),
            total: templateParser(texts.total, propsForParser),
          },
        }),
      );
    }

    dispatch(setPaymentLoadingState(false));
  };
