import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import { PopupStateless, Preloader } from '@beef/legacy-ui-kit';

import PlainContainer from 'pages-components/PopupContainer/PlainContainer';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class RestrictedPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: true,
      step: props.isRequestAvailable ? 'ready' : 'unavailable',
      description: props.content,
    };
  }

  onButtonClick = () => {
    if (this.state.step !== 'ready') {
      this.closePopup();
      return;
    }

    const { connectUrl } = this.props;

    this.setState({ step: 'loading' });

    axios
      .post(connectUrl)
      .then(({ data }) => {
        if (!data.isSucceeded) {
          return Promise.reject();
        }
        this.setState({
          step: 'success',
          description: data.content || null,
        });
      })
      .catch(() => {
        this.setState({ step: 'error' });
      });
  };

  closePopup = () => {
    this.setState({ opened: false });
  };

  render() {
    const { texts } = this.props;
    const { opened, step, description } = this.state;
    const currentTexts = texts[step] || texts.submit;
    const loading = step === 'loading';

    return (
      <PopupStateless onClose={this.closePopup} opened={opened}>
        <PlainContainer
          description={description}
          {...currentTexts}
          onButtonClick={loading ? undefined : this.onButtonClick}
          onSubButtonClick={loading ? undefined : this.closePopup}
        >
          {loading && <Preloader className={cx('preloader')} />}
        </PlainContainer>
      </PopupStateless>
    );
  }
}

RestrictedPopup.propTypes = {
  isRequestAvailable: PropTypes.bool,
  content: PropTypes.string,
  texts: PropTypes.shape({
    submit: PropTypes.object,
  }),
  connectUrl: PropTypes.string,
};

export default RestrictedPopup;
