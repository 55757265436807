import React from 'react';

// Copied from @beef/widget-chat
// TODO: add types export into @beef/widget-chat pckg and replace copy-paste to types import here
export const MESSENGERS_MENU_FLAGS = {
  IS_CHAT_CHAT_ENABLED: 'IS_CHAT_CHAT_ENABLED',
  IS_CHAT_WHATSAPP_ENABLED: 'IS_CHAT_WHATSAPP_ENABLED',
  IS_CHAT_VIBER_ENABLED: 'IS_CHAT_VIBER_ENABLED',
  IS_CHAT_TELEGRAM_ENABLED: 'IS_CHAT_TELEGRAM_ENABLED',
} as const;

export const AUTH_TYPES = {
  BisIdToken: 'BisToken',
  IdpIdToken: 'IdpIdToken',
  Captcha: 'Captcha',
} as const;

export type MessangersMenuFlag = (typeof MESSENGERS_MENU_FLAGS)[keyof typeof MESSENGERS_MENU_FLAGS];
export type AuthTypes = (typeof AUTH_TYPES)[keyof typeof AUTH_TYPES];

interface IChatWidgetConfig {
  apiVersion?: string;
  isOldDesignEnabled?: boolean;
  isWelcomeMessageOnStartEnabled?: boolean;
  messengers?: Array<MessangersMenuFlag>;
  url?: string;
  welcomeMessage?: string;
}
export interface IChatWidgetProps {
  className?: string;
  config?: IChatWidgetConfig;
  domNodeRef?: React.RefObject<HTMLDivElement>;
  onChatWidgetClick?: React.MouseEventHandler<HTMLDivElement>;
}

export type TChatWidget = IChatWidgetConfig &
  Pick<IChatWidgetProps, 'className' | 'domNodeRef' | 'onChatWidgetClick'> & {
    authType?: AuthTypes;
    authZoneConfig: {
      showMessageBeforeInput?: boolean;
      startMessage?: string;
      zoneType: string;
    };
    baseUrl?: string;
    userData?: {
      login: string;
      selectedCtn: string;
      selectedLogin: string;
      token: string;
      tokenType: AuthTypes;
    };
  };

export type TChatOld = TChatWidget & Pick<IChatWidgetConfig, 'isOldDesignEnabled'>;
