import {
  FETCH_FTTB_SPECIAL_TARIFF_ERROR,
  FETCH_FTTB_SPECIAL_TARIFF_START,
  FETCH_FTTB_SPECIAL_TARIFF_SUCCESS,
  SET_FTTB_SPECIAL_TARIFF_SHOW_IF_EXIST,
  SET_FTTB_SPECIAL_TARIFF_TYPE,
} from 'pages/FTTB/actions/actionTypes';
import { mapToPromoTariff } from 'pages/FTTB/utils/mapToPromoTariff';

const reducers = {
  [FETCH_FTTB_SPECIAL_TARIFF_START]: (state, payload) => ({
    ...state,

    fttbSpecialTariffData: {
      ...state.fttbSpecialTariffData,
      state: 'loading',
      showSpecTariffIfExist: payload.showSpecTariffIfExist,
    },
  }),
  [FETCH_FTTB_SPECIAL_TARIFF_SUCCESS]: (state, payload) => ({
    ...state,

    fttbSpecialTariffData: {
      ...state.fttbSpecialTariffData,

      state: 'loaded',
      tariff: payload.tariff ? mapToPromoTariff(payload.tariff) : null,
      tariffFullModel: payload.tariff ? payload.tariff : null,
      availabilitySpecialTariff: payload.tariff ? 'available' : 'notAvailable',
      houseId: payload.houseId,
    },
  }),
  [FETCH_FTTB_SPECIAL_TARIFF_ERROR]: (state) => ({
    ...state,
    fttbSpecialTariffData: { state: 'error' },
  }),
  [SET_FTTB_SPECIAL_TARIFF_TYPE]: (state, payload) => ({
    ...state,
    fttbSpecialTariffData: {
      ...state.fttbSpecialTariffData,
      fetchTariffType: payload.type && payload.type.toLowerCase(),
    },
  }),
  [SET_FTTB_SPECIAL_TARIFF_SHOW_IF_EXIST]: (state, payload) => ({
    ...state,
    fttbSpecialTariffData: {
      ...state.fttbSpecialTariffData,
      showSpecTariffIfExist: payload.show,
    },
  }),
};

export const fttbSpecialTariffData = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
