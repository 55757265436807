import { path } from 'ramda';

export const SET_LOADING_STATUS = 'set loading status';
export const setLoadingStatus = (status) => ({ type: SET_LOADING_STATUS, payload: status });

const getTimeout = (key, state) => path(['external', 'loading', key, 'timeout'], state);

export const updateLoadingStatus =
  ({
    key,
    /* loading status for defined element */
    loading,
    /* true | false */
    progress,
    time,
  }) =>
  (dispatch, getState) => {
    const state = getState();
    const keyIsLoading = path(['loading', key, 'loading'], state);
    const timeoutForKey = getTimeout(key, state);
    if (!keyIsLoading && loading) {
      const timeout = window.setTimeout(
        () => dispatch(setLoadingStatus({ key, loading, progress, time })),
        400,
      );
      dispatch(setLoadingStatus({ key, timeout }));
    }

    if (timeoutForKey && !loading) {
      window.clearTimeout(timeoutForKey);
      dispatch(
        setLoadingStatus({
          key,
          loading,
          progress,
          time,
          timeout: null,
        }),
      );
    }
  };
