import ESIMPurchaseDefaultState from 'pages/ESIMPurchase/reducers/defaultState';
import SPNDefaultState from 'pages/SPN/store/reducers/defaultState';

export default {
  tariffConstructor: {
    opened: false,
    isLifeFamily: false,
    step: 'pending',
    values: {
      seconds: 0,
      sms: 0,
      kbyte: 0,
      onNetSeconds: 0,
      offNetSeconds: 0,
      dataTariff: false,
    },
    data: {},
  },
  fttbWifiRouters: {},
  fttbSelectedTvConsoles: {},
  esim: ESIMPurchaseDefaultState,
  spn: SPNDefaultState,
};
