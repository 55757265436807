import { SET_AUTHORIZATION_POPUP_VALUE, TOGGLE_AUTHORIZATION_POPUP } from '../actions/actionsTypes';

export function authorizationWidgetsReducer(state = {}, { type, payload }) {
  switch (type) {
    case TOGGLE_AUTHORIZATION_POPUP: {
      return {
        ...state,
        authorizationPopup: {
          ...state.authorizationPopup,
          popupIsActive: !!payload.popupIsActive,
        },
      };
    }

    case SET_AUTHORIZATION_POPUP_VALUE: {
      return {
        ...state,
        authorizationPopup: {
          ...state.authorizationPopup,
          ...payload,
        },
      };
    }

    default: {
      return state;
    }
  }
}
