import { EHeaderTheme, setHeaderTheme as setNewHeaderTheme } from '@carnica/smart-kit';

import { headerStore2024 } from 'store';

export const setHeaderTheme = (theme: EHeaderTheme) => {
  if (typeof window === 'undefined') return;
  try {
    headerStore2024().dispatch(setNewHeaderTheme(theme));
  } catch (e) {
    console.error(e);
  }
};
