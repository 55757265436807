import React from 'react';
import { Chat2021, Header2021 } from '@beef/layout-kit';
import { prepareChatStore, prepareDevicesStore, prepareLoginStore } from '@beef/layout-kit/store';
import { mapDevicesPayload } from '@beef/layout-kit/utils';
import { useWillMount } from '@beef/react';

import store from 'store';
import { pushMenuClick } from 'utils/analytics/header';
import { setInterceptors } from 'utils/interceptorsHandler';
import { ChatWidget } from 'widgets/Chat';

import { ExtendedPersonalComponent } from './ExtendedPersonalComponent';
import { SET_AUTH_DATA, SET_CURRENT_CTN, SET_REGION, SET_USER } from './actionTypes';
import { TExtendedHeader } from './types';
import { useSentry } from '../HeaderV2/hooks/useSentry';

const ExtendedHeader = (props: TExtendedHeader) => {
  const { data } = props;

  useSentry(data.env);

  useWillMount(() => {
    if (typeof window === 'undefined') return;

    prepareChatStore();
    prepareLoginStore(data.loginModel);
    prepareDevicesStore(mapDevicesPayload(data));

    store.dispatch({
      type: SET_REGION,
      payload: data.currentRegion?.userRegion || data.currentRegion,
    });
    store.dispatch({ type: SET_CURRENT_CTN, payload: data.currentAccount });
    store.dispatch({ type: SET_USER, payload: data?.auth?.user });
    store.dispatch({
      type: SET_AUTH_DATA,
      payload: {
        profileUrl: data?.auth?.profileUrl,
      },
    });
    setInterceptors();
  });

  return (
    <Header2021
      {...props}
      ChatComponent={data.chat ? Chat2021 : null}
      ChatWidget={data.chatWidget ? ChatWidget : null}
      PersonalComponent={ExtendedPersonalComponent}
      fixedBreakPoint={1024}
      hideMobileNotification
      onEntryClick={pushMenuClick}
    />
  );
};

export default ExtendedHeader;
