import { pathOr } from 'ramda';

import {
  CHOSE_NUMBER,
  FETCH_NUMBERS,
  FETCHING_ERROR,
  INCREMENT_LIMIT,
  REMOVE_CHOSEN_NUMBER,
  SET_CATEGORY,
  SET_FETCHED_TARIFFS,
  SET_FILTER,
  SHUFFLE_NUMBERS,
  TOGGLE_POPUP_STATUS,
  UPDATE_HOT_NUMBERS,
} from '../actions';

const getNewState = (oldState, newData) => ({
  ...oldState,
  ...{
    fancyLanding: {
      ...oldState.fancyLanding,
      ...newData,
    },
  },
});

const categories = {
  R: 'spetsialnyie',
  B: 'bronzovie',
  G: 'zolotie',
  S: 'serebryanie',
};

const getNumbers = (data, category) => {
  const numbers = data || [];
  const index = numbers.findIndex((item) => item.alias === category);

  return numbers[index] ? numbers[index].numbers || [] : [];
};

export default (state, { type, payload }) => {
  if (type === FETCH_NUMBERS) {
    return getNewState(state, {
      numbers: getNumbers(payload.numbers, categories[state.fancyLanding.category]),
      luckCounter: 0,
    });
  }

  if (type === SET_FETCHED_TARIFFS) {
    return getNewState(state, {
      tariffsInfo: {
        tariffs: payload.tariffs,
        deliveryTariffId: pathOr(payload.defaultTariffId, [0, 'id'], payload.tariffs),
      },
    });
  }

  if (type === FETCHING_ERROR) {
    return getNewState(state, {
      fetchingError: payload,
    });
  }

  if (type === UPDATE_HOT_NUMBERS) {
    return getNewState(state, {
      hotNumbers: getNumbers(payload.numbers, categories[state.fancyLanding.category]),
    });
  }

  if (type === INCREMENT_LIMIT) {
    return getNewState(state, {
      limit: state.fancyLanding.limit ? state.fancyLanding.limit + 6 : 12,
    });
  }

  if (type === CHOSE_NUMBER) {
    return getNewState(state, {
      chosenNumber: payload,
    });
  }

  if (type === TOGGLE_POPUP_STATUS) {
    return getNewState(state, {
      popupIsActive: payload,
    });
  }

  if (type === SET_CATEGORY) {
    return getNewState(state, {
      category: payload,
    });
  }

  if (type === SET_FILTER) {
    return getNewState(state, {
      filter: payload,
    });
  }

  if (type === SHUFFLE_NUMBERS) {
    const luckCounter = state.fancyLanding.luckCounter || 0;
    const numbers = state.fancyLanding.numbers || [];

    return getNewState(state, {
      luckCounter: luckCounter + 1,
      numbers: numbers.sort(() => Math.random() - 0.5).slice(),
      limit: 6,
    });
  }

  if (type === REMOVE_CHOSEN_NUMBER) {
    const { value } = state.fancyLanding.chosenNumber;
    const { numbers } = state.fancyLanding;
    const { hotNumbers } = state.fancyLanding;
    let index = 0;
    let hotIndex = 0;

    for (; index < numbers.length; index++) {
      if (numbers[index].value === value) {
        numbers.splice(index, 1);
        break;
      }
    }
    for (; hotIndex < numbers.length; hotIndex++) {
      if (hotNumbers[hotIndex].value === value) {
        hotNumbers.splice(hotIndex, 1);
        break;
      }
    }

    return getNewState(state, {
      numbers: numbers.concat(),
      hotNumbers: hotNumbers.concat(),
    });
  }

  return state;
};
