import axios from 'axios';
import qs from 'query-string';

import { requestConfig } from '../constants';

/**
 * Проверяет достпуна ли квартира для подключения
 * @param {{houseId: string | null; isConvergentRequest: boolean; city: string; street: string; flat: string; isConnected: boolean | undefined; citiId: number; checkAddressType: boolean; house: string; streetId: string | null}} request Объект запроса
 * @param {string} request.city
 * @param {number} request.cityId
 * @param {string} request.street
 * @param {number} request.streetId
 * @param {string} request.house
 * @param {number} request.houseId
 * @param {boolean} request.isConnected
 * @param {string} request.flat
 */
function checkConnection(request) {
  const requestData = qs.stringify(request);

  return axios.post(
    '/customers/products/home/request/checkconnection/',
    requestData,
    requestConfig,
  );
}

export default checkConnection;
