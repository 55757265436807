import React from 'react';
import PropTypes from 'prop-types';
import { withDataConverter } from '@beef/react';
import { Footer, ShortFooter } from '@carnica/smart-kit';

import { footerConverter } from './dataConverter';

const _FooterV3 = ({ data }) => {
  return (
    <>
      <Footer data={data.footer} />
      <ShortFooter data={data.shortFooter} tagName="div" />
    </>
  );
};

_FooterV3.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape({}),
    shortFooter: PropTypes.shape({}),
  }),
};

export default withDataConverter(footerConverter)(_FooterV3);
