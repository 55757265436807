import axios from 'axios';
import qs from 'query-string';
import { equals, find, path, propEq } from 'ramda';

import { formatPhoneWithDefaultPrefix, unformatPhone } from 'utils/format-string';

import { startRequest } from '../components/ServiceStatusPopup/actions';
import { responseHandler } from '../components/RedirectService/utils/responseHandler';

export const defaultOptions = [
  { active: true, name: 'none' },
  { active: false, name: 'oneNumber', phone: '+7' },
  {
    active: false,
    name: 'multipleOptions',
    noAnswer: { active: false, phone: '+7', text: 'не отвечает' },
    busy: { active: false, phone: '+7', text: 'занят' },
    notAvailable: { active: false, phone: '+7', text: 'недоступен' },
  },
];

const pollData = (url, id) =>
  new Promise((resolve, reject) => {
    const pollTimeout = 300000;
    let currentTimeout = 500;
    let timeoutId = null;

    const poll = () => {
      axios
        .post(url, { requestId: id, clearJson: true })
        .then((data) => {
          if (data.data && !/not ready/gi.test(data.data)) {
            resolve(data.data);
            clearTimeout(timeoutId);
          } else if (currentTimeout > pollTimeout) {
            reject('Превышено время ожидания запроса');
            clearTimeout(timeoutId);
          } else {
            timeoutId = setTimeout(poll, currentTimeout);
            currentTimeout *= 1.25;
          }
        })
        .catch(() => {
          reject('Превышено время ожидания запроса');
          clearTimeout(timeoutId);
        });
    };
    poll();
  });

const mapToState = ({
  redirectAlways,
  doNotRedirect,
  redirectByConditions,
  phoneAlwaysRedirect,
  phoneIfUnavailable,
  phoneIfBusy,
  phoneIfNoAnswer,
}) => [
  { active: (!redirectAlways && !redirectByConditions) || doNotRedirect, name: 'none' },
  {
    active: !!redirectAlways,
    name: 'oneNumber',
    phone: formatPhoneWithDefaultPrefix(phoneAlwaysRedirect),
  },
  {
    active: !!redirectByConditions,
    name: 'multipleOptions',
    noAnswer: {
      active: !!phoneIfNoAnswer,
      phone: formatPhoneWithDefaultPrefix(phoneIfNoAnswer),
      text: 'не отвечает',
    },
    busy: {
      active: !!phoneIfBusy,
      phone: formatPhoneWithDefaultPrefix(phoneIfBusy),
      text: 'занят',
    },
    notAvailable: {
      active: !!phoneIfUnavailable,
      phone: formatPhoneWithDefaultPrefix(phoneIfUnavailable),
      text: 'недоступен',
    },
  },
];

const unmapFromState = (data) => {
  const activeOption = find(propEq('active', true), data);
  const response = {
    redirectByConditions: true,
  };

  switch (activeOption.name) {
    case 'oneNumber':
      return {
        redirectAlways: true,
        phoneAlwaysRedirect: unformatPhone(activeOption.phone),
      };
    case 'multipleOptions':
      response.phoneIfNoAnswer = '';
      response.phoneIfUnavailable = '';
      response.phoneIfBusy = '';

      if (activeOption.noAnswer.active) {
        response.phoneIfNoAnswer = unformatPhone(activeOption.noAnswer.phone);
      }
      if (activeOption.notAvailable.active) {
        response.phoneIfUnavailable = unformatPhone(activeOption.notAvailable.phone);
      }
      if (activeOption.busy.active) {
        response.phoneIfBusy = unformatPhone(activeOption.busy.phone);
      }
      return response;
    case 'none':
    default:
      return {};
  }
};

export const getData = (requestId) => {
  const url = '/customers/products/mobile/services/getredirectdata/';
  return pollData(url, requestId).then(mapToState);
};

export const connect = (data) =>
  axios.post('/customers/products/mobile/services/details/pereadresatsiya/', qs.stringify(data));

export const OPTIONS_POPUP = 'options popup';
export const optionsPopup = ({ opened }) => ({ type: OPTIONS_POPUP, payload: { opened } });

export const SERVICE_STATUS = 'redirect service status';
export const setServiceStatus = (status) => ({ type: SERVICE_STATUS, payload: status });

export const startRedirectConnectionRequest = (data) => (dispatch, getState) => {
  const serviceStatus = path(['external', 'redirect', 'serviceStatus'], getState());
  const { options } = serviceStatus;
  const dataToServer = typeof data !== 'undefined' ? data : options;
  dispatch(
    startRequest({
      key: 'redirectService',
      startUrl: '/customers/products/mobile/services/saveredirectdata/',
      pollUrl: '/customers/products/mobile/services/checkconnectservicestatus/',
      dataForStartRequest: unmapFromState(dataToServer),
      responseHandler,
    }),
  ).then(() => {
    const status = path(
      ['external', 'serviceStatus', 'data', 'redirectService', 'status'],
      getState(),
    );
    if (status === 'success') {
      dispatch(setServiceStatus({ touched: false, saved: true, serverOptions: dataToServer }));
      dispatch(setServiceStatus({ loading: false, loaded: true, options: dataToServer }));
    }
  });
};

export const setRedirectData = (isAuthenticated, requestId) => (dispatch, getState) => {
  const serviceStatus = path(['external', 'redirect', 'serviceStatus'], getState());
  if (serviceStatus.loaded) {
    dispatch(setServiceStatus({ loaded: true, touched: true }));
    dispatch(optionsPopup({ opened: true }));
    return;
  }
  dispatch(setServiceStatus({ loading: true, touched: true }));
  getData(requestId)
    .then((newState) => {
      dispatch(setServiceStatus({ loading: false, loaded: true, serverOptions: newState }));
      return newState;
    })
    .then((newState) => {
      const noneIsActiveInNewState = propEq('name', 'none', find(propEq('active', true), newState));
      if (newState && !noneIsActiveInNewState) {
        dispatch(setServiceStatus({ options: newState }));
      }
    })
    .catch(() => {
      dispatch(setServiceStatus({ error: { active: true }, loading: false }));
    });
};

export const disconnectRedirection = () => (dispatch, getState) => {
  const serviceStatus = path(['external', 'redirect', 'serviceStatus'], getState());
  const { options, serverOptions } = serviceStatus;
  const findActive = find(propEq('active', true));
  const activeServerOption = findActive(serverOptions);
  if (!equals(options, serverOptions) && activeServerOption === 'none') {
    dispatch(setServiceStatus({ options: serverOptions, saved: true }));
  } else {
    dispatch(startRedirectConnectionRequest(defaultOptions));
  }
};
