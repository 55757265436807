/**
  author: Vender Aleksandr
  version: 1.0.0
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
  - Компонент переключет картинки между desktop и mobile.
    Если передать source будет использует в себе tag <picture/>, что
    дает гибкость в использовании при адаптиве;

  ⬇︎⬇︎⬇︎ Способ применения симореть в конце файла ⬇︎⬇︎⬇︎
*/
export default class Picture extends Component {
  static emptyImage = '//static.beeline.ru/upload/images/b2c/0.png';

  renderImage() {
    const { alt, className, src, title, lazy, mobileOnly, desktopOnly } = this.props;

    return (
      <img
        alt={alt}
        className={cx({ mobileOnly, desktopOnly }, className)}
        loading={lazy ? 'lazy' : undefined}
        src={src}
        title={title}
      />
    );
  }

  render() {
    const { src, mobileOnly, desktopOnly, mobileSrc, pictureClassName } = this.props;
    let { source } = this.props;

    if (mobileOnly || desktopOnly) {
      source = [
        {
          media: 'max-width: 767px',
          srcSet: mobileOnly ? src : Picture.emptyImage,
        },
        {
          media: 'min-width: 768px',
          srcSet: desktopOnly ? src : Picture.emptyImage,
        },
      ];
    } else if (mobileSrc) {
      source = [
        {
          media: 'max-width: 767px',
          srcSet: mobileSrc,
        },
      ];
    }

    if (source) {
      return (
        <picture className={pictureClassName}>
          {source.map(
            (item, index) =>
              item && (
                <source
                  key={`source-${index}`}
                  media={`(${item.media})`}
                  srcSet={item.srcSet}
                  type={item.type || undefined}
                />
              ),
          )}
          {this.renderImage()}
        </picture>
      );
    }

    return this.renderImage();
  }
}

Picture.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  mobileSrc: PropTypes.string,
  lazy: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  desktopOnly: PropTypes.bool,
  source: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string,
      media: PropTypes.string,
    }),
  ),
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  pictureClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Picture.defaultProps = { alt: '' };

/**
--- Способ применения ---

<Picture
  src={webImage}
  className={cx('bgImage')}
  alt="Flowers"
  source={[{
    srcSet: mobImage,
    media: 'max-width: 768px',
  }]}
/>

<Picture
  src={webImage}
  mobileSrc={mobImage}
  className={cx('bgImage')}
  alt="Flowers"
/>

<Picture
  src={imageSrc}
  alt="image_from_static"
/>
*/
