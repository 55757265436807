export const footerConverter = ({ data }) => {
  const { auth, footer, shortFooter } = data;

  return {
    data: {
      footer: {
        ...footer,
        auth,
      },
      shortFooter: {
        ...shortFooter,
        auth,
      },
    },
  };
};
