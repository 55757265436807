import { determineDeclension as declension } from '@beef/utils';

export { declension };

export function maskPhone(value, mask = '+7 ___ ___ __ __') {
  let masked = mask;
  const onlyDigits = value ? value.replace(/\+7|\D/g, '') : '';

  let lastDigit;
  onlyDigits.split('').forEach((digit) => {
    if (masked.indexOf('_') > 0) {
      masked = masked.replace('_', digit);
      lastDigit = digit;
    }
  });

  return masked.slice(0, masked.lastIndexOf(lastDigit) + 1);
}

export function ctnMaskBeautifier(ctn) {
  const { length } = ctn;
  const clearNumber = ctn.replace(/\D/g, '');

  if (/^708|08\d{8}$/.test(clearNumber)) {
    return maskPhone(ctn, ' ___ ___ __ __');
  }
  if (/^79|9\d{9}$/.test(clearNumber)) {
    return maskPhone(ctn, '+7 ___ ___ __ __');
  }
  if (length > 16) {
    return `${ctn.substring(0, 7)}...${ctn.substring(length - 7, length)}`;
  }
  return ctn;
}

export const monthGenitive = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

// [один, два, пять]
export const dictionary = {
  additional: ['дополнительный', 'дополнительных', 'дополнительных'],
  additionalDevices: ['доп. устройство', 'доп. устройства', 'доп. устройств'],
  app: ['приложение', 'приложения', 'приложений'],
  channels: ['канал', 'канала', 'каналов'],
  days: ['день', 'дня', 'дней'],
  daysGenitive: ['дня', 'дней', 'дней'],
  gbGenetive: ['гигабайт', 'гигабайта', 'гигабайт'],
  goods: ['товар', 'товара', 'товаров'],
  minGenetive: ['минуту', 'минуты', 'минут'],
  minutes: ['минута', 'минуты', 'минут'],
  month: ['месяц', 'месяца', 'месяцев'],
  monthGenetive: ['месяца', 'месяцев', 'месяцев'],
  number: ['номер', 'номера', 'номеров'],
  numbers: ['номер', 'номера', 'номеров'],
  numbersCount: ['номером', 'номерами', 'номерами'],
  numbersGenitive: ['номера', 'номеров', 'номеров'],
  office: ['офис', 'офиса', 'офисов'],
  packages: ['пакет', 'пакета', 'пакетов'],
  peopleGenetive: ['человека', 'человек', 'человек'],
  secGenetive: ['секунду', 'секунды', 'секунд'],
  seconds: ['секунда', 'секунды', 'секунд'],
  service: ['услуга', 'услуги', 'услуг'],
  tariff: ['тариф', 'тарифа', 'тарифов'],
  weeks: ['неделя', 'недели', 'недель'],
};

export function translate(word, number) {
  if (!dictionary[word]) return null;
  return declension(dictionary[word], number);
}

// 79091232345566 initial phone format
export const formatPhone = (value) => {
  if (value.length < 5) return value;
  const mainNumber = value.slice(value.length - 10, value.length);
  const prefix = value.slice(0, value.length - 10);
  const code = mainNumber.slice(0, 3);
  const FstTelNum = mainNumber.slice(3, 6);
  const SndTelNum = mainNumber.slice(6, 8);
  const TrdTelNum = mainNumber.slice(8, 10);
  return `+${prefix} ${code} ${FstTelNum} ${SndTelNum} ${TrdTelNum}`;
};

export const getOnlyNumbres = (value) => value.replace(/\D/g, '');

export const formatPhoneWithDefaultPrefix = (value) => {
  if (!value) return value;
  return formatPhone(`7${value}`);
};

/* +7 (888) 333-44-44 to 8883334444 */
export const processPhoneNumber = (value) => {
  if (!value) return value;
  // Remove prefix +7 if it exists
  const withoutPrefix = value.startsWith('+7') ? value.slice(2) : value;

  return unformatPhone(withoutPrefix);
};

export const unformatPhone = (value) => {
  if (!value) return value;
  const onlyNumbers = value.replace(/\D/gi, '');
  return onlyNumbers.slice(onlyNumbers.length - 10, onlyNumbers.length);
};

export const templateParser = (string, vars) => {
  if (!string) return '';
  if (!vars) return string;
  return string.replace(/{{(\w+)}}/gi, (full, match) => vars[match]);
};

export const formatPriceNumber = (price) => {
  if (price >= 10000) {
    return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return String(price);
};

export const getNumberText = (number, wordCase = 'nominative') => {
  let numbers = [
    'один',
    'два',
    'три',
    'четыре',
    'пять',
    'шесть',
    'семь',
    'восемь',
    'девять',
    'десять',
    'одиннадцать',
    'двенадцать',
    'тринадцать',
    'четырнадцать',
    'пятнадцать',
  ];
  if (wordCase === 'instrumental') {
    numbers = numbers.map((item, index) => {
      switch (index) {
        case 0:
          return 'одним';
        case 1:
          return 'двумя';
        case 2:
          return 'тремя';
        case 3:
          return 'четырьмя';
        default:
          return item && `${item}ю`;
      }
    });
  }
  if (wordCase === 'genitive') {
    numbers = numbers.map((item, index) => {
      switch (index) {
        case 0:
          return 'одного';
        case 1:
          return 'двух';
        case 2:
          return 'трех';
        case 3:
          return 'четырех';
        case 7:
          return 'восьми';
        default:
          return item && `${item.substring(0, item.length - 1)}и`;
      }
    });
  }
  return numbers[number - 1];
};

export const getNumberCountText = (number, wordCase = 'nominative') => {
  if (wordCase === 'instrumental') {
    const numberString = number === 1 ? 'номером' : 'номерами';
    return `${getNumberText(number, wordCase)} ${numberString}`;
  }
  return `${getNumberText(number)} ${translate('number', number)}`;
};

export const validateFancyNumber = (order) => (value) => {
  const val = value ? value.replace(/\+7|\D/g, '') : '';
  const ord = order ? order.replace(/\+7|\D/g, '') : '';
  const status = val.length === 10 && !/^([0-9])\1+$/.test(val) && val !== ord ? 'ok' : 'typo';

  return {
    forceError: val.length === 10 && status === 'typo',
    status,
  };
};

const getFormattedUnit = (param) => (param > 9 ? param : `0${param}`);

export const formatDateWithDot = (date) => {
  const currentDate = new Date(date);
  const currentDay = currentDate.getDate();
  const currentMonth = getFormattedUnit(currentDate.getMonth() + 1);
  const currentYear = currentDate.getFullYear();

  return `${currentDay}.${currentMonth}.${currentYear}`;
};

export function formatDate(date, format = 'withTime') {
  const day = date.getDate();
  const year = date.getFullYear();
  const month = monthGenitive[date.getMonth()];
  const hours = getFormattedUnit(date.getHours());
  const minutes = getFormattedUnit(date.getMinutes());

  if (format === 'withYear') {
    return `${day}&nbsp;${month}&nbsp;${year}`;
  }

  if (format === 'withTime') {
    return `${day}&nbsp;${month}&nbsp;${hours}:${minutes}`;
  }

  return `${day}\u00A0${month}`;
}

export const replacePriceDot = (value) => {
  let val = value || '';

  if (typeof value === 'number') {
    val = value.toString();
  }

  return val.replace('.', ',');
};

export const stripTags = (string) => string.replace(/<\/?[^>]+(>|$)/g, '');

/**
 * Разбивает число на сотые разделяя пробелами;
 * @param {number} number Число;
 * @return {string | null} Строка, разбирая на сотые, либо null;
 */
export const formatIntoHundredths = (number) =>
  number !== null && number !== undefined ?
    String(number).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 ')
  : null;

const floorToTenth = (number) => Math.floor(number * 10) / 10;

export const getSecondsString = (value, whole) =>
  `${Math.floor(value / 60)} ${whole ? translate('minGenetive', Math.floor(value / 60)) : 'мин.'}`;

export const getKbyteString = (value, whole) =>
  // eslint-disable-next-line no-nested-ternary
  value >= 1048576 ?
    `${floorToTenth(value / 1048576)} ${
      whole ? translate('gbGenetive', Math.floor(value / 1048576)) : 'ГБ'
    }`
  : value >= 1024 ? `${Math.floor(value / 1024)} Мб`
  : `${value} Кб`;

export const VALID_PHONE_LENGTH = 10;

export const getOnlyDigitsPhone = (phone) => phone.replace(/\+7|\D/g, '');

export const isFilledPhone = (phone) => getOnlyDigitsPhone(phone).length === VALID_PHONE_LENGTH;

// NOTE: Allows to interpolate string with nested structures
// (e.g. "Test {{check.test}}" could be interpolated if vars contain { check: { test: 'value' }})
export const nestedTemplateParser = (str: string, vars: Record<string, any>) => {
  try {
    const nestedPropRegex = /{{([a-zA-Z0-9_.[\]]+)}}/g;

    const getNestedValue = (obj: any, key: string) =>
      key
        .split(/\.|\[|\]/)
        .filter((prop) => prop !== '')
        .reduce((o, prop) => {
          if (!o || !prop) return undefined;

          const [_, value] =
            Object.entries(o).find(([oKey]) => oKey.toLowerCase() === prop.toLowerCase()) || [];

          return value ?? '';
        }, obj);

    return str.replaceAll(nestedPropRegex, (_, match: string) => {
      const value = getNestedValue(vars, match);
      if (value === undefined) return '';
      if (typeof value === 'object') return JSON.stringify(value);
      return value;
    });
  } catch (e) {
    console.error('Could not parse template', e);
    return '';
  }
};

export const formatTimer = (timer) => {
  const minutes = `${Math.floor(timer / 60)}`;

  const secondsInTimer = timer % 60;
  const seconds = secondsInTimer < 10 ? `0${secondsInTimer}` : `${secondsInTimer}`;

  return `${minutes}:${seconds}`;
};

export const formatNumber = (value) => {
  const number = `${value}`;
  return `+7&nbsp;${number.slice(0, 3)}&nbsp;${number.slice(3, 6)}-${number.slice(
    6,
    8,
  )}-${number.slice(8, 10)}`;
};

export const getTextFromHtml = (str: string | undefined) => {
  // Избежать краша если будет undefined
  if (typeof str !== 'string') {
    return '';
  }
  const textWithoutTags = str.replace(/<[^>]*>/g, ' ');
  // Декодируем HTML сущности
  const textWithoutEntities = textWithoutTags
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");

  return textWithoutEntities.trim();
};
