import PropTypes from 'prop-types';

export const currentStateList = {
  none: 'none',
  requestForm: 'requestForm',
  success: 'success',
  fail: 'fail',
  loading: 'loading',
  abbreviated: 'abbreviated',
  captcha: 'captcha',
};

export const quickOrderRequestUrl = '/hometariffsapi/SubmitConnectionCallbackRequest/';

export const catalogPageTypeList = {
  fixed: 1,
  internet: 2,
  kit: 3,
};

export const COUNTS_OF_ZONE_LIST = 12;
export const ZONE_NUMBERS = {
  streetActivePage: 0,
  districtActivePage: 1,
  areaActivePage: 2,
  metroActivePage: 3,
  metroLineActivePage: 4,
  zhkActivePage: 5,
};

export const serviceTypes = {
  tvPackage: 'tvPackage',
  additionalService: 'additionalService',
  antivirus: 'antivirus',
  wifiRouter: 'wifiRouter',
  tvConsole: 'tvConsole',
  tvConsoleForChange: 'tvConsoleForChange',
  additionalTvConsole: 'additionalTvConsole',
};

export const deviceSelectionTypes = {
  available: 'available',
  required: 'required',
  preEnabled: 'pre-enabled',
};

export const connectionDataPropTypes = {
  city: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  street: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  house: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  flat: PropTypes.string,
};

export const requestConfig = {
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
};

export const checkAddressModelPropTypes = {
  changeCity: PropTypes.func,
  changeStreet: PropTypes.func,
  changeHouse: PropTypes.func,
  changeFlat: PropTypes.func,

  fetchStreets: PropTypes.func,
  fetchHouses: PropTypes.func,

  clearStreet: PropTypes.func,
  clearHouse: PropTypes.func,

  currentCity: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }),

  streets: PropTypes.arrayOf(connectionDataPropTypes.street),
  street: connectionDataPropTypes.street,
  streetTerm: PropTypes.string,

  houses: PropTypes.arrayOf(connectionDataPropTypes.house),
  house: connectionDataPropTypes.house,
  houseTerm: PropTypes.string,

  flat: connectionDataPropTypes.flat,

  setAndSaveConnectionState: PropTypes.func,
  setConnectionData: PropTypes.func,

  loadingConnectionData: PropTypes.bool,
};

export const productType = {
  internet: 0,
  tv: 1,
  kit: 2,
};

export const gaEventLabel = {
  getBeeline: 'getBeeline',
  chosen: 'chosen',
};
