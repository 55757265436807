import { once } from 'ramda';

import { getCookie } from './cookie';

/**
 @description - Фукция находит обьект у которого значение равное параметру value
 @params [array] list - Список по которому идет поиск
 @params [string] key - Ключ в котором лежит необходимое значение
 @params [string] value - Строка которая должна совпадать со значением по ключу
 */
const filter = (list, key, value) => list && list.filter((item) => item[key] === value);

/**
 * Push order data to data layer.
 * @param event {object} Event data.
 */
export const pushDataLayer = (event) => {
  if (window.dataLayer) {
    window.dataLayer.push(event);
  }
};

/**
 Сбор статистики на странице "Каталог тарифов"
 */
export const pushGAeeBeeTariffCatalog = (list = []) => {
  const impressions = list.map((item, i) => {
    let price = '';

    if (item.parameters && item.parameters.length) {
      // "Абонентская плата" - это костыль, так не должно быть, но выхода нет
      const filterResults = filter(item.parameters, 'label', 'Абонентская плата');
      price = filterResults[0].value;
    }

    return {
      name: item.title,
      id: item.soc,
      price,
      metric13: price,
      metric14: 0,
      dimension12: '',
      dimension13: '',
      brand: '',
      variant: item.familyTitle,
      category: 'Тарифы',
      list: 'Тарифы',
      position: i,
    };
  });

  pushDataLayer(impressions);
};

/**
 Сбор статистики на странице "Заявка на подключение интернета"
 */
export const pushGAeeBeeAllInOneForm = (data) => {
  const { soc, mobileTariffName, feePerMonth } = data;

  pushDataLayer({
    event: 'ee_Bee',
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        products: [
          {
            name: mobileTariffName,
            id: soc,
            price: feePerMonth,
            metric13: feePerMonth,
            metric14: 0,
            dimension12: '',
            dimension13: '',
            brand: '',
            variant: '',
            category: 'Тарифы',
          },
        ],
      },
    },
  });
};

/**
 Сбор статистики при отправки на странице "Заявка на подключение интернета"
 */
export const pushGAeeBeeAllInOneFormAction = (data) => {
  const { soc, tariffValue, tariffName } = data;

  pushDataLayer({
    event: 'ee_Bee',
    ecommerce: {
      currencyCode: 'RUB',
      purchase: {
        actionField: {
          id: soc,
          revenue: tariffValue,
        },
        products: [
          {
            name: tariffName,
            id: soc,
            price: tariffValue,
            metric13: tariffValue,
            metric14: 0,
            dimension12: '',
            dimension13: '',
            brand: '',
            variant: '',
            category: 'Тарифы',
          },
        ],
      },
    },
  });
};

export function getCustomPusher(defaults) {
  return (data) => {
    const eventData = {
      ...defaults,
      ...data,
    };
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventData);
    }
  };
}

export const pushOrderEvent = getCustomPusher({
  event: 'event_newsim_order_success',
  ordered_itemProduct: 'Мобильная связь',
  orderId: 'Undefined',
  price: 'Undefined',
  ordered_itemCat: 'Undefined',
  ordered_itemCatNumber: 'Undefined',
  ordered_itemPodCatNumber: 'Undefined',
  ordered_itemTitle: 'Undefined',
  ordered_itemSoc: 'Undefined',
  ordered_itemMethod: 'Undefined',
});

export const pushLoginEvent = getCustomPusher({
  event: 'GA_event',
  eventCategory: 'loginSteps',
});

/**
 * Get google analytics client id.
 * @returns {string} GA client id.
 */
export function getGAClientId() {
  const gaCookie = getCookie('_ga');
  if (gaCookie) {
    const ga = gaCookie.split('.');
    if (ga.length === 4) return `${ga[2]}.${ga[3]}`;
  }
  return '';
}

export const shippingTypes = {
  pickup: 'Самовывоз',
  courier: 'Обычная доставка',
  fastDelivery: 'Срочная доставка',
};

/**
 * Push SIM order analytics event.
 * @param orderId {string} SIM order id.
 * @param numberCategory {string} Number category.
 * @param numberSubCategory {string} Number subcategory.
 * @param tariffName {string} Tariff name.
 * @param soc {string} Tariff SOC or alias.
 * @param method {string} Request method.
 * @param price {number} Price.
 * @param shipping {string} Shipping type.
 */
export const pushSimOrderEvent = ({
  orderId,
  numberCategory,
  numberSubCategory,
  tariffName,
  soc,
  method,
  price,
  shipping,
}) =>
  pushDataLayer({
    event: 'event_newsim_order_success',
    orderId,
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemCatNumber: method === 'Sim' ? 'Not set' : numberCategory || 'Случайный номер',
    ordered_itemPodCatNumber:
      method === 'Sim' ? 'Not set' : numberSubCategory || 'Бесплатный номер',
    ordered_itemTitle: tariffName,
    ordered_itemSoc: soc,
    ordered_itemMethod: method,
    ordered_itemShipping: shippingTypes[shipping] || 'Не указан',
    price: method === 'SimNumber' ? price : undefined,
  });

/**
 * Push GA for open tariff connect popup event
 * @param method {string} One of Sim or SimNumber
 * @param soc {string} Tariff soc
 * @param marketCode {string} Market code
 * @param tariffName {string} Tariff name
 * @param isMnp {boolean} True, if MNP button clicked and opened MNP popup
 */
export const pushOpenTariffConnectPopup = ({
  method,
  soc,
  marketCode,
  tariffName,
  isMnp = false,
}) => {
  const paramsGA = {
    event: 'event_popup_interaction',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Tariffs',
    itemDeviceType: 'mobile',
    itemTitle: tariffName,
    itemMarketCode: marketCode,
    itemSoc: soc,
  };

  if (isMnp) {
    pushDataLayer({
      ...paramsGA,
      itemMethod: 'MNP',
    });
  } else {
    if (method === 'SimNumber') paramsGA.price = 0;

    pushDataLayer({
      ...paramsGA,
      itemMethod: method,
      itemCatNumber: method === 'SimNumber' ? 'Случайный номер' : 'Not set',
      itemPodCatNumber: method === 'SimNumber' ? 'Бесплатный номер' : 'Not set',
    });
  }
};

/**
 * Push SIM order analytics event.
 * @param error {string} error text.
 * @param tariffName {string} Tariff name.
 * @param soc {string} Tariff SOC or alias.
 * @param method {string} Request method.
 * @param numberCategory {string} Number category.
 * @param numberSubCategory {string} Number subcategory.
 */
export const pushSimOrderErrorEvent = ({
  error,
  tariffName,
  soc,
  method,
  numberCategory,
  numberSubCategory,
}) =>
  pushDataLayer({
    event: 'event_newsim_order_error',
    connectionErrorType: error || 'Что-то пошло не так, попробуйте повторить позднее',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemTitle: tariffName,
    ordered_itemSoc: soc,
    ordered_itemMethod: method,
    ordered_itemCatNumber: numberCategory || 'Случайный номер',
    ordered_itemPodCatNumber: numberSubCategory || 'Бесплатный номер',
  });

/**
 * Push mobile tariff connect event to GA.
 * @param isOrder {boolean} Connection or connection order.
 * @param ctn {string} Client CTN.
 * @param tariffName {string} Tariff name.
 * @param soc {string} Tariff SOC or alias.
 * @param orderId {string} Connection order id.
 * @param marketCode {string} Tariff market code.
 * @param currentTariffName {string} Tariff name.
 * @param currentTariffSoc {string} Tariff SOC or alias.
 */
export const pushMobileTariffConnectEvent = ({
  isOrder = false,
  ctn,
  tariffName,
  soc,
  orderId,
  marketCode,
  currentTariffName,
  currentTariffSoc,
}) => {
  const orderExt = { orderId };
  const data = {
    event: isOrder ? 'event_item_order_sent_success' : 'event_item_connection_success',
    clientType: 'B2C',
    clientCTN: ctn,
    currentTariffName,
    currentTariffSoc,
    ordered_itemMarketCode: marketCode,
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemTitle: tariffName,
    ordered_itemSoc: soc,
  };
  pushDataLayer(isOrder ? { ...data, ...orderExt } : data);
};

/**
 * Push analytics data for show tariff connection pop-up.
 * @param tariffName {string} Tariff name.
 * @param soc {string} Tariff SOC.
 * @param marketCode {string} Tariff market code.
 * @param method {string} Order type. One of Sim, SimNumber, MNP.
 * @param catNumber {string} Type of fancy number.
 * @param podCatNumber {string} Price of fancy number.
 */
export const pushShowTariffConnectionPopupEvent = ({
  tariffName,
  soc,
  marketCode,
  method,
  catNumber,
  podCatNumber,
}) => {
  pushDataLayer({
    event: 'event_popup_interaction',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Tariffs',
    itemDeviceType: 'mobile',
    itemTitle: tariffName,
    itemSoc: soc,
    itemMarketCode: marketCode,
    itemMethod: method,
    itemCatNumber: catNumber,
    itemPodCatNumber: podCatNumber,
  });
};

/**
 * Push analytics data for tariff connection error.
 * @param error {string} Error message.
 * @param tariffName {string} Tariff name.
 * @param soc {string} Tariff SOC.
 * @param marketCode {string} Tariff market code.
 * @param ctn {string} Client CTN.
 * @param currentTariffName {string} Current tariff name.
 * @param currentSoc {string} Current tariff SOC.
 */
export const pushTariffConnectionErrorEvent = ({
  error,
  tariffName,
  marketCode,
  soc,
  ctn,
  currentTariffName,
  currentTariffSoc,
}) => {
  pushDataLayer({
    event: 'event_item_connection_error',
    connectionErrorType: error || 'Что-то пошло не так, попробуйте повторить позднее',
    clientType: 'B2C',
    clientCTN: ctn,
    currentTariffName,
    currentTariffSoc,
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemTitle: tariffName,
    ordered_itemMarketCode: marketCode,
    ordered_itemSoc: soc,
  });
};

/**
 * Push analytics data for tariff connection processing.
 * @param tariffName {string} Tariff name.
 * @param soc {string} Tariff SOC.
 * @param marketCode {string} Tariff market code.
 */
export const pushTariffConnectProcessingEvent = ({ tariffName, soc, marketCode }) => {
  pushDataLayer({
    event: 'event_popup_processing',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Tariffs',
    itemDeviceType: 'mobile',
    itemTitle: tariffName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

/**
 * Push event for processing pop-up close.
 */
export const pushTariffProcessingCloseEvent = () => {
  pushDataLayer({ event: 'popup_processing_close' });
};

export const pushNumberErrorEvent = () => {
  pushDataLayer({ event: 'number_error' });
};

export const pushTariffDownsellShowEvent = ({
  downsellTariffName,
  downsellSoc,
  downsellMarketCode,
}) => {
  pushDataLayer({
    event: 'event_popup_downsell',
    itemProductDownsell: 'Мобильная связь',
    itemCatDownsell: 'Tariffs',
    itemTitleDownsell: downsellTariffName,
    itemSocDownsell: downsellSoc,
    itemMarketCodeDownsell: downsellMarketCode,
  });
};

export const pushTariffDownsellConnectSuccessEvent = ({
  downsellTariffName,
  downsellSoc,
  downsellMarketCode,
}) => {
  pushDataLayer({
    event: 'event_downsell_success',
    itemProductDownsell: 'Мобильная связь',
    itemCatDownsell: 'Tariffs',
    itemTitleDownsell: downsellTariffName,
    itemSocDownsell: downsellSoc,
    itemMarketCodeDownsell: downsellMarketCode,
  });
};

export const pushTariffSharingConnectEvent = ({ tariffName, soc, marketCode }) => {
  pushDataLayer({
    event: 'event_seb_success',
    itemProductSeb: 'Мобильная связь',
    itemCatSeb: 'Tariffs',
    itemTitleSeb: tariffName,
    itemSocSeb: soc,
    itemMarketCodeSeb: marketCode,
  });
};

/**
 * Push analytics data for MNP request errors.
 */
export const pushMnpErrorEvent = () => {
  pushDataLayer({ event: 'event_popupMNP_fail' });
};

export const pushUpsellShowPopupEvent = ({ isAuthenticated, serviceName, soc, marketCode }) => {
  pushDataLayer({
    event:
      isAuthenticated ? 'event_upsellService_interactionLK' : 'event_upsellService_interaction',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Services',
    itemTitle: serviceName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

export const pushUpsellConfirmationEvent = ({ serviceName, soc, marketCode }) => {
  pushDataLayer({
    event: 'event_upsellService_confirmation',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Services',
    itemTitle: serviceName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

export const pushUpsellConnectSuccessEvent = ({ serviceName, soc, marketCode }) => {
  pushDataLayer({
    upsell: 1,
    event: 'event_item_connection_success',
    clientType: 'B2C',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Services',
    ordered_itemTitle: serviceName,
    ordered_itemSoc: soc,
    ordered_itemMarketCode: marketCode,
  });
};

export const pushUpsellConnectErrorEvent = ({ error, serviceName, soc, marketCode }) => {
  pushDataLayer({
    upsell: 1,
    event: 'event_item_connection_error',
    connectionErrorType: error || 'Что-то пошло не так, попробуйте повторить позднее',
    clientType: 'B2C',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Services',
    ordered_itemTitle: serviceName,
    ordered_itemSoc: soc,
    ordered_itemMarketCode: marketCode,
  });
};

/**
 * Push analytics data for MNP request success.
 * @param orderId {number} Order ID.
 * @param shipping {string} Shipping type.
 */
export const pushMnpSuccessEvent = ({ orderId, shipping }) => {
  pushDataLayer({
    event: 'event_newsim_order_success',
    orderId,
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemMethod: 'MNP',
    ordered_itemShipping: shippingTypes[shipping],
  });
};

/**
 * Push analytics data for service connection pop-up.
 * @param isAuthenticated {boolean} True, if authenticated.
 * @param inProcess {boolean} True, if connection in process.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushShowServiceConnectionPopupEvent = ({
  isAuthenticated,
  inProcess,
  serviceName,
  soc,
  marketCode,
}) => {
  let event = isAuthenticated ? 'event_popup_confirmation' : 'event_popup_interaction';
  if (inProcess) {
    event = 'event_popup_processing';
  }

  pushDataLayer({
    event,
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Services',
    itemTitle: serviceName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

/**
 * Push analytics data for service connection success.
 * @param isConnected {boolean} True, if service connected.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushServiceConnectionSuccessEvent = ({
  isConnected,
  serviceName,
  marketCode,
  soc,
}) => {
  pushDataLayer({
    event: isConnected ? 'event_item_connection_success' : 'event_item_order_sent_success',
    clientType: 'B2C',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Services',
    ordered_itemTitle: serviceName,
    ordered_itemMarketCode: marketCode,
    ordered_itemSoc: soc,
  });
};

/**
 * Push analytics data for service connection error.
 * @param error {string} Error message.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushServiceConnectionErrorEvent = ({ error, serviceName, marketCode, soc }) => {
  pushDataLayer({
    event: 'event_item_connection_error',
    connectionErrorType: error || 'Что-то пошло не так, попробуйте повторить позднее',
    clientType: 'B2C',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Services',
    ordered_itemTitle: serviceName,
    ordered_itemMarketCode: marketCode,
    ordered_itemSoc: soc,
  });
};

/**
 * Push analytics data for service disconnection success.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 * @param isUpsell {boolean} True, if upsell service.
 */
export const pushServiceDisconnectionSuccessEvent = ({
  serviceName,
  marketCode,
  soc,
  isUpsell,
}) => {
  const upsellData = isUpsell ? { upsell: 1 } : {};
  pushDataLayer({
    event: 'event_item_disconnection_success',
    clientType: 'B2C',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Services',
    ordered_itemTitle: serviceName,
    ordered_itemMarketCode: marketCode,
    ordered_itemSoc: soc,
    ...upsellData,
  });
};

/**
 * Push analytics data for service connection error.
 * @param error {string} Error message.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushServiceDisconnectionErrorEvent = ({ error, serviceName, marketCode, soc }) => {
  pushDataLayer({
    event: 'event_item_disconnection_error',
    connectionErrorType: error || 'Что-то пошло не так, попробуйте повторить позднее',
    clientType: 'B2C',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Services',
    ordered_itemTitle: serviceName,
    ordered_itemMarketCode: marketCode,
    ordered_itemSoc: soc,
  });
};

/**
 * Push analytics data for service disconnection.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushShowServiceOffEvent = ({ serviceName, soc, marketCode }) => {
  pushDataLayer({
    event: 'event_serviceOff',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Services',
    itemDeviceType: 'mobile',
    itemTitle: serviceName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

/**
 * Push analytics data for show service disconnection pop-up.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushShowServiceOffPopupEvent = ({ serviceName, soc, marketCode }) => {
  pushDataLayer({
    event: 'event_popup_serviceOff_confirmation',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Services',
    itemDeviceType: 'mobile',
    itemTitle: serviceName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

/**
 * Push analytics data for show service connection cancel pop-up.
 * @param serviceName {string} Service name.
 * @param soc {string} Service SOC.
 * @param marketCode {string} Service market code.
 */
export const pushShowServiceCancelPopupEvent = ({ serviceName, soc, marketCode }) => {
  pushDataLayer({
    event: 'event_popup_serviceOff_confirmation2',
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Services',
    itemDeviceType: 'mobile',
    itemTitle: serviceName,
    itemSoc: soc,
    itemMarketCode: marketCode,
  });
};

export const pushRoamingSearchEvent = ({ value }) => {
  pushDataLayer({
    event: 'event_roaming_search',
    search: value,
  });
};

export const pushRoamingConfirmationEvent = () => {
  pushDataLayer({ event: 'roaming_confirmation' });
};

export const pushRoamingSuccessEvent = () => {
  pushDataLayer({ event: 'roaming_success' });
};

export const pushInfoBannerEvent = (type, title, url) => {
  pushDataLayer({
    event: `banner_${type}`,
    banner_title: title,
    banner_url: url,
  });
};

export const pushBannerViewEvent = ({ index, id, title, link }) => {
  pushDataLayer({
    event: 'banner_view',
    [`banner_id_${index}`]: id,
    [`banner_title_${index}`]: title,
    [`banner_url_${index}`]: link,
  });
};

/**
 * Push to GA for  🍏pay
 * @param action {string} What type of action (show, click, success)
 * @param paymentSum {any} Payment sum in ₽$€...
 */

export const pushApplePayAction = (action, paymentSum = null) => {
  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'Online payment',
    eventAction: 'apple pay',
    eventLabel: {
      show: 'show apple pay function',
      click: 'apple pay button click',
      success: 'apple pay success',
    }[action],
    paymentSum,
  });
};

export const pushFloatingCheckAction = (eventActionKey, eventLabelKey, pushOnce) => {
  const eventActionList = {
    address: 'check_adress',
    backCall: 'callback',
  };
  const eventLabelList = {
    open: 'click_to_open',
    connectTariff: 'click_connect',
    backCallOpen: 'click_to_open',
    addressFound: 'address_is_available',
    flatNotAvailable: 'address_is_not_available',
    streetNotFound: 'street_was_not_found',
    houseNotFound: 'house_is_not_available',
    houseFound: 'house_is_available',
  };
  const eventAction = eventActionList[eventActionKey];
  const eventLabel = eventLabelList[eventLabelKey];

  if (pushOnce) {
    const event = window.dataLayer.find(
      (eventObj) =>
        eventObj.eventCategory === 'check' &&
        eventObj.eventAction === eventAction &&
        eventObj.eventLabel === eventLabel,
    );

    if (event) return;
  }

  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'check',
    eventAction,
    eventLabel,
  });
};

export const pushFTTBFormAction = (eventAction) => {
  const eventActionList = {
    street: 'NewStreet',
    house: 'NewHouse',
    flat: 'NewFlat',
    phone: 'Phone',
    name: 'Name',
  };

  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'FTTBForm',
    eventAction: eventActionList[eventAction],
    eventLabel: 'changeField',
  });
};

export const pushFTTBAction = (eventAction, eventLabel, eventCategory) => {
  if (eventCategory === 'FTTBForm') {
    pushFTTBFormAction(eventLabel);
    if (!eventAction) return;
  }

  if (!eventCategory && eventAction === 'address') {
    pushFloatingCheckAction(eventAction, eventLabel);
  }
};

export const pushFTTBOrder = (type, data, services, errorType, orderType, isFttbWithTv) => {
  const eventTypes = {
    server: 'event_item_order_sent_server_error',
    double: 'event_item_order_sent_double',
    sentFail: 'event_item_order_sent_fail',
    openOrderPopup: 'event_item_order_open_popup',
  };
  const errorTypes = { notFoundHouse: 'not_connected_house' };
  const orderTypes = { home: 'Home user' };
  const eventObj = {
    event: eventTypes[type],
    clientType: 'B2C',
    ordered_itemProduct: isFttbWithTv ? 'FTTBOrderTV' : 'FTTBOrder',
    ordered_itemTitle: data?.title,
    ordered_itemSoc: data?.alias,
    ordered_Type: orderTypes[orderType] || '',
    ordered_itemPrice: String(data?.fee),
    ordered_addServices: services ? services.map((s) => s.alias).join('|') : '',
  };

  if (errorType) {
    eventObj.error_type = errorTypes[errorType];
  }
  pushDataLayer(eventObj);
};

export const pushBackCallAction = (eventAction, eventLabel, additionalData) => {
  const eventActionList = {
    shown: 'shown',
    click: 'click_send',
    send: 'form_send',
    sendError: 'form_send_error',
    captcha: 'captcha_shown',
    widget: 'callback_widget',
    buttonClick: 'buttonClick',
    clickInFrom: 'click_Позвоните мне',
  };

  const eventLabelList = {
    linkClick: 'Закажите обратный звонок',
    widgetClick: 'Виджет',
    fmc: 'Convorder',
    fttb: 'FTTBOrder',
    fttbTv: 'FTTBOrderTV',
    url: window.location.href,
    getBeeline: 'get_beeline',
    chosen: 'chosen',
  };

  let pushData = {
    event: 'GA_event',
    eventCategory: 'callback_form',
    eventAction: eventActionList[eventAction],
    eventLabel: eventLabelList[eventLabel],
  };

  if (additionalData) {
    pushData = {
      ...pushData,
      ...additionalData,
    };
    if (additionalData.ordered_itemProduct) {
      pushData = {
        ...pushData,
        ordered_itemProduct: eventLabelList[additionalData.ordered_itemProduct],
      };
    }
  }

  pushDataLayer(pushData);
};

export const pushCheckAddressAction = (eventAction) => {
  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'buttonClick',
    eventAction,
  });
};

export const pushTVEUpsaleBlockAction = (eventAction) => {
  const eventActionList = {
    show: 'show',
    eShopClick: 'click_В интернет-магазин',
    iosClick: 'click_Скачать для iOS',
    androidClick: 'click_Скачать для Android',
  };

  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'TVE_block',
    eventAction: eventActionList[eventAction],
    eventLabel: 'spd_order_form',
  });
};

export const pushFullFormPopupAction = ({ category, action }) => {
  const eventCategories = { btnClick: 'buttonClick' };
  const eventActions = { order: 'Оформить заявку' };

  pushDataLayer({
    event: 'GA_event',
    eventCategory: eventCategories[category],
    eventAction: eventActions[action],
  });
};

/**
 * Push to dataLayer for Recommended tariffs
 */
export const pushGARecommend = once(() => {
  pushDataLayer({ event: 'event_banner_recommend' });
});

/**
 * Push to dataLayer for Open TariffConnectionPopup With Option
 * @param optionName {string} - Option name: constructor or economy
 */
export const pushTariffOption = (optionName) => {
  pushDataLayer({
    event: optionName === 'Закрыть popup' ? 'popupClose_Bee' : 'tariffOption_Bee',
    details: {
      flow: 'buttonClick',
      name: optionName,
    },
  });
};

/**
 * Показ поп-апа на ленде безлимита
 * @param popupType {string} - тип поп-апа (mnp/fancy-number)
 */
export const pushUnlimPopupShown = (popupType) => {
  if (popupType === 'error') return;

  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'phone_input_popup',
    eventAction: 'besplatniy-bezlimit-zhdet-tebya',
    eventLabel: popupType === 'mnpPopup' ? 'not beeline' : 'beeline',
  });
};

/**
 * Клик по кнопке приложения на карточке тарифа
 * @param altText {string} - alt-текст картинки
 * @param targetUrl {string} - url перехода при клике
 */
export const pushAppLinkClick = (altText, targetUrl) => {
  pushDataLayer({
    event: 'GA_event',
    eventCategory: 'linkClick',
    eventAction: altText,
    eventLabel: targetUrl,
  });
};

/**
 * Просмотр карточки тарифа
 * @param extraParams {object} - дополнительные параметры тарифа
 */
export const pushViewedTariffCard = (extraParams) => {
  pushDataLayer({
    itemClientType: 'B2C',
    itemProduct: 'Мобильная связь',
    itemCat: 'Tariffs',
    itemDeviceType: 'mobile',
    ...extraParams,
  });
};

/**
 * Взаимодействие с попапом
 * @param extraParams {object} - дополнительные параметры тарифа
 */
export const pushPopupInteractionEvent = (extraParams) => {
  pushDataLayer({
    event: 'event_popup_interaction',
    itemProduct: 'Мобильная связь',
    itemCat: 'Tariffs',
    itemCatNumber: 'Случайный номер',
    itemPodCatNumber: 'Бесплатный номер',
    ...extraParams,
  });
};
