export const responseHandler = (response) => {
  const { data } = response;
  const { notReady, errorText } = data || {};
  return new Promise((resolve, reject) => {
    if (notReady === true) {
      reject();
    } else if (typeof errorText !== 'undefined') {
      reject('B процессе подключения произошла ошибка. Обратитесь в службу поддержки.');
    } else {
      resolve(data);
    }
  });
};
