import { assoc, merge, mergeWith } from 'ramda';

import { simpleReducer } from 'utils/reduxHelpers';

import { SET_LOADING_STATUS } from './actions';

const root = simpleReducer(SET_LOADING_STATUS, {}, (state, action) =>
  mergeWith(merge, state, { [action.payload.key]: action.payload }),
);

export const loading = (state, action) => assoc('loading', root(state.loading, action), state);
