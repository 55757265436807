import axios from 'axios';
import qs from 'query-string';

import { requestConfig } from '../constants';

/**
 * Проверяет наличие спецтариф для дома и возвращает его если есть
 * @param {number} houseId id дома
 * @param {('internet' | 'kit')} tariffType управляет тем какие тарифы возвращать, только интернет или интернет + тв
 */
const getSpecialTariff = (houseId, tariffType = 'internet') =>
  axios
    .get(
      `/hometariffsapi/getspecialtariff/?${qs.stringify({ houseId, tariffType })}`,
      requestConfig,
    )
    .then(({ data }) => data.view);

export default getSpecialTariff;
