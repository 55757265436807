import {
  ADD_SELECTED_WIFI_ROUTER,
  EXCLUDE_WIFI_ROUTER,
  REMOVE_ALL_SELECTED_WIFI_ROUTERS,
  REMOVE_SELECTED_WIFI_ROUTER,
  RETURN_EXCLUDED_WIFI_ROUTER,
} from '../actions/actionTypes';

const reducers = {
  [EXCLUDE_WIFI_ROUTER]: (state, { alias }) => {
    const excludedRouters = state.fttbWifiRouters.excludedRouters || [];
    if (excludedRouters.find((router) => router.alias === alias)) return state;

    return {
      ...state,
      fttbWifiRouters: {
        ...state.fttbWifiRouters,
        excludedRouters: [...excludedRouters, { alias }],
      },
    };
  },
  [RETURN_EXCLUDED_WIFI_ROUTER]: (state, { alias }) => {
    const excludedRouters = state.fttbWifiRouters.excludedRouters || [];
    const filtredExludedRouters = excludedRouters.filter((router) => router.alias !== alias);

    return {
      ...state,
      fttbWifiRouters: {
        ...state.fttbWifiRouters,
        excludedRouters: filtredExludedRouters,
      },
    };
  },

  [ADD_SELECTED_WIFI_ROUTER]: (state, { router }) => {
    const selectedRouters = state.fttbWifiRouters.selectedRouters || [];
    if (selectedRouters.find((selectedRouter) => selectedRouter.alias === router)) return state;

    const newRouter = {
      name: router.name,
      fee: router.price.value,
      unit: router.price.unit,
      alias: router.alias,
    };

    return {
      ...state,
      fttbWifiRouters: {
        ...state.fttbWifiRouters,
        selectedRouters: [...selectedRouters, newRouter],
      },
    };
  },
  [REMOVE_SELECTED_WIFI_ROUTER]: (state, { alias }) => {
    const selectedRouters = state.fttbWifiRouters.selectedRouters || [];
    const filtredSelectedRouters = selectedRouters.filter((router) => router.alias !== alias);

    return {
      ...state,
      fttbWifiRouters: {
        ...state.fttbWifiRouters,
        selectedRouters: filtredSelectedRouters,
      },
    };
  },
  [REMOVE_ALL_SELECTED_WIFI_ROUTERS]: (state) => ({
    ...state,
    fttbWifiRouters: {
      ...state.fttbWifiRouters,
      selectedRouters: [],
    },
  }),
};

export const fttbWifiRouters = (state, action) => {
  const reducer = reducers[action.type];

  return typeof reducer === 'function' ? reducer(state, action.payload) : state;
};
