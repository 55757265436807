import { simpleReducer } from 'utils/reduxHelpers';

import { SET_CALLBACK_REQUEST_DATA } from './constants';

export const familyEconomyLandingReducer = simpleReducer(
  SET_CALLBACK_REQUEST_DATA,
  {},
  (state, action) => ({
    ...state,
    callbackData: action.payload,
  }),
);
