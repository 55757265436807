import {
  CHANGE_STEP,
  CHOOSE_CONSTRUCTOR,
  GET_TARIFF_CONSTRUCTORS,
  GET_TARIFF_RESTS,
  RESET_VALUES,
  SET_VALUES,
  TOGGLE_FLIP_STATE,
  TOGGLE_TARIFF_CONSTRUCTOR,
} from '../actions/tariffConsctructorActionsType';

const getNewState = (oldState, newData) => ({
  ...oldState,
  ...{
    tariffConstructor: {
      ...oldState.tariffConstructor,
      ...newData,
    },
  },
});

const getConstructorStates = (data) => {
  const directions = [false, false]; // 0 - минуты и смс на ГБ, 1 - ГБ на минуты и смс
  const isLifeFamily = !!data.accumulators && !!data.accumulators.onNetSeconds;

  if (isLifeFamily) {
    data.constructors.forEach((item) => {
      if (item.onNetSeconds.quantum < 0) {
        directions[0] = true;
      } else if (item.offNetSeconds.quantum < 0) {
        directions[1] = true;
      }
    });
  } else {
    data.constructors.forEach((item) => {
      if (item.seconds.quantum < 0 || item.sms.quantum < 0) {
        directions[0] = true;
      } else if (item.kbyte.quantum < 0) {
        directions[1] = true;
      }
    });
  }
  return {
    flipped: !directions[0] && directions[1],
    unflippable: !directions[0] || !directions[1],
    isLifeFamily,
  };
};

const getValuesState = ({
  seconds,
  sms,
  kbyte,
  onNetSeconds,
  offNetSeconds,
  chargeAmount,
  constructorId,
  dataTariff,
}) => {
  const isLifeFamily = !!onNetSeconds;

  if (isLifeFamily) {
    return {
      onNetSeconds: onNetSeconds.quantum,
      offNetSeconds: offNetSeconds.quantum,
      price: chargeAmount,
      constructorId,
      dataTariff,
    };
  }

  return {
    seconds: seconds.quantum,
    sms: sms.quantum,
    kbyte: kbyte.quantum,
    price: chargeAmount,
    constructorId,
    dataTariff,
  };
};

const defaultValues = { seconds: 0, sms: 0, kbyte: 0, onNetSeconds: 0, offNetSeconds: 0 };

const getNewValues = (state, { value, key }) => {
  const {
    values,
    flipped,
    data: { constructors },
  } = state;
  let constructor;

  if (value === 0 && (values.seconds === 0 || values.sms === 0)) {
    return defaultValues;
  }

  if (key === 'seconds') {
    constructor = constructors.find(
      ({ seconds, sms }) => seconds.quantum === value && sms.quantum === state.values.sms,
    );
    if (!constructor && (!flipped || value !== 0)) {
      constructor = constructors.find(({ seconds }) => seconds.quantum === value);
    }
  }

  if (key === 'sms') {
    constructor = constructors.find(
      ({ seconds, sms }) => sms.quantum === value && seconds.quantum === state.values.seconds,
    );
    if (!constructor && (!flipped || value !== 0)) {
      constructor = constructors.find(({ sms }) => sms.quantum === value);
    }
  }

  if (key === 'kbyte') {
    constructor = constructors.find(({ kbyte }) => kbyte.quantum === value);
  }

  if (key === 'onNetSeconds') {
    constructor = constructors.find(({ onNetSeconds }) => onNetSeconds.quantum === value);
    if (!constructor && (!flipped || value !== 0)) {
      constructor = constructors.find(({ onNetSeconds }) => onNetSeconds.quantum === value);
    }
  }

  if (key === 'offNetSeconds') {
    constructor = constructors.find(({ offNetSeconds }) => offNetSeconds.quantum === value);
    if (!constructor && (!flipped || value !== 0)) {
      constructor = constructors.find(({ offNetSeconds }) => offNetSeconds.quantum === value);
    }
  }

  if (
    constructor &&
    value === 0 &&
    ((key === 'sms' && constructor.seconds.quantum !== values.seconds) ||
      (key === 'seconds' && constructor.sms.quantum !== values.sms))
  ) {
    return defaultValues;
  }

  return constructor ? getValuesState(constructor) : defaultValues;
};

/**
 * Возвращает конструктор, опции обмена которого,
 * обратные опциям выбранного на данный момент конструктора
 * @param {object} tariffConstructor объект состояние конструктора тарифа
 * @param {object} tariffConstructor.values объект с выбранными опциями конструктора
 * @returns {object|undefined} Если найден возвращает объект конструктора, иначе `undefined`
 */
const getReversedConstructor = ({ data, values, isLifeFamily }) => {
  if (isLifeFamily) {
    return data.constructors.find(
      ({ onNetSeconds, offNetSeconds }) =>
        onNetSeconds.quantum === -values.onNetSeconds &&
        offNetSeconds.quantum === -values.offNetSeconds,
    );
  }

  return data.constructors.find(
    ({ seconds, kbyte, sms }) =>
      seconds.quantum === -values.seconds &&
      kbyte.quantum === -values.kbyte &&
      sms.quantum === -values.sms,
  );
};

export function tariffConstructorReducer(state, { type, payload }) {
  if (type === TOGGLE_TARIFF_CONSTRUCTOR) {
    return getNewState(state, {
      opened: !state.tariffConstructor.opened,
      step: 'pending',
    });
  }

  if (type === CHANGE_STEP) {
    return getNewState(state, { step: payload });
  }

  if (type === GET_TARIFF_CONSTRUCTORS) {
    return getNewState(state, { data: payload, ...getConstructorStates(payload) });
  }

  if (type === GET_TARIFF_RESTS) {
    return getNewState(state, {
      data: {
        ...state.tariffConstructor.data,
        ...payload,
      },
    });
  }

  if (type === TOGGLE_FLIP_STATE) {
    const reversedConstructor = getReversedConstructor(state.tariffConstructor);
    const values = reversedConstructor ? getValuesState(reversedConstructor) : defaultValues;

    return getNewState(state, {
      flipped: !state.tariffConstructor.flipped,
      values,
    });
  }

  if (type === SET_VALUES) {
    return getNewState(state, {
      values: getNewValues(state.tariffConstructor, payload),
    });
  }

  if (type === RESET_VALUES) {
    return getNewState(state, {
      values: defaultValues,
    });
  }

  if (type === CHOOSE_CONSTRUCTOR) {
    return getNewState(state, {
      constructorId: state.tariffConstructor.values.constructorId,
    });
  }

  return state;
}
