import { errorHandler as restrictedErrorHandler } from 'widgets/RestrictedPopup/utils';

export function axiosErrorHandler(error) {
  if (error.response && error.response.status === 452) {
    restrictedErrorHandler(error.response.data);
  }

  return Promise.reject(error);
}

export function jqueryErrorHandler() {
  window.jQuery &&
    window.jQuery(document).ajaxError((event, jqxhr) => {
      if (jqxhr.status === 452) {
        const data = JSON.parse(jqxhr.responseText);
        restrictedErrorHandler(data);
      }
    });
}

// Сбрасывает дефолтные XHR-заголовки, установленные в конструкторе Axios
export const transformRequest = [
  (data, headers) => {
    headers.common = {};
    return data;
  },
];
