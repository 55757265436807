export const TOGGLE_TARIFF_CONNECTION_POPUP = 'TOGGLE_TARIFF_CONNECTION_POPUP';

export const SET_TARIFF_CONNECTION_POPUP = 'SET_TARIFF_CONNECTION_POPUP';

export const SET_TARIFF_IN_PROGRESS_POPUP = 'SET_TARIFF_IN_PROGRESS_POPUP';

export const SET_CONNECTION_RESULT_POPUP = 'SET_CONNECTION_RESULT_POPUP';

export const SET_TARIFF_CHANGE_RESULT_POPUP = 'SET_TARIFF_CHANGE_RESULT_POPUP';

export const SET_DISCONNECT_NUMBER_POPUP = 'SET_DISCONNECT_NUMBER_POPUP';

export const SET_DISCONNECT_SUCCESS_POPUP = 'SET_DISCONNECT_SUCCESS_POPUP';

export const SET_DISCONNECT_WARNING_POPUP = 'SET_DISCONNECT_WARNING_POPUP';

export const SET_SERVICE_CONNECTION_POPUP = 'SET_SERVICE_CONNECTION_POPUP';

export const SET_SERVICE_DISCONNECTION_POPUP = 'SET_SERVICE_DISCONNECTION_POPUP';

export const TOGGLE_SERVICE_CONNECTION_POPUP = 'TOGGLE_SERVICE_CONNECTION_POPUP';

export const TOGGLE_SERVICE_DISCONNECTION_POPUP = 'TOGGLE_SERVICE_DISCONNECTION_POPUP';

export const SET_SRV_CONNECTION_RESULT_POPUP = 'SET_SRV_CONNECTION_RESULT_POPUP';

export const SET_SRV_DISCONNECTION_RESULT_POPUP = 'SET_SRV_DISCONNECTION_RESULT_POPUP';

export const INIT_SERVICE_DATA = 'INIT_SERVICE_DATA';

export const SET_SERVICE_WAITING_POPUP = 'SET_SERVICE_WAITING_POPUP';

export const SET_SERVICE_WAITING_DISCONNECT_POPUP = 'SET_SERVICE_WAITING_DISCONNECT_POPUP';

export const SET_SERVICE_MNP_POPUP = 'SET_SERVICE_MNP_POPUP';

export const SET_SERVICE_SIM_ORDER = 'SET_SERVICE_SIM_ORDER';

export const SET_CONFIRM_NUMBER_POPUP = 'SET_CONFIRM_NUMBER_POPUP';

export const TOGGLE_CONFIRM_NUMBER_POPUP = 'TOGGLE_CONFIRM_NUMBER_POPUP';

export const SET_SERVICE_STATUS_REQUEST_START = 'SET_SERVICE_STATUS_REQUEST_START';

export const SET_SERVICE_STATUS_REQUEST_COMPLATE = 'SET_SERVICE_STATUS_REQUEST_COMPLATE';

export const SET_SERVICE_STATUS_REQUEST_IN_PROGRESS = 'SET_SERVICE_STATUS_REQUEST_IN_PROGRESS';

export const SET_SERVICE_STATUS_REQUEST_ERROR = 'SET_SERVICE_STATUS_REQUEST_ERROR';

export const SERVICE_IS_DISCONNECT = 'SERVICE_IS_DISCONNECT';

export const OPEN_TARIFF_DESCRIPTION_POPUP = 'OPEN_TARIFF_DESCRIPTION_POPUP';

export const CALLBACK_DOWNSELL = 'CALLBACK_DOWNSELL';

export const ON_DOWNSELL_OFFER_STEP = 'ON_DOWNSELL_OFFER_STEP';

export const SET_TABS_TO_DISPLAY = 'SET_TABS_TO_DISPLAY';

export const UPDATE_CONNECTED_NUMBERS = 'UPDATE_CONNECTED_NUMBERS';

export const RESET_SERVICE_POPUP = 'RESET_SERVICE_POPUP';

export const SET_ADDITIONAL_SIM_COUNT = 'SET_ADDITIONAL_SIM_COUNT';

export const SET_CHOSEN_SOCS = 'SET_CHOSEN_SOCS';

export const SET_ALIAS = 'SET_ALIAS';
