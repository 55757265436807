export function getCookie(name) {
  if (typeof document === 'undefined') return null;
  /* eslint-disable */
  const matches = document.cookie.match(new RegExp(
        `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  /* eslint-enable */
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie({ name, value, domain, ttl, path, maxAge }) {
  // eslint-disable-next-line max-len
  document.cookie = `${name}=${value}${domain ? `;domain=${domain}` : ''}${
    path ? `;path=${path}` : ''
  }${ttl ? `;expires=${new Date(new Date().getTime() + ttl).toGMTString()}` : ''}${
    maxAge ? `;max-age=${maxAge}` : ''
  }`;
}

export const deleteCookie = (name) => {
  setCookie({
    name,
    value: '',
    maxAge: -1,
  });
};
