import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import arrowCircled from './assets/arrowCircled.svg';
import arrowLeft from './assets/arrowLeft.svg';
import arrowTop from './assets/arrowTop.svg';
import backward from './assets/backward.svg';
import chat from './assets/chat.svg';
import checked from './assets/checked.svg';
import checkedThin from './assets/checkedThin.svg';
import cross from './assets/cross.svg';
import crossThin from './assets/crossThin.svg';
import documents from './assets/documents.svg';
import arrowZLand from './assets/arrow-zLand.svg';
import dropdownArrow from './assets/dropdownArrow.svg';
import discountMark from './assets/discountMark.svg';
import exit from './assets/exit.svg';
import external from './assets/external.svg';
import home from './assets/home.svg';
import mapMarker from './assets/mapMarker.svg';
import link from './assets/link.svg';
import ok from './assets/ok.svg';
import personal from './assets/personal.svg';
import phone from './assets/phone.svg';
import phoneHandset from './assets/phoneHandset.svg';
import phoneFilled from './assets/phoneFilled.svg';
import play from './assets/play.svg';
import search from './assets/search.svg';
import settings from './assets/settings.svg';
import tariffCheck from './assets/tariffCheck.svg';
import arrowDown from './assets/arrowDown.svg';
import metro from './assets/metro.svg';
import infinity from './assets/infinity.svg';
import info from './assets/info.svg';
import star from './assets/star.svg';
import camera from './assets/camera.svg';
import sort from './assets/sort.svg';
import handicapped from './assets/handicapped.svg';
import erase from './assets/erase.svg';
import minus from './assets/minus.svg';
import plus from './assets/plus.svg';
import pen from './assets/pen.svg';
import alfaBankCard from './assets/alfaBankCard.svg';
import trolley from './assets/trolley.svg';
import controlsDoubleArrows from './assets/controlsDoubleArrows.svg';
import yandexPlusGradient from './assets/yandexPlus/yandexPlusGradient.svg';
import yandexPlusGrey from './assets/yandexPlus/yandexPlusGrey.svg';
import yandexPlusWhite from './assets/yandexPlus/yandexPlusWhite.svg';
import lockedToggle from './assets/locked.svg';
import infinityConstructor from './assets/infinityConstructor.svg';
import eSim from './assets/eSim.svg';
import fire from './assets/fire.svg';
import infinityWhite from './assets/infinityWhite.svg';
import userAdd from './assets/userAdd.svg';
import timer from './assets/timer.svg';
import chevronLeft from './assets/chevronLeft.svg';
import chevronRight from './assets/chevronRight.svg';
import chevronRightGray from './assets/chevronRightGray.svg';
import FTTBHandset from './assets/FTTBHandset.svg';
import FTTBInfo from './assets/FTTBInfo.svg';
import warning from './assets/warning.svg';
import crossZ from './assets/crossZ.svg';
import gosLogo from './assets/gosLogo.svg';

const cx = classNames.bind(styles);

const icons = {
  arrowCircled,
  arrowZLand,
  arrowLeft,
  arrowTop,
  backward,
  discountMark,
  chat,
  checked,
  cross,
  crossThin,
  documents,
  dropdownArrow,
  exit,
  external,
  home,
  mapMarker,
  link,
  ok,
  personal,
  phone,
  phoneHandset,
  phoneFilled,
  play,
  search,
  settings,
  tariffCheck,
  arrowDown,
  metro,
  infinity,
  info,
  checkedThin,
  star,
  camera,
  sort,
  handicapped,
  erase,
  minus,
  plus,
  pen,
  alfaBankCard,
  trolley,
  controlsDoubleArrows,
  yandexPlusGradient,
  yandexPlusGrey,
  yandexPlusWhite,
  lockedToggle,
  infinityConstructor,
  gosLogo,
  eSim,
  fire,
  infinityWhite,
  userAdd,
  timer,
  chevronLeft,
  chevronRight,
  chevronRightGray,
  FTTBHandset,
  FTTBInfo,
  warning,
  crossZ,
};

const Icon = ({ name, className, src, fill, markup, lazy }) => {
  const IconComponent = (markup && 'span') || icons[name];

  return IconComponent ?
      <IconComponent className={cx(className, markup && name)} fill={fill} />
    : <img
        alt=""
        className={className}
        loading={lazy ? 'lazy' : undefined}
        src={src && src.replace('{{name}}', name)}
      />;
};

Icon.defaultProps = { src: '//static.beeline.ru/upload/images/emoji/{{name}}.svg' };

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  fill: PropTypes.string,
  markup: PropTypes.bool,
  lazy: PropTypes.bool,
};

export default Icon;
