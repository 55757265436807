// Принимает на вход модель страницы и работает с ней
export const getPopupDataFromPageData = (originData, type) => {
  const popup = originData.popups.find((p) => p.type === type);

  if (popup) return popup.data;
  return null;
};

export const getFTTBTariffPageData = ({ external }) => external.fttbTariffPage.originData;

export const getFTTBTariffPagePopupByType = (state, type) =>
  getPopupDataFromPageData(getFTTBTariffPageData(state), type);
