import { pushDataLayer } from '../ga';

export const pushMenuClick = (level1, level2, attached) => {
  const data = {
    event: 'event_b2c',
    eventLabel: window.location.pathname,
    eventCategory: `${attached ? 'StickyNav' : 'MainNav'}|${level1.title}`,
  };

  if (level2) {
    data.eventAction = `${level2.title}|Null`;
  }
  if (attached) {
    data.navType = 'sticky';
  }

  pushDataLayer(data);
};
