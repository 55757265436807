import { pathOr } from 'ramda';

import { recordPayload } from '../actionsApi';
import {
  OPEN_TARIFF_DESCRIPTION_POPUP,
  SET_TARIFF_CONNECTION_POPUP,
  TOGGLE_TARIFF_CONNECTION_POPUP,
} from '../actionTypes';

export const setWaitingPopup = (data) => (dispatch, getState) => {
  const state = getState();
  const connectionPopup = pathOr({}, ['external', 'tariffConnectionPopup'], state);
  const connectData = connectionPopup.data;
  const { waitingPopup } = connectData;

  dispatch(
    recordPayload(SET_TARIFF_CONNECTION_POPUP, {
      isPending: false,
      data: {
        ...connectData,
        waitingPopup: { ...waitingPopup, ...data },
      },
    }),
  );
};

export const toggleConnectPopup = (popupIsActive) => (dispatch) => {
  dispatch(recordPayload(TOGGLE_TARIFF_CONNECTION_POPUP, { popupIsActive }));
};

export const toggleDivorcingTariffPopup = (offerCode, offerType) => (dispatch) => {
  dispatch(recordPayload(TOGGLE_TARIFF_CONNECTION_POPUP, { popupIsActive: true }));
  dispatch(
    recordPayload(SET_TARIFF_CONNECTION_POPUP, {
      step: 'divorcing',
      divorcing: true,
      isPending: false,
      offerCodeStore: offerCode,
      offerTypeStore: offerType,
    }),
  );
};

export const openTariffDescriptionPopup = (isOpen) => (dispatch) => {
  dispatch(recordPayload(OPEN_TARIFF_DESCRIPTION_POPUP, isOpen));
};

export const initConnectionPopup = (connectPopupUrl, alias, soc, connect) => (dispatch) => {
  dispatch(
    recordPayload(SET_TARIFF_CONNECTION_POPUP, {
      connectPopupUrl,
      alias,
      connect,
      soc,
    }),
  );
};

export const setTariffConnectionPopup = (popup) => ({
  type: SET_TARIFF_CONNECTION_POPUP,
  payload: popup,
});
