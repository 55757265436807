import { axiosBeelineRequest } from '@beef/utils';

import type { AuthTypes } from './types';

interface IAuthInfo {
  login: string;
  selectedCtn: string;
  selectedLogin: string;
  token: string;
  tokenType: AuthTypes;
}

export interface IGetTokenResponse {
  Error: {
    ErrorMessage?: string;
  };
  IsSucceeded: boolean;
  Message?: string;
  Result: unknown;
  View?: IAuthInfo;
}

export const fetchTokenInfo = () =>
  axiosBeelineRequest<IGetTokenResponse>({
    method: 'post',
    url: '/login/gettoken',
    withCredentials: true,
  });
